// Envoyer un message
import {userStore} from "@/store/pinia-stores/user.store";
import {toast} from "vue3-toastify";
import {Styles} from "@/assets/js/styles";
import api from "@/services-apis/axios-init";
import {commandeStore} from "@/store/pinia-stores/commande-store";

export const sendMessage = (data, refetch) => {
    let token = userStore()?.tokens?.accessToken
    let headers = {Authorization: `Bearer ${token}`}

    const store = commandeStore()
    store.setIsLoading(true)

    api.post(`/admin/messages/create`, data, {headers}).then(res => {
        if (res.status !== 201) throw new Error("Status de création erroné")

        // On change l'état de la commande actuelle
        refetch()

        // Arrêter le chargement
        store.setIsLoading(false)

    }).catch(err => {
        // Arrêter le chargement
        store.setIsLoading(false)

        let msg = err.response.data.message

        toast.error(msg || 'impossible de créer un message', {
            position: 'bottom-right',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle
        })

        console.log({err})

    })
}

export const updateMessageContent = (idMsg, data, refetch) => {
    let token = userStore()?.tokens?.accessToken
    let headers = {Authorization: `Bearer ${token}`}

    const store = commandeStore()
    store.setIsLoading(true)

    api.patch(`/admin/messages/${idMsg}/update`, data, {headers}).then(res => {
        if (res.status !== 200) throw new Error("Status de création erroné")

        // On change l'état de la commande actuelle
        refetch()

        // Arrêter le chargement
        store.setIsLoading(false)

    }).catch(err => {
        // Arrêter le chargement
        store.setIsLoading(false)

        let msg = err.response.data.message

        toast.error(msg || 'impossible de modifier ce message', {
            position: 'bottom-right',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle
        })

        console.log({err})

    })
}
