<script setup>
import ArgonButton from "@/components/FormButton.vue";
import {usePayement} from "@/services-apis/payements/hooks.payements";
import dayjs from "dayjs";
import {computed} from "vue";
import {payementStore} from "@/store/pinia-stores/payements-store";
import {useTypeText} from "@/hook/frontHooks";
import formatCurrency from "../../middlewares/formatCurrency";

const {commande, refetch} = usePayement()
const {getTypeText} = useTypeText()

const textLengthSoldeCommande = computed(() => {
  let elements = commande.value?.payements.filter(item => item?.type === 'paye_commande')
  let length = elements?.length
  return length === 0
})

const textLengthPurchargeFee = computed(() => {
  let elements = commande.value?.payements.filter(item => item?.type === 'paye_purcharge_fee')
  let length = elements?.length
  return length === 0
})

const textLengthCommandeFe = computed(() => {
  let elements = commande.value?.payements.filter(item => item?.type === 'paye_commande_fe')
  let length = elements?.length
  return length === 0
})

// const textLengthAcompteCommande = computed(() => {
//   let elements = commande.value?.payements.filter(item => item?.type === 'acompte_commande')
//   let length = elements?.length
//   return length === 0
// })


// Aller à un autre payement
const strPym = payementStore()
const gotoOnePayement = async (pymnt) => {
  strPym.setCurrentId(pymnt?.id)
  await refetch()
}

</script>

<template>
  <div class="card h-100 mb-4">
    <div class="card-header pb-0 px-3">
      <div class="row">
        <div class="col-md-12">
          <h6 class="mb-0">Transactions</h6>
          <span class="text-xs">Transactions sur la commande de ce paiement</span>
        </div>
      </div>
    </div>


    <div class="card-body pt-4 p-3">
      <h6 class="text-uppercase text-body text-xs font-weight-bolder mb-3">
        Frais de services
      </h6>
      <span v-if="textLengthPurchargeFee" class="text-xs mx-5 text-center align-self-center">Aucun solde payé</span>

      <div v-if="commande?.payements.length===0" class="text-center">
        <img alt="" class="img-fluid" src="@/assets/img/paye.jpg" style="height: 100px;width: 100px"/>
        <p class="text-normal text-sm text-dark">Aucun paiement enregistré pour la commande</p>
      </div>

      <ul class="list-group">

        <!-- Les frais de services-->
        <template v-for="payement in commande?.payements" :key="payement.id">
          <li
              v-if="payement?.type === 'paye_purcharge_fee'"
              class="btn shadow-none text-normal border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
              @click="gotoOnePayement(payement)"
          >
            <div class="d-flex align-items-center">
              <argon-button
                  class="btn-icon-only btn-rounded mb-0 me-3 d-flex align-items-center justify-content-center"
                  color="success"
                  size="sm"
                  variant="outline"
              >
                <i aria-hidden="true" class="fas fa-arrow-up"></i>
              </argon-button>

              <div class="d-flex flex-column">
                <h6 class="mb-1 text-dark text-sm">{{ getTypeText(payement) }}</h6>
                <span class="text-xs text-start">{{ dayjs(payement?.createdAt).format('DD MMM, à HH:mm') }}</span>
              </div>
            </div>

            <div
                class="d-flex align-items-center text-success text-gradient text-lg font-weight-bold"
            >
              {{ payement?.devise?.symbole }} {{ formatCurrency(Number(payement?.totalPayed || 0)) }}
            </div>
          </li>
        </template>
      </ul>

      <h6 class="text-uppercase text-body text-xs font-weight-bolder mb-3">
        Solde de la commande
      </h6>
      <span v-if="textLengthSoldeCommande" class="text-xs mx-5 text-center align-self-center">Aucun solde payé</span>

      <ul class="list-group">
        <!--   Solde de la commande -->
        <template v-for="payement in commande?.payements" :key="payement.id">
          <li
              v-if="payement?.type === 'paye_commande'"
              class="btn shadow-none text-normal border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
              @click="gotoOnePayement(payement)"
          >
            <div class="d-flex align-items-center">
              <argon-button
                  class="btn-icon-only btn-rounded mb-0 me-3 d-flex align-items-center justify-content-center"
                  color="success"
                  size="sm"
                  variant="outline"
              >
                <i aria-hidden="true" class="fas fa-arrow-up"></i>
              </argon-button>

              <div class="d-flex flex-column">
                <h6 class="mb-1 text-dark text-sm">{{ getTypeText(payement) }}</h6>
                <span class="text-xs text-start">{{ dayjs(payement?.createdAt).format('DD MMM, à HH:mm') }}</span>
              </div>

            </div>
            <div
                class="d-flex align-items-center text-success text-gradient text-lg font-weight-bold"
            >
              {{ payement?.devise?.symbole }} {{ formatCurrency(Number(payement?.totalPayed || 0)) }}
            </div>
          </li>
        </template>
      </ul>

      <h6 class="text-uppercase text-body text-xs font-weight-bolder mb-3">
        Solde de la commande
      </h6>
      <span v-if="textLengthSoldeCommande" class="text-xs mx-5 text-center align-self-center">Aucun solde payé</span>

      <ul class="list-group">
        <!--   Solde de la commande -->
        <template v-for="payement in commande?.payements" :key="payement.id">
          <li
              v-if="payement?.type === 'paye_commande'"
              class="btn shadow-none text-normal border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
              @click="gotoOnePayement(payement)"
          >
            <div class="d-flex align-items-center">
              <argon-button
                  class="btn-icon-only btn-rounded mb-0 me-3 d-flex align-items-center justify-content-center"
                  color="success"
                  size="sm"
                  variant="outline"
              >
                <i aria-hidden="true" class="fas fa-arrow-up"></i>
              </argon-button>

              <div class="d-flex flex-column">
                <h6 class="mb-1 text-dark text-sm">{{ getTypeText(payement) }}</h6>
                <span class="text-xs text-start">{{ dayjs(payement?.createdAt).format('DD MMM, à HH:mm') }}</span>
              </div>

            </div>
            <div
                class="d-flex align-items-center text-success text-gradient text-lg font-weight-bold"
            >
              {{ payement?.devise?.symbole }} {{ formatCurrency(Number(payement?.totalPayed || 0)) }}
            </div>
          </li>
        </template>
      </ul>

      <h6 class="text-uppercase text-body text-xs font-weight-bolder mb-3">
        Frais d'expédition
      </h6>
      <span v-if="textLengthCommandeFe"
            class="text-xs mx-5 text-center align-self-center">Aucun frais payé actuellement</span>

      <ul class="list-group">
        <!--   Solde de la commande -->
        <template v-for="payement in commande?.payements" :key="payement.id">
          <li
              v-if="payement?.type === 'paye_commande_fe'"
              class="btn shadow-none text-normal border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
              @click="gotoOnePayement(payement)"
          >
            <div class="d-flex align-items-center">
              <argon-button
                  class="btn-icon-only btn-rounded mb-0 me-3 d-flex align-items-center justify-content-center"
                  color="success"
                  size="sm"
                  variant="outline"
              >
                <i aria-hidden="true" class="fas fa-arrow-up"></i>
              </argon-button>

              <div class="d-flex flex-column">
                <h6 class="mb-1 text-dark text-sm">{{ getTypeText(payement) }}</h6>
                <span class="text-xs text-start">{{ dayjs(payement?.createdAt).format('DD MMM, à HH:mm') }}</span>
              </div>

            </div>
            <div
                class="d-flex align-items-center text-success text-gradient text-lg font-weight-bold"
            >
              {{ payement?.devise?.symbole }} {{ formatCurrency(Number(payement?.totalPayed || 0)) }}
            </div>
          </li>
        </template>
      </ul>

    </div>
  </div>
</template>
