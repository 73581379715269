import {userStore} from "@/store/pinia-stores/user.store";
import api from "@/services-apis/axios-init";
import {toast} from "vue3-toastify";
import {Styles} from "@/assets/js/styles";
import {cartStore} from "../../store/pinia-stores/cart-store";


/*
* Affecter une date de livraison à un panier
* */
export const scheduleDateLivraison = (cartId, data, refetch, messageSuccess) => {
    let token = userStore()?.tokens?.accessToken
    let headers = {Authorization: `Bearer ${token}`}

    const store = cartStore()
    store.setIsLoading(true)

    api.post(`/admin/carts/${cartId}/schedule-delivery`, data, {headers}).then(res => {
        const msg = res.data.message
        const dataLivraison = res.data?.data

        // On recharge les données
        refetch([dataLivraison])

        // Arrêter le chargement
        store.setIsLoading(false)

        // Message du succès d'enregistrement user / admin
        toast.success(messageSuccess || msg || 'Planification effectuée', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle,
            autoClose: 3000,
        })

    }).catch(err => {
        // Arrêter le chargement
        store.setIsLoading(false)

        let msg = err.response.data.message

        toast.error(msg || 'impossible de planifier une livraison actuellement', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle
        })

        console.log({err})

    })
}


/*
* Ajouter une étape dans la progression d'une date de livraison d'un panier prédéfini
* */
export const addStepDateLivraison = (cartId, data, refetch, messageSuccess) => {
    let token = userStore()?.tokens?.accessToken
    let headers = {Authorization: `Bearer ${token}`}

    const store = cartStore()
    store.setIsLoading(true)

    api.post(`/admin/carts/${cartId}/schedule-delivery/add-step`, data, {headers}).then(res => {
        const msg = res.data.message

        // On recharge les données
        refetch()

        // Arrêter le chargement
        store.setIsLoading(false)

        // Message du succès d'enregistrement user / admin
        toast.success(messageSuccess || msg || "Etape ajoutée", {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle,
            autoClose: 3000,
        })

    }).catch(err => {
        // Arrêter le chargement
        store.setIsLoading(false)

        let msg = err.response.data.message

        toast.error(msg || "impossible d'ajouter cette étape de livraison", {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle
        })

        console.log({err})

    })
}
