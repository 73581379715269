<script setup>
import {cartStore} from "../../../store/pinia-stores/cart-store";
import {CalendarOutlined, PhoneOutlined} from "@ant-design/icons-vue";
import {formatName} from "../../../middlewares/formatName";
import {computed, ref, watch} from "vue";
import formatCurrency from "../../../middlewares/formatCurrency";
import dayjs from "dayjs";
import {scheduleDateLivraison} from "../../../services-apis/carts";
import CartTimeLineSide from "./CartTimeLineSide.vue";

const panierStore = cartStore()
const panierCourant = computed(() => panierStore.currentCart)
const isLoading = computed(() => panierStore.isLoading)
const isPayed = computed(() => panierCourant.value.isPayed)
const isCompletePayed = computed(() => panierCourant.value.isCompletePayed)
const livraisons = ref([])

// Last delivery date : la dernière date fixée pour la livraison
const lastDelivery = computed(() => panierCourant.value?.livraisons[0]);
const lastDateDelivery = computed(() => lastDelivery.value?.livraisonDate);

// Vérifiez si la date existe et transformez-la
const dateSchedule = ref(
    lastDateDelivery.value
        ? new Date(lastDateDelivery.value) // Conversion en Date native
        : new Date() // Date actuelle par défaut
);

console.log(panierCourant.value)
// Planificateur des livraisons
const showPlanificator = ref(false)

// Fermer le modal
const scheduleNow = () => {
  scheduleDateLivraison(panierCourant.value?.id, {date: dayjs.utc(dateSchedule.value)}, (livr) => {
    livraisons.value = livr
    showPlanificator.value = false
  }, "")
}

// Watch pour surveiller les changements dynamiques
watch(
    lastDateDelivery,
    (newValue) => {
      if (newValue) {
        dateSchedule.value = new Date(newValue); // Met à jour lorsque `lastDateDelivery` change
      }

      // Si aucune date n'est définie alors, on passe à aujourd'hui
      if (!newValue) {
        dateSchedule.value = new Date(); // Met à jour lorsque `lastDateDelivery` change
      }
    },
    {immediate: true} // Met à jour dès le premier rendu
);

// Watch pour surveiller les changements dynamiques des dates sur la livraison du panier courant
watch(
    panierCourant,
    (newValue) => {
      if (newValue) {
        livraisons.value = newValue.livraisons;
      }
    },
    {immediate: true} // Met à jour dès le premier rendu
);
</script>


<template>

  <div>
    <slot/>
  </div>

  <a-drawer ref="modalRef" v-model:open="panierStore.sideDetails" :mask="false"
            body-style="min-width: 500px;width: 55vw;" width="auto">

    <template #title>
      <div class="card-header pb-0 px-3">
        <h6 class="mb-0 modal-title">Informations sur le panier</h6>
      </div>
    </template>

    <!--   ============== Le contenu du modal =============== -->
    <div>

      <div class="card-body py-1">

        <ul class="list-group">
          <li
              class="list-group-item border-0 d-flex flex-column px-4 py-2 mb-2 bg-gray-100 border-radius-lg"
          >
            <div class="font-weight-bold my-2 text-lg text-dark">Coordonnées du client</div>
            <div class="d-flex flex-column">
              <span class="mb-2 text-sm">
              Nom complet:
              <span class="text-dark font-weight-bold ms-sm-1 text-sm">
                {{ formatName(panierCourant?.user) || 'Non défini' }}
              </span>
            </span>
              <span class="mb-2 text-sm">
              Adresse e-mail:
              <a :href="mailToLink"
                 class="text-link-blue ms-sm-1 text-sm font-weight-bold text-decoration-underline">
                {{ panierCourant?.user?.email || 'Non réconnu' }}
                <MailOutlined class="pb-1"/>
              </a>
            </span>

              <span class="mb-2 text-sm">
              Téléphone:
              <a v-if="panierCourant?.user?.phoneNumber" :href="'tel:'+panierCourant?.user?.phoneNumber"
                 class="text-link-blue ms-sm-1 text-sm font-weight-bold text-decoration-underline">
                {{ panierCourant?.user?.phoneNumber }}
                    <PhoneOutlined class="pb-1"/>
              </a>
              <span v-else class="text-dark ms-sm-1 text-sm font-weight-bold">
                Aucun numéro fournis
              </span>
            </span>

              <span class="mb-2 text-sm">
              ID.Unique:
              <span class="text-dark ms-sm-1 text-sm font-weight-bold">
                {{ panierCourant?.user?.uuid }}
              </span>
            </span>
            </div>
          </li>

          <li
              class="list-group-item border-0 d-flex flex-column px-4 py-2 mb-2 bg-gray-100 border-radius-lg"
          >

            <div class="font-weight-bold my-2 text-lg text-dark">Livraison</div>
            <div class="font-weight-bold my-2 text-md text-dark">Adresse</div>

            <!--  Si une adresse est fournie , nous allons l'afficher à ce niveau-->
            <div v-if="panierCourant?.adresse">
              <i class="fas fa-map px-1"></i>
              <span class="font-weight-bold">
                   {{ panierCourant?.adresse?.country }}, {{
                  panierCourant?.adresse?.city
                }}, C/ {{ panierCourant?.adresse?.commune || '' }},
          Q / {{ panierCourant?.adresse?.quartier || '-' }}  </span>
            </div>

            <!--  Si aucune adresse n'est fournie -->
            <div v-else class="mx-1 px-1 border-radius-xl">
              <span class="text-secondary">
                -- Aucune fournie --
              </span>
            </div>
            <hr class="horizontal dark"/>

            <div class="font-weight-bold my-2 text-md text-dark">Les dates</div>
            <!--   Aucune date de livraison choisie pour l'instant-->
            <div v-if="(livraisons?.length <1)" class="mb-2 text-sm">
              <span class="text-gray-400 font-weight-bold ms-sm-1 text-sm">
                -- Aucune date choisie pour la livraison --
              </span>
            </div>

            <!-- Parcourons le tableau de tous les évenéments de livraison créés -->
            <div v-for="planification in livraisons" :key="planification.id" class="d-flex flex-column">
              <!-- Date de la livraison fournie -->
              <div class="mb-2 text-sm">
                Livraison planifiée pour le:
                <span class="text-dark font-weight-bold ms-sm-1 text-sm">
                {{ dayjs(planification.livraisonDate).format('DD MMM YYYY') }}
              </span>
              </div>

              <!-- Adresse de la livraison fournie -->
              <div class="mb-2 text-sm">
                A l'adresse:
                <span class="text-dark font-weight-bold ms-sm-1 text-sm">
                {{ planification.adresse || "- Non précisée -" }}
              </span>
              </div>
            </div>

            <div class="d-flex flex-row gap-1">

              <a-button
                  class="text-xs text-link-blue bg-gray-200 d-flex flex-row align-items-center"
                  type="text"
                  @click="showPlanificator=!showPlanificator">
                <CalendarOutlined/>
                {{ livraisons?.length >= 1 ? "Mettre à jour la date" : "Planifier la livraison" }}
              </a-button>

              <!-- Ligne de temps du parcours de livraison d'un panier -->
              <CartTimeLineSide>
                <a-button
                    class="text-xs text-white bg-link-blue border-0 d-flex flex-row align-items-center"
                    type="default">
                  <i class="far fa-map px-1"></i>
                  Cartographie
                </a-button>
              </CartTimeLineSide>

            </div>

          </li>

          <li
              v-if="showPlanificator"
              class="d-flex justify-content-center flex-column  px-4 py-2 mb-2 bg-gray-100 border-radius-lg border-dashed border-1"
          >
            <div class="text-bold">Planifier la livraison</div>
            <p class="text-sm text-dark-500">Organiser les dates de livraison pour chaque commande des différents
              clients</p>
            <v-date-picker v-model="dateSchedule" :min-date="new Date()" borderless expanded mode="dateTime"
                           title-position="left"/>

            <div class="d-flex flex-row gap-1 py-1">
              <a-button :loading="isLoading"
                        class="text-xs text-link-blue font-weight-bold d-flex flex-row align-items-center"
                        type="text"
                        @click="scheduleNow">
                Sauvegarder
              </a-button>
              <a-button class="text-xs text-danger font-weight-bold d-flex flex-row align-items-center"
                        type="text"
                        @click="showPlanificator=!showPlanificator">
                Rénoncer
              </a-button>
            </div>
          </li>

          <li
              class="list-group-item border-0 d-flex flex-column px-4 py-2 mb-2 bg-gray-100 border-radius-lg"
          >
            <div class="font-weight-bold my-2 text-lg text-dark">Produit(s) du panier</div>
            <div class="d-flex cursor-pointer flex-column">
              <div v-for="item in panierCourant?.cartProducts" :key="item?.id"
                   class="mx-1 p-1 d-flex flex-column justify-content-between">
                <div class="text-decoration-underline text-dark text-bold">{{
                    item?.product?.name || 'Pas de nom'
                  }}
                </div>

                <div class="d-flex flex-row justify-content-between">
                  <span class="text-dark">Quantité commandée: </span>
                  <span class="font-weight-bold text-md">{{
                      item?.pieceQuantity || 'Qtité inconnue'
                    }}</span>
                </div>
                <div class="d-flex flex-row justify-content-between">
                  <span class="text-dark">Prix par unité: </span>
                  <span class="font-weight-bold text-md text-success">$ {{
                      item?.piecePrice || 'Prix inconnu'
                    }}</span>
                </div>
                <!--

                            <div class="d-flex flex-row justify-content-between">
                              <span class="text-decoration-underline text-dark">Prix unité: {{
                                  item?.product?.name || 'Pas de nom'
                                }}</span>
                              <span class="font-weight-bold text-md">3</span>
                            </div>
                -->

              </div>

            </div>
            <hr class="horizontal dark"/>

            <div class="d-flex flex-row justify-content-between px-2 my-3">
              <span class="text-dark">Total à payer: </span>
              <span class="font-weight-bold text-md text-primary">$ {{
                  panierCourant?.totalPrice || 'Total ...'
                }}</span>
            </div>

          </li>

          <li
              class="list-group-item border-0 d-flex flex-column p-4 mb-2 bg-gray-100 border-radius-lg"
          >
            <div class="font-weight-bold my-2 text-lg text-dark">Paiement(s)</div>
            <div class="mx-1 p-1 d-flex flex-row justify-content-between">
              <div class="font-weight-bold mt-1">Statut de paiement panier</div>
              <span
                  :class="{'bg-link-blue text-link-blue': isCompletePayed, 'bg-success text-success': isPayed, 'text-danger bg-danger': !isCompletePayed && !isPayed}"
                  class="text-danger text-xs text-white py-1 px-2 my-2 status-render">
          <i class="fas fa-circle p-1" style="font-size: 8px"></i>
          {{
                  isCompletePayed ? "Payé totalement" : isPayed ? "Payé en tranche" : "Non payé"
                }}</span>
            </div>

            <!--        Les derniers paiements éffectués pour ce panier-->
            <div class="d-flex cursor-pointer flex-column">
              <div v-for="item in panierCourant?.cartPayed" :key="item?.id"
                   class="mx-1 px-2 py-1 d-flex flex-row justify-content-between">

            <span class="text-dark text-decoration-underline">Paiement du <b>{{
                dayjs(item?.createdAt).format('DD MMM YYYY à HH:mm')
              }}</b></span>
                <span class="font-weight-bold text-success text-lg">$ {{ formatCurrency(item?.amount) || '-' }}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- ======================================================-->
  </a-drawer>

</template>

<style scoped>
.status-render {
  max-width: 165px;
}

</style>
