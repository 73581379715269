<script setup>
import {computed, ref} from "vue";
import {setArticlePrice} from "@/services-apis/articles/article-request";
import {useCommande} from "@/services-apis/commandes/hooks.commandes";
import {commandeStore} from "@/store/pinia-stores/commande-store";
import DetailsArticle from "@/views/modals/DetailsArticle.vue";
import formatCurrency from "../../middlewares/formatCurrency";

const props = defineProps({article: {type: Object}})
const prevPrice = computed(() => props.article.price)
const price = ref('')
const {commande, refetch} = useCommande()
const store = commandeStore()

// L'image de l'article
const image = computed(() => props.article?.images[0]?.url || require('@/assets/img/icon.png'))

// Envoyer le nouveau pricePerUnit
const handleChangePrice = () => {
  if (prevPrice.value !== price.value) {
    if (!price.value) {
      return
    }

    // Changer maintenant
    setArticlePrice(props.article.id,
        {commandId: Number(store.currentId), price: price.value, deviseId: 1},
        async () => {
          await refetch()
        },
        () => {
          price.value = ''
        }
    )
  } else {
    // console.log("Prix identique")
  }
}

</script>

<template>
  <div class="d-flex align-items-center justify-content-between w-100 p-2">
    <div class="d-flex align-items-center w-75">
      <details-article :article="article">
        <div class="d-flex flex-column">
          <h6 class="mb-1 text-dark text-md text-start" style="max-height: 6rem; overflow: hidden">
            {{ article?.name }}</h6>
          <span class="text-xs text-truncate" style="max-width: 250px; overflow-x: scroll;scrollbar-width: none;">
          {{ article?.description || '- Aucune description -' }}
        </span>

          <p class="text-dark text-sm text-start" style="max-height: 6rem; overflow: hidden">
            Qté : <span class="text-bold">{{ article?.quantity }}</span></p>
        </div>
        <div class="d-flex flex-row">
          <img :src="image" alt="Avatar"
               class="img-circle"/>
        </div>
      </details-article>

    </div>

    <div v-if="article?.price" class="d-flex flex-row align-items-center font-weight-bold">
      <input
          :readonly="commande?.isCompletePayed"
          :value="article?.price"
          class="form-control btn-xs text-success input-group-outline text-end border-0 btn-outline-success font-weight-bold mx-2"
          placeholder="pricePerUnit dans le champs" style="max-width: 90px; min-width: 90px"/>
      $
    </div>

    <!--          Si le price n'est pas connu -->
    <div v-if="!article?.price" class="d-flex flex-column align-items-center font-weight-bold">
      <input
          v-model="price"
          class="form-control btn-xs text-success input-group-outline text-end border-0 btn-outline-success font-weight-bold mx-2"
          placeholder="Fixer le prix"
          style="max-width: 105px" @blur.stop="handleChangePrice"/>

      <div class="my-1">
        <span class="text-capitalize text-xs">Un article coûtera</span>
        <h4 class="text-uppercase text-sm">{{ formatCurrency(Number(price)) }} $</h4>
      </div>

    </div>
  </div>

</template>
