<script setup>
import {useJustificationPayement} from "../../services-apis/payements/hooks.payements";
const logo = require('../../assets/img/icon.png')
const {images} = useJustificationPayement()
</script>

<template>
  <div class="card h-100 mb-4">
    <div class="card-header pb-0 px-3">
      <div class="row">
        <div class="col-md-12">
          <h6 class="mb-0 font-weight-bold">Images de la justification</h6>
        </div>
      </div>
    </div>
    <div class="card-body pt-4 p-3">

      <ul class="list-group">
        <li class="list-group-item" v-for="image in images" :key="image?.id">
          <a-image

              :width="200"
              :src="image?.url || logo"
          />
        </li>

      </ul>
    </div>


    <!--    Preview de l'image -->
    <a-modal :footer="null" :mask="false" :open="previewVisible" @cancel="handleCancel">
      <template #title>
        <h4 class="font-weight-bold">Aperçu de l'image</h4>
        <span>{{ previewTitle }}</span>
      </template>
      <a :href="previewImage" target="_blank">
        <img :src="previewImage" alt="example" style="width: 100%"/>
      </a>
    </a-modal>
  </div>

</template>
