
<script setup>
// Ce composant ouvre le modal qui permet de créer une requête de paiement
import {ref} from "vue";
import {useDraggableModal} from "@/hook/draggableModal";
import {payementReactive} from "@/reactiveState/payements";
import {useCommande} from "@/services-apis/commandes/hooks.commandes";
import {PayementTypeArray} from "@/store/pinia-stores/payements-store";
import {sendRequestPayement} from "@/services-apis/payements";
import {toast} from "vue3-toastify";
import {Styles} from "@/assets/js/styles";

const {commande} = useCommande()
const motif = ref('')
const modalTitleRef = ref(null);
const {transformStyle} = useDraggableModal(modalTitleRef);

const sendRequestCallback = () => {
  if (!motif.value) {
    return toast.error('Choisissez un motif valide', {
      position: 'bottom-center',
      hideProgressBar: true,
      bodyClassName: 'body-toaster',
      toastStyle: Styles.toastStyle
    })
  }

  sendRequestPayement({
    "commandId": commande.value?.id,
    "motif": motif.value
  }, () => {
  })
}

</script>

<template>

  <a-modal ref="modalRef" v-model:open="payementReactive.isOpenModalCreateReqPayement" :footer="false"
           :mask="false"
           :wrap-style="{ overflow: 'hidden' }">

    <!--   ============== Le contenu du modal =============== -->
    <div>

      <div class="card-header pb-0">
        <h5 id="exampleModalLabel" class="modal-title">Nouvelle requête de paiement</h5>
        <p class="text-dark font-weight-normal text-xs">Cette demande s'adresse au client
          <span class="text-bold text-sm">{{
              commande?.creator?.firstname || commande?.creator?.email
            }} {{ commande?.creator?.lastname || '' }}</span></p>
      </div>

      <ul class="list-group">
        <li class="list-group-item border-0 d-flex p-4 mb-2 mt-1 bg-gray-100 border-radius-lg">
          <div class="d-flex flex-column">
              <span class="text-dark ms-sm-1">
                Commande: <span class="mb-3 text-sm text-bold">{{ commande?.title }}</span>
              </span>

            <span class="text-dark ms-sm-1 mt-2">
                Statut actuel de la commande: <span class="mb-3 text-sm text-success text-bold">{{
                commande?.status
              }}</span>
              </span>

            <div class="d-grid row-cols-2">
             <span class="text-dark ms-sm-1">
               Motif du paiement
              </span>

              <a-select v-model:value="motif" class="mt-1" placeholder="Choisissez le motif de la requête ..."
                        style="width: 100%;">
                <a-select-option v-for="type in PayementTypeArray" :key="type.value" :value="type.value">{{
                    type.key
                  }}
                </a-select-option>
              </a-select>
            </div>

            <span class="text-secondary ms-sm-1 text-xs mt-2 lh-lg">
             Vous demanderez au client d'accepter votre requête suite à laquelle vous confirmerez le paiement de celui-ci. <br>Puis patientez pendant qu'il accepte, pendant ce temps vous pourrez faire autre dans l'application sans conséquence negative.</span>
          </div>
        </li>
      </ul>

      <div class="modal-footer border-0">
        <a-button class="text-xs btn-sm border-radius-xl font-weight-normal bg-danger text-white m-1"
                  @click="payementReactive.setModalCreateReqPayement(false)">
          Annuler
        </a-button>

        <a-button class="text-xs btn-sm border-radius-xl font-weight-normal bg-link-blue m-1 text-white" type="link"
                  @click="sendRequestCallback">
          Envoyer
        </a-button>
      </div>

    </div>
    <!-- ======================================================-->

    <template #title>
      <div ref="modalTitleRef" class="text-secondary font-weight-normal text-xs" style="width: 100%; cursor: move"><i
          class="fas fa-bars px-1"></i>Déplacer
      </div>
    </template>

    <template #modalRender="{ originVNode }">
      <div :style="transformStyle">
        <component :is="originVNode"/>
      </div>
    </template>

  </a-modal>

</template>
