<template>

  <a-modal ref="modalRef" v-model:open="payementReactive.isOpenModalCancelPayementReq" :closable="false"
           :footer="false"
           :mask="false"
           :mask-closable="false"
           :wrap-style="{ overflow: 'hidden' }">

    <!--   ============== Le contenu du modal =============== -->
    <div>

      <div class="card-header pb-0">
        <h5 class="modal-title text-danger">Demande de paiement annulée</h5>
        <p class="font-weight-normal text-sm mt-2">Il semblerait que le client
          <span class="text-bold text-dark text-sm">{{
              user?.firstname || user?.email
            }} {{ user?.lastname || '' }}</span> aît
          annulé votre demande de paiement sur sa commande.</p>
      </div>

      <ul class="list-group">
        <li class="list-group-item border-0 d-flex p-4 mb-2 mt-1 bg-gray-100 border-radius-lg">
          <div class="d-flex flex-column">

            <span class="text-dark ms-sm-1 mt-2">
                Commande: <span class="mb-3 text-sm text-bold">{{ commande?.title }}</span>
             </span>

            <span class="text-dark ms-sm-1 mt-2">
                Motif: <span class="mb-3 text-sm text-bold">{{ motifPayement }}</span>
             </span>

            <span class="text-dark ms-sm-1 mt-2">
                Montant: <span class="mb-3 text-sm text-bold">{{ Number(prixAPayer).toLocaleString('fr-FR') }} $</span>
             </span>

            <span class="text-dark ms-sm-1 mt-2">
                Statut du paiement: <span class="mb-3 text-sm text-bold text-success">Annulé</span>
             </span>

            <img alt="illustration" class="img-thumbnail img-fluid mt-3 shadow-none border-radius-2xl border-0" height="40" src="https://i.pinimg.com/564x/62/ab/5b/62ab5b67fac86b3596d8673a56dd8c64.jpg"
                 width="40"/>
          </div>

        </li>
      </ul>

      <div class="modal-footer border-0">
        <a-button class="text-xs btn-sm border-radius-xl font-weight-normal m-1" type="dashed"
                  @click="sendRequestCallback">
          Réfaire la demande
        </a-button>

        <a-button class="text-xs btn-sm border-radius-xl font-weight-normal bg-link-blue m-1 text-white" type="link"
                  @click="payementReactive.setIsOpenModalCancelPayementReq(false)">
          Ok
        </a-button>
      </div>

    </div>
    <!-- ======================================================-->

    <template #title>
      <div ref="modalTitleRef" class="text-secondary font-weight-normal text-xs" style="width: 100%; cursor: move"><i
          class="fas fa-bars px-1"></i>Déplacer
      </div>
    </template>

    <template #modalRender="{ originVNode }">
      <div :style="transformStyle">
        <component :is="originVNode"/>
      </div>
    </template>

  </a-modal>

</template>


<script setup>
// Ce composant ouvre le modal qui permet de valider/sauvegarder un paiement après, que le client aît annuler/refuser la requête de paiement
import {computed, ref} from "vue";
import {useDraggableModal} from "@/hook/draggableModal";
import {payementReactive} from "@/reactiveState/payements";
import {payementStore} from "@/store/pinia-stores/payements-store";
import {sendRequestPayement} from "@/services-apis/payements";

const modalTitleRef = ref(null);
const {transformStyle} = useDraggableModal(modalTitleRef);

const pStore = payementStore()
const user = computed(() => pStore.dataRequestAccepted?.user)
const commande = computed(() => pStore.dataRequestAccepted?.commande)
const motifPayement = computed(() => pStore.dataRequestAccepted?.motifPayement)
const prixAPayer = computed(() => pStore.dataRequestAccepted?.prixAPayer)
const motif = computed(() => pStore.dataRequestAccepted?.motif)

// Réfaire la demande paiement
const sendRequestCallback = () => {
  sendRequestPayement({
    "commandId": commande.value?.id,
    "motif": motif.value
  }, () => {
    payementReactive.setIsOpenModalCancelPayementReq(false)
  })
}

</script>