<script setup>
import {computed} from "vue";
import {messageReactive} from "@/reactiveState/message";
import {useUser} from "@/services-apis/users/hooks.user";

const props = defineProps({
  message: {
    type: Object,
  }
})

const {user}= useUser()

// Celui qui a envoyé le message
const author = computed(() => {
  if (props.message?.agentSender) {
    return `${props.message.agentSender.firstname || props.message.agentSender.email} ${props.message.agentSender.lastname || ''}`;
  } else if (props.message?.sender) {
    return `${props.message.sender.firstname || props.message.sender.email} ${props.message.sender.lastname || ''}`;
  }
  return '';
});

// Les accès
const fullPrivilegies = computed(() => {
  return props.message?.agentSender?.id !== user?.id;
});



// Les tags du message
const articles = computed(() => props.message?.articles)
const commande = computed(() => props.message?.commande)

// Activer la modification d'un message
const setEdition = () => {
  // Donnons la valeur du message à modifier
  messageReactive.messageEdition = props.message
  // Puis activons le modal de modification de message
  messageReactive.openEditionModal()
}

</script>

<template>
  <!--            Item de celui qui envoie-->
  <li class="list-group-item border-0 d-flex p-4 mb-2 bg-success-subtle border-radius-lg"
  >
    <div class="d-flex flex-column">
      <div class="d-flex flex-column">
        <div
            class="btn-link d-flex flex-row align-items-center border-radius-xl text-truncate mb-3">
          <div
              class="mt-1 bg-gradient-dark p-1 border-radius-xl d-flex flex-row align-items-center justify-content-center"
              style="width: 30px;height: 30px;"><i class="far fa-user text-white"></i></div>

          <span class="text-dark text-sm mx-2 mt-1 text-bold" style="max-width: 80%"> {{ author }} </span>
        </div>
        <span class="mb-2 text-sm">
              {{ message?.content || '[ Contenu vide ]' }}
            </span>
        <div class="mb-2 text-sm">
          Tags:
          <div class="d-flex flex-row flex-wrap">
            <span class="text-dark text-xs my-2 text-bold">{{ commande?.title }},</span>
            <span v-for="article in articles" :key="article.id"
                  class="text-dark text-xs my-2 text-decoration-underline">{{
                article?.title || article?.name + " ,"
              }}</span>
          </div>
        </div>

      </div>
    </div>

    <!--    Icone pour plus d'option sur un item de message-->
    <div class="ms-auto text-end" v-if="fullPrivilegies">
      <a-dropdown placement="bottomRight">
        <button class="btn btn-link text-dark mb-0" href="javascript:;">
          <i aria-hidden="true" class="fas fa-caret-down text-dark me-2"></i>
        </button>
        <template #overlay>
          <a-menu>
            <a-menu-item @click="setEdition">
              <span>Modifier le message</span>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>

    </div>
  </li>
</template>

<style scoped>

</style>