<template>

  <a-button
      :class="{'text-dark': commande?.isPackedForExpedition,'text-white': !commande?.isPackedForExpedition,}"
      :disabled="commande?.isPackedForExpedition"
      class="text-xs bg-link-blue mt-0 border-0 font-weight-normal" type="default"
      @click="showModal">
    {{ commande?.isPackedForExpedition ? "Déjà emballée" : "Créer le paquet d'expédition" }}
  </a-button>

  <a-button
      v-if="commande?.isPackedForExpedition"
      class="text-xs text-white bg-link-blue mt-0 border-0 font-weight-normal" type="default"
      @click="cStore.setIsOpenSideDetail(true)">
    Configuration expéditions
  </a-button>

  <a-modal ref="modalRef" v-model:open="open" :footer="false" :mask="false" :wrap-style="{ overflow: 'hidden' }"
           @ok="handleOk">

    <!--   ===========   Le contenu du modal ============ -->

    <div>
      <div class="">

        <div class="modal-header">
          <div class="col">
            <h5 class="modal-title text-dark">Emballer la commande</h5>
            <span class="text-xs text-bold text-dark text-decoration-underline">{{ commande?.title }}</span>
            <br>
          </div>
        </div>

        <div v-if="commande?.isPackedForExpedition" class="modal-body">
          <h4 class="text-sm">Statut de la commande</h4>

          <p class="text-sm font-weight-light text-secondary mt-3">
            Cette commande est déjà bien emballée et potentiellement en route pour le pays de destination.
          </p>

        </div>

        <div v-else class="modal-body">
          <h4 class="text-sm">Le poids réel du colis</h4>
          <a-input v-model:value="weight" class="py-2" placeholder="Valeur du weight" type="number">
            <template #prefix>
              <i class="fas fa-weight-hanging px-2 text-secondary"></i>
            </template>
            <template #suffix>
              <a-tooltip
                  title="Le poids est la valeur obtenue après avoir pèser les articles de la commande (déjà emballé)">
                <i class="fas fa-info px-1 text-secondary"></i>
              </a-tooltip>
            </template>
          </a-input>
          <span class="text-xs">Exprimé en kilogramme (kg)</span>


          <h4 v-if="flyFret" class="text-sm mt-3">Facteur du poids ( dans l'avion )</h4>
          <a-input v-if="flyFret" v-model:value="factorWeight" class="py-2" placeholder="Valeur du weight"
                   type="number">
            <template #prefix>
              <i class="fas fa-weight-hanging px-2 text-secondary"></i>
            </template>
            <template #suffix>
              <a-select
                  v-model:value="factorWeight"
                  style="width: 120px"
              >
                <a-select-option value="600">600</a-select-option>
                <a-select-option value="500">500</a-select-option>
              </a-select>
            </template>
          </a-input>

          <h4 class="text-sm pt-3">Les trois dimensions</h4>
          <div class="d-flex flex-row gap-1">

            <a-input v-model:value="height" class="text-secondary py-2" placeholder="Hauteur" type="number">
              <template #prefix>
                <i class="fas fa-text-height px-1"></i>
              </template>
              <template #suffix>
                <a-tooltip
                    title="La hauteur est prélevée après emballage de tous les articles.">
                  <i class="fas fa-info px-1"></i>
                </a-tooltip>
              </template>
            </a-input>

            <a-input v-model:value="length" class="text-secondary py-2" placeholder="Longueur" type="number">
              <template #prefix>
                <i class="fas fa-ruler-vertical px-1"></i>
              </template>
              <template #suffix>
                <a-tooltip
                    title="La longueur est prélevée après emballage de tous les articles.">
                  <i class="fas fa-info px-1"></i>
                </a-tooltip>
              </template>
            </a-input>

            <a-input v-model:value="width" class="text-secondary py-2" placeholder="Largeur" type="number">
              <template #prefix>
                <i class="fas fa-text-width px-1"></i>
              </template>
              <template #suffix>
                <a-tooltip
                    title="Le largeur est prélevée après emballage de tous les articles.">
                  <i class="fas fa-info px-1"></i>
                </a-tooltip>
              </template>
            </a-input>
          </div>

          <p class="text-sm font-weight-light text-secondary mt-3">Les dimensions que vous saisissez seront évaluées en
            centimètres (cm)</p>
        </div>

        <div class="modal-footer">
          <div class="d-flex flex-row justify-content-between w-100">

            <a-button
                class="text-xs text-danger bg-danger-soft mt-0 border-0 font-weight-normal" type="text"
                @click="handleOk">
              Fermer
            </a-button>

            <div class="flex flex-row">
              <PackCommandeTestResult :callbackSave="()=>empackedCommande(false)"
                                      :onPress="()=>empackedCommande(true)"/>

              <a-button
                  class="text-xs text-white bg-link-blue mt-0 border-0 font-weight-normal" type="default"
                  @click="empackedCommande(false)">
                Emballer
              </a-button>
            </div>

          </div>

        </div>
      </div>

    </div>

    <!--      ====================================================-->
    <template #title>
      <div ref="modalTitleRef" class="text-secondary font-weight-normal text-xs" style="width: 100%; cursor: move"><i
          class="fas fa-bars px-1"></i>Déplacer
      </div>
    </template>
    <template #modalRender="{ originVNode }">
      <div :style="transformStyle">
        <component :is="originVNode"/>
      </div>
    </template>
  </a-modal>


  <!--  Afficher le modal d'ajout de forfait -->
  <configure-flat-rate-commande/>

</template>


<script setup>
import {useDraggableModal} from "@/hook/draggableModal";
import {computed, ref} from "vue";
import {useCommande} from "@/services-apis/commandes/hooks.commandes";
import {createPackedCommande} from "@/services-apis/commandes";
import {toast} from "vue3-toastify";
import {Styles} from "@/assets/js/styles";
import PackCommandeTestResult from "@/views/modals/PackCommandeTestResult.vue";
import ConfigureFlatRateCommande from "./ConfigureFlatRateCommande.vue";
import {commandeReactive} from "../../reactiveState/commandes";
import {commandeStore} from "../../store/pinia-stores/commande-store";

const modalTitleRef = ref(null);
const {open, transformStyle, showModal, handleOk} = useDraggableModal(modalTitleRef);
const cStore = commandeStore()
const {commande, refetch} = useCommande()
// Si c'est un transport aérien
const flyFret = computed(() => commande.value?.transport?.way === 'AVION')

const length = ref(0)
const width = ref(0)
const weight = ref(0)
const height = ref(0)
const factorWeight = ref(600)

// Valider l'envoie du messsage
const empackedCommande = (testResultat) => {

  const options = {
    position: 'bottom-center',
    hideProgressBar: true,
    bodyClassName: 'body-toaster',
    toastStyle: Styles.toastStyle,
    autoClose: 2000,
  }

  // ==================== Valider le formulaire ===========================
  if (!Number(length.value) || Number(length.value) === 0) {
    return toast.error('Entrez une valeur valide de la longueur', options)
  }
  if (!Number(width.value) || Number(width.value) === 0) {
    return toast.error('Entrez une valeur valide de la largeur', options)
  }
  if (!Number(height.value) || Number(height.value) === 0) {
    return toast.error('Entrez une valeur valide de la hauteur', options)
  }
  // ========================================================================
  const data = {
    "weight": Number(weight.value),
    "height": Number(height.value),
    "width": Number(width.value),
    "length": Number(length.value),
    "estimateResultForTest": !!testResultat,
    "factorCubic": Number(factorWeight.value)
  }
  commandeReactive.setDataPacked(data)

  // Commencer la sauvegarde
  createPackedCommande(data, refetch, () => {
    resetStates()
  })

}
//
// // Vider les states à la fermeture tu modal
const resetStates = () => {
  length.value = 0
  weight.value = 0
  width.value = 0
  height.value = 0
  factorWeight.value = 600

  handleOk()
}

</script>
