<script setup>
import {ref} from "vue";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import {AgGridVue} from "ag-grid-vue3";
import {useCartsOrdered} from "../../services-apis/products/hooks.products";
import UserRender from "./aggrid-templates/UserRender.vue";
import CartProductsRender from "./aggrid-templates/CartProductsRender.vue";
import dayjs from "dayjs";
import StatusCartRender from "./aggrid-templates/StatusCartRender.vue";
import CartLivraisonAddressRender from "./aggrid-templates/CartLivraisonAddressRender.vue";

const search = ref('')
const {carts} = useCartsOrdered()

const colDefs = ref([
  {
    headerName: "Paniers des clients",
    children: [
      {
        headerName: "Client (demandeur)", field: "user", cellRenderer: UserRender,
        minWidth: 300,
      },
      {
        headerName: "Produits du panier",
        field: "cartProducts",
        minWidth: 350,
        width: 550,
        maxWidth: 900,
        cellRenderer: CartProductsRender
      },
      {
        headerName: "Facturation",
        field: "cartPayed",
        minWidth: 150,
        width: 160,
        maxWidth: 250,
        cellRenderer: StatusCartRender
      },
      // {headerName: "Livraison", field: "pricePerCarton", minWidth: 100, width: 170, maxWidth: 300},

      // {
      //   headerName: "Dernier paiement le",
      //   field: "createdAt",
      //   valueFormatter: (params) => dayjs(params.value).format('DD MMM. YYYY').toLowerCase(),
      //   minWidth: 100,
      //   width: 130,
      //   maxWidth: 170
      // },

      {
        headerName: "Adresse de livraison",
        field: "adresse",
        cellRenderer: CartLivraisonAddressRender,
        minWidth: 200,
        width: 230,
        maxWidth: 630
      },

      {
        headerName: "Créé le",
        field: "createdAt",
        valueFormatter: (params) => dayjs(params.value).format('DD MMM. YYYY').toLowerCase(),
        minWidth: 100,
        width: 130,
        maxWidth: 170
      },
    ]
  },

  {
    headerName: "",
    children: [
      {headerName: "", field: "", pinned: '', minWidth: 100, width: 170, maxWidth: 300},
    ]
  },

]);

const pagination = true;
const paginationPageSize = 10;
const paginationPageSizeSelector = [10, 20, 50, 100];

// console.log({carts})
</script>

<template>
  <div class="card mb-4 border-radius-md">
    <div class="d-flex justify-content-between">
      <div class="card-header pb-0">
        <h6>Produits commandés par les clients</h6>
      </div>

      <a-input
          v-model:value="search"
          class="m-3 border-radius-sm" placeholder="Recherche d'un produit"
          style="width:18vw;"
          type="text"
      />
    </div>

    <div class="card-body pt-0 pb-2 px-3">
      <ag-grid-vue
          :columnDefs="colDefs"
          :pagination="pagination"
          :paginationPageSize="paginationPageSize"
          :paginationPageSizeSelector="paginationPageSizeSelector"
          :row-height="55"
          :rowData="carts"
          class="ag-theme-quartz"
          style="height: 600px"
      >
      </ag-grid-vue>
    </div>
  </div>
</template>
