<script setup>
import {ref} from "vue";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import locale from "ant-design-vue/es/date-picker/locale/fr_FR";
import {globalReactive} from "../../../reactiveState/global";
import {expeditionStore} from "../../../store/pinia-stores/expedition-store";
import {createAirExpedition} from "../../../services-apis/expeditions";

// Références pour les champs de formulaire
const airlineName = ref("");
const flightNumber = ref("");
const airwayBillNumber = ref("");
const originAirport = ref("");
const destinationAirport = ref("");
const departureRangeDate = ref(null);

// État global et store
const states = globalReactive;
const store = expeditionStore();

// Désactiver les dates antérieures à aujourd'hui
const disabledDepartureDate = (current) => {
  return current && current < dayjs().startOf("day");
};

// Changement de date
const onRangeChange = (value, dateString) => {
  const first = dayjs(value[0]).utc()
  const last = dayjs(value[1]).utc()

  departureRangeDate.value = [first, last]
  console.log('Selected Time: ', value);
  console.log('Formatted Selected Time: ', dateString);
};

// Méthode pour sauvegarder l'expédition
const handleSaveExp = () => {
  createAirExpedition(
      {
        airlineName: airlineName.value,
        flightNumber: flightNumber.value,
        airwayBillNumber: airwayBillNumber.value,
        originAirport: originAirport.value,
        destinationAirport: destinationAirport.value,
        departDate: departureRangeDate.value ? departureRangeDate.value[0].toISOString() : null,
        estimatedArrival: departureRangeDate.value ? departureRangeDate.value[1].toISOString() : null,
        commandIds: [101, 102, 103],
      },
      () => {
      },
      "",
      () => {
        states.setIsOpenNewAirExp(false);
      }
  );
};


</script>

<template>
  <div>
    <slot/>
  </div>

  <a-drawer ref="modalRef" v-model:open="states.isOpenNewAirExp" :mask="false" width="550">
    <template #title>
      <div class="card-header pb-0 px-3">
        <h6 class="mb-0 modal-title">Nouvelle expédition aérienne</h6>
      </div>
    </template>

    <div>
      <div class="card-body py-1">
        <ul class="list-group">
          <li class="d-flex justify-content-center flex-column px-4 py-2 mb-2 bg-white border-radius-lg border-dashed border-1">
            <div class="font-weight-bold my-2 text-lg text-dark">Détails du cargo</div>

            <div class="mb-1 gap-2 d-flex flex-row justify-content-between align-items-center">
              <span class="text-sm">Compagnie:</span>
              <input
                  v-model="airlineName"
                  class="border-0 form-control bg-gray-100 font-weight-bold"
                  placeholder="Nom de la compagnie aérienne"
              />
            </div>

            <div class="mb-1 gap-2 d-flex flex-row justify-content-between align-items-center">
              <span class="text-sm">Numéro:</span>
              <input
                  v-model="flightNumber"
                  class="border-0 form-control bg-gray-100 font-weight-bold"
                  placeholder="Numéro de vol de l'avion"
              />
            </div>

            <div class="mb-1 gap-2 d-flex flex-row justify-content-between align-items-center">
              <span class="text-sm">ID AWB:</span>
              <input
                  v-model="airwayBillNumber"
                  class="border-0 form-control bg-gray-100 font-weight-bold"
                  placeholder="Identifiant unique (AWB)"
              />
            </div>

          </li>

          <li class="d-flex justify-content-center flex-column px-4 py-2 mb-2 bg-white border-radius-lg border-dashed border-1">
            <div class="font-weight-bold my-2 text-lg text-dark">Départ et arrivée</div>

            <div class="mb-1 gap-2 d-flex flex-row justify-content-between align-items-center">
              <span class="text-sm">Origine:</span>
              <input
                  v-model="originAirport"
                  class="border-0 form-control bg-gray-100 font-weight-bold"
                  placeholder="Aéroport de départ (pays, ville)"
              />
            </div>

            <div class="mb-1 gap-2 d-flex flex-row justify-content-between align-items-center">
              <span class="text-sm">Destination:</span>
              <input
                  v-model="destinationAirport"
                  class="border-0 form-control bg-gray-100 font-weight-bold"
                  placeholder="Aéroport d'atterrissage (pays, ville)"
              />
            </div>

            <div class="my-2 gap-2 d-flex flex-column">
              <span class="text-sm">Dates & heures:</span>
              <div class="gap-2 d-flex flex-row justify-content-between align-items-center">
                <a-range-picker
                    v-model="departureRangeDate"
                    :disabled-date="disabledDepartureDate"
                    :locale="locale"
                    allow-clear
                    class="border-0 bg-gray-100 p-2 w-100"
                    format="DD/MM/YYYY, HH:mm"
                    placeholder="Départ (date & heure)"
                    show-time
                    @change="onRangeChange"
                />
              </div>
            </div>
          </li>

          <li class="list-group-item border-0 d-flex flex-column px-4 py-2 mb-2 bg-white border-radius-lg">
            <div class="d-flex flex-row gap-1 py-1">
              <a-button :loading="store.isLoading" class="text-xs text-link-blue bg-gray-100 font-weight-bold"
                        type="text" @click="handleSaveExp">
                Sauvegarder
              </a-button>
              <a-button class="text-xs text-danger font-weight-bold" type="text"
                        @click="states.setIsOpenNewAirExp(false)">
                Rénoncer
              </a-button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </a-drawer>
</template>

<style scoped>
</style>
