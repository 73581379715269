<script setup>
import {computed} from "vue";
import {useStore} from "vuex";
import SidenavList from "./SidenavList.vue";
import logoDark from '@/assets/img/icon.png';
import logoWhite from '@/assets/img/splash.png';

const store = useStore();
const isRTL = computed(() => store.state.isRTL);
const layout = computed(() => store.state.layout);
const sidebarType = computed(() => store.state.sidebarType);
const darkMode = computed(() => store.state.darkMode);
</script>
<template>
  <div
      v-show="layout === 'default'"
      class="min-height-300 position-absolute w-100"
  />
  <!--  :class="`${darkMode ? 'bg-transparent' : 'bg-success'}`"-->

  <aside
      id="sidenav-main"
      :class="`${isRTL ? 'me-3 rotate-caret fixed-end' : 'fixed-start ms-3'}
      ${
        layout === 'landing' ? 'bg-transparent shadow-none' : ' '
      } ${sidebarType}`"
      class="my-2 overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs border-radius-md"
  >
    <div class="sidenav-header">
      <i
          id="iconSidenav"
          aria-hidden="true"
          class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
      ></i>

      <router-link class="m-0 navbar-brand" to="/">
        <img
            :src="darkMode || sidebarType === 'bg-default' ? logoWhite:logoDark "
            alt="main_logo"
            class="navbar-brand-img h-100"
        />

        <span class="ms-2 font-weight-bold me-2">Central Achat</span>
      </router-link>
    </div>

    <hr class="mt-0 horizontal dark"/>

    <sidenav-list/>
  </aside>
</template>
