<script setup></script>

<template>
  <div class="card">

    <div class="card-body pt-4 p-3 body">
      <h5 class="text-lg text-dark">Touchez un élément pour afficher ses détails</h5>
      <span class="text-md text-secondary p-4 text-center">Les informations qui seront affichées, ne sont rattâchées qu'à une expédition ou transporteur de l'expédition; càd lorsque vous affichez les résultats d'une expédition, vous affichez en fait les données sur le conteneur ou l'avion concerné</span>
    </div>
  </div>

</template>

<style scoped>
.body {
  min-height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
