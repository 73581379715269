<template>
  <div class="card cursor-pointer shadow-none w-100">
    <div class="card-body d-flex align-items-center py-1 w-100">
      <a-checkbox @change="change"/>
      <!-- Icon -->
      <div class="border-radius-xl mx-1 d-flex align-items-center justify-content-center"
           style="width: 40px; height: 40px;border: .5px solid rgba(128,128,128,0.27);padding: 4px;">
        <img
            :src="commande?.picture||'https://i.pinimg.com/736x/d8/4c/ce/d84cce83e759ed4a5759f6ed8e0ff3bd.jpg'"
            alt="Zoom icon"
            class="border-radius-xl"
            style="width: 30px; height: 30px;"/>
      </div>
      <!-- Content -->
      <div class="flex-grow-1">
        <strong class="fw-bold text-sm">{{ commande?.title }}</strong>
        <small class="text-muted font-weight-bold"> (De: {{ formatName(commande?.creator) }})</small>
        <div class="d-flex flex-row flex-wrap">
          <div v-for="article in commande?.articles" :key="article?.id"
               class="text-muted text-xs bg-success-soft p-1 mx-1 border-radius-lg">
            {{ article?.name }}
          </div>
        </div>

        <!-- Si l'expédition n'est pas payée entièrement pour un cas d'expédition aérienne -->
        <a-tag v-if="!commande?.isCompletePayedExpeditionFee" color="red">{{
            commande?.status
          }}
        </a-tag>
      </div>
    </div>
  </div>
</template>

<script setup>
import {formatName} from "../../middlewares/formatName";

const props = defineProps({
  commande: {
    type: Object
  }
})
const emit = defineEmits(['addThis', 'removeThis'])

// Lorsque la valeur du checkbox change
const change = (e) => {
  const checked = e.target.checked
  checked ?
      sendAdd() :
      sendRemove()
}

const sendAdd = () => {
  emit('addThis', {data: props?.commande})
}
const sendRemove = () => {
  emit('removeThis', {data: props?.commande})
}
</script>
