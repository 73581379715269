<script setup>
import {computed} from "vue";
import {productStore} from "../../../store/pinia-stores/products-store";
import router from "../../../router";

const props = defineProps(['params']) // On passe les paramètres Aggrid
const name = computed(() => props.params.data.name)
const product = computed(() => props.params.data)
const images = computed(() => props.params.data?.images)
const img_url = computed(() => images.value[0]?.url || require('@/assets/img/splash.png'))

const store = productStore()
const gotoEdition = () => {
  // Changez les valeurs pour aller éditer
  store.setCurrent(product.value)
  store.setCurrentId(product.value?.id)
  router.push({name: 'UpdateProduct'})
}

</script>

<template>
  <div class="d-flex align-items-center cursor-pointer flex-row" @click="openForOne">
    <a-button class="px-2" type="default" @click="gotoEdition()">
      <i class="fas fa-pen"></i>
    </a-button>

    <img :src="img_url" alt="ia"
         class="avatar-product"/>
    <span> {{ name || 'Pas de nom' }}</span></div>
</template>

<style scoped>
</style>
