<script setup>
import {computed} from "vue";
import {cartStore} from "../../../store/pinia-stores/cart-store";

const props = defineProps(['params']) // On passe les paramètres Aggrid
const cartProducts = computed(() => props.params.data.cartProducts)
// Le panier courant
const panier = computed(() => props.params.data)
const panierStore = cartStore()

const openForOne = () => {
  panierStore.setSideDetails(true)
  panierStore.setCurrentCart(panier)
}

</script>

<template>
  <div class="d-flex align-items-center flex-wrap cursor-pointer flex-row" @click="openForOne">
    <div v-for="cartProduct in cartProducts" :key="cartProduct?.id"
         class="mx-1 px-1 bg-success-soft border-radius-xl">
      <img
          :src="cartProduct?.product?.images?.[0]?.url || require('@/assets/img/splash.png')"
          class="avatar-product"/>
      <span class="text-decoration-underline"> {{ cartProduct?.product?.name || 'Pas de nom' }}</span></div>
  </div>
</template>

<style scoped>
</style>
