<script setup>
import router from "../../router";
import {useRoute} from "vue-router";
import {computed} from "vue";
const route = useRoute()

const currentRouteName = computed(() => {
  return route.name;
});
console.log(currentRouteName)

</script>

<template>
  <div>
    <a-page-header sub-title="" title="Espace Shop Pay" @back="() => $router.go(-1)">
      <template #extra>
        <a-button key="1" :type="currentRouteName==='accueil-paiements'? 'default':'text'" @click="router.push({name: 'accueil-paiements'})">Paniers payés</a-button>
<!--        <a-button key="2" :type="currentRouteName==='requete-paiements'? 'default':'text'" @click="router.push({name: 'requete-paiements'})">Requêtes</a-button>-->
        <a-button key="3" :type="currentRouteName==='justifications-paiements'? 'default':'text'" @click="router.push({name: 'justifications-paiements'})">Justifications</a-button>
      </template>
    </a-page-header>
  </div>
</template>

<style scoped>
.demo-page-header :deep(tr:last-child td) {
  padding-bottom: 0;
}
</style>
