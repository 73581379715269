export const formatName = (data) => {

    if (data?.firstname && data?.lastname) {
        return `${data?.firstname} ${data?.lastname}`;
    }
    if (data?.firstname) {
        return data?.firstname;
    }
    if (data?.lastname) {
        return data?.lastname;
    }

    // Si ni data?.firstname ni data?.lastname ne sont présents, retourner l'email
    return data?.email;
}
