// Accepter une commande
import {userStore} from "@/store/pinia-stores/user.store";
import api from "@/services-apis/axios-init";
import {toast} from "vue3-toastify";
import {Styles} from "@/assets/js/styles";
import {payementStore} from "@/store/pinia-stores/payements-store";
import {payementReactive} from "@/reactiveState/payements";

/*
* Confirmer un / plusieurs paiements
* */
export const confirmPayement = (data, refetch, messageSuccess) => {
    let token = userStore()?.tokens?.accessToken
    let headers = {Authorization: `Bearer ${token}`}

    const store = payementStore()
    store.setIsLoading(true)

    api.post(`/admin/payement/confirm`, data, {headers}).then(res => {
        const msg = res.data.message
        // console.log({commande})

        // On recharge les données
        refetch()

        // Arrêter le chargement
        store.setIsLoading(false)

        // Message du succès d'enregistrement user / admin
        toast.success(messageSuccess || msg || 'Payement confirmé avec succès', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle,
            autoClose: 3000,
        })

    }).catch(err => {
        // Arrêter le chargement
        store.setIsLoading(false)

        let msg = err.response.data.message

        toast.error(msg || 'impossible de confirmer un paiement actuellement', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle
        })

        console.log({err})

    })
}

// Désactiver les notifications d'un / plusieurs paiements
export const editNotificationState = (data, refetch, messageSuccess) => {
    let token = userStore()?.tokens?.accessToken
    let headers = {Authorization: `Bearer ${token}`}

    const store = payementStore()
    store.setIsLoading(true)

    api.post(`/admin/payement/switch-notification-state`, data, {headers}).then(res => {
        const msg = res.data.message
        // console.log({commande})

        // On recharge les données
        refetch()

        // Arrêter le chargement
        store.setIsLoading(false)

        // Message du succès d'enregistrement user / admin
        toast.success(messageSuccess || msg || 'Notifications désactivées', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle,
            autoClose: 3000,
        })

    }).catch(err => {
        // Arrêter le chargement
        store.setIsLoading(false)

        let msg = err.response.data.message

        toast.error(msg || 'impossible de désactiver les notifications d\'un paiement actuellement', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle
        })

        console.log({err})

    })
}

/*
* Faire une requête de paiement à un client par rapport à une commande
* */
export const sendRequestPayement = (data, refetch) => {
    let token = userStore()?.tokens?.accessToken
    let headers = {Authorization: `Bearer ${token}`}

    const store = payementStore()
    store.setIsLoading(true)

    api.post(`/admin/payement/request-payement-face-to-face`, data, {headers}).then(res => {
        const msg = res.data.message

        // On recharge les données
        refetch()

        // Arrêter le chargement
        store.setIsLoading(false)

        // Message du succès d'enregistrement user / admin
        toast.success(msg || 'Demande de paiement envoyée', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle,
            autoClose: 3000,
        })

        // En cas de succès on ferme les deux modaux sur le choix d'une commande & la création de la requête
        payementReactive.setModalChooseCommandeForReqPayement(false)
        payementReactive.setModalCreateReqPayement(false)
        // Afficher ce message en cas de succès d'envoi
        payementReactive.setModalSuccessReqPayement(true)
        // Afficher le spinner pendant l'attente de la réponse du client
        payementReactive.setIsWaitingAnswer(true)

    }).catch(err => {
        // Arrêter le chargement
        store.setIsLoading(false)

        let msg = err.response.data.message

        toast.error(msg || 'Impossible de lancer une demande de paiement actuellement', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle
        })

        console.log({err})

    })
}


/*
* Faire une requête de paiement à un client par rapport à une commande
* */
export const savePayementOfRequestId = (data, refetch) => {
    let token = userStore()?.tokens?.accessToken
    let headers = {Authorization: `Bearer ${token}`}

    const store = payementStore()
    store.setIsLoading(true)

    api.post(`/admin/payement/save-by-request`, data, {headers}).then(res => {
        const msg = res.data.message

        // On recharge les données
        refetch()

        // Arrêter le chargement
        store.setIsLoading(false)

        // Message du succès d'enregistrement user / admin
        toast.success(msg || 'Paiement sauvegardé', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle,
            autoClose: 3000,
        })

        // En cas de succès, on ferme les deux modaux sur le choix d'une commande & la création de la requête
        payementReactive.setModalChooseCommandeForReqPayement(false)
        payementReactive.setModalCreateReqPayement(false)
        // Afficher ce message en cas de succès d'envoi
        payementReactive.setModalSuccessReqPayement(false)
        // Afficher le spinner pendant l'attente de la réponse du client
        payementReactive.setIsWaitingAnswer(false)

    }).catch(err => {
        // Arrêter le chargement
        store.setIsLoading(false)

        let msg = err.response.data.message

        toast.error(msg || 'Impossible de lancer une demande de paiement actuellement', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle
        })

        console.log({err})

    })
}


/*
* Enregistrer un paiement grâce à sa justification
* */
export const savePayementOfJustificationId = (data, refetch) => {
    let token = userStore()?.tokens?.accessToken
    let headers = {Authorization: `Bearer ${token}`}

    const store = payementStore()
    store.setIsLoading(true)

    api.post(`/admin/payement/save-by-justification`, data, {headers}).then(res => {
        const msg = res.data.message

        // On recharge les données
        refetch()

        // Arrêter le chargement
        store.setIsLoading(false)

        // Message du succès d'enregistrement user / admin
        toast.success(msg || 'Paiement sauvegardé', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle,
            autoClose: 3000,
        })

        // En cas de succès, on ferme les deux modaux sur le choix d'une commande & la création de la requête
        payementReactive.setModalChooseCommandeForReqPayement(false)
        payementReactive.setModalCreateReqPayement(false)
        // Afficher ce message en cas de succès d'envoi
        payementReactive.setModalSuccessReqPayement(false)
        // Afficher le spinner pendant l'attente de la réponse du client
        payementReactive.setIsWaitingAnswer(false)

    }).catch(err => {
        // Arrêter le chargement
        store.setIsLoading(false)

        let msg = err.response.data.message

        toast.error(msg || 'Impossible de lancer une demande de paiement actuellement', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle
        })

        console.log({err})

    })
}

/*
* Enregistrer un paiement grâce à sa justification (pour les paniers)
* */
export const savePayementOfCartJustificationId = (data, refetch) => {
    let token = userStore()?.tokens?.accessToken
    let headers = {Authorization: `Bearer ${token}`}

    const store = payementStore()
    store.setIsLoading(true)

    api.post(`/admin/payement/save-by-cart-justification`, data, {headers}).then(res => {
        const msg = res.data.message

        // On recharge les données
        refetch()

        // Arrêter le chargement
        store.setIsLoading(false)

        // Message du succès d'enregistrement user / admin
        toast.success(msg || 'Paiement sauvegardé', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle,
            autoClose: 3000,
        })

        // En cas de succès, on ferme les deux modaux sur le choix d'une commande & la création de la requête
        payementReactive.setModalChooseCommandeForReqPayement(false)
        payementReactive.setModalCreateReqPayement(false)
        // Afficher ce message en cas de succès d'envoi
        payementReactive.setModalSuccessReqPayement(false)
        // Afficher le spinner pendant l'attente de la réponse du client
        payementReactive.setIsWaitingAnswer(false)

    }).catch(err => {
        // Arrêter le chargement
        store.setIsLoading(false)

        let msg = err.response.data.message

        toast.error(msg || 'Impossible de lancer une demande de paiement actuellement', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle
        })

        console.log({err})

    })
}
