<template>
  <div>
    <div class="d-flex justify-content-between">
      <p class="text-secondary font-weight-bold">
        Justifications de paiement du client
      </p>
      <a-input-search
          v-model:value="search"
          class="w-25"
          placeholder="Entrez un mot clé. Ex: Solde/Acompte"
          size="large"
      >
        <template #enterButton>
          <a-button><i class="fas fa-search"></i></a-button>
        </template>
      </a-input-search>
    </div>


    <table class="table align-items-center justify-content-center mb-0">
      <thead>
      <tr>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
          Référence
        </th>

        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
          Statut
        </th>

        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
          Réf. du paiement
        </th>
        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
          Date de paiement
        </th>
        <th class="text-uppercase text-dark text-xxs font-weight-bolder opacity-7 ps-2">
        </th>

      </tr>
      </thead>

      <tbody>
      <!-- Boucle sur les paiements -->
      <tr v-for="justification in filteredPayementLines" :key="justification?.id">
        <td class="cursor-pointer" @click="getToOne(justification?.id)">
          <div class="d-flex px-2">
            <i class="fas fa-store mx-2 bg-white text-secondary px-2 pt-2"
               style="height: 33px; width: 33px"></i>
            <div class="my-auto">
              <h6 class="mb-0 text-sm text-truncate" style="width: 10rem">Achat produits </h6>
            </div>
          </div>
        </td>

        <td>
          <a-tag
              :color="justification?.isDeclined ? 'red' :justification?.isConfirmed ? 'orange':'cyan'"
              class="text-xs font-weight-bold">
            {{
              justification?.isDeclined ? 'Preuve déclinée' : justification?.isConfirmed ? 'Payée • Preuve validée' : 'Validation en attente'
            }}
          </a-tag>
        </td>

        <td>
          <div class="text-xs font-weight-bold text-truncate" style="width: 200px">{{
              justification?.reference
            }}
          </div>
        </td>

        <td>
          <span class="text-xs font-weight-bold">{{
              dayjs(justification?.datePayement).locale('fr').format('DD MMM YYYY')
            }}</span>
        </td>

        <td>
          <button class="btn btn-link text-success text-xs pb-0" type="button" @click="getToOne(justification?.id)">
            Lire détails
          </button>
        </td>

      </tr>
      </tbody>
    </table>

    <!--    Le pied de la table-->
    <div class="d-flex justify-content-between align-items-center flex-nowrap border-top p-3 custom-footer-table">

      <a-button class="text-uppercase text-dark text-xxs font-weight-bolder" @click="()=>{
        // Si nous sommes deja en mode ONE_USER alors, on va désactiver le mode
        justOneUser ? showAllUser():
        showOnlyOneUser()
      }">
        {{ justOneUser ? 'Voir moins' : 'Voir tous' }}
      </a-button>

      <div>
        <a-pagination v-model:current="currentPage" :total="lenPage" size="small"/>
      </div>

    </div>

  </div>
</template>

<script setup>
import dayjs from "dayjs";
import {computed, ref} from "vue";
import validator from "validator";
import {payementStore} from "../../store/pinia-stores/payements-store";
import router from "../../router";

const pStore = payementStore()

const props = defineProps(['itemJustificationCart', 'showOnlyOneUser', 'showAllUser',
  // Ceci vérifie si un seul user est en vue (actif)
  'justOneUser'])

const search = ref('');

// Les configurations de la pagination
const currentPage = ref(1);
const lenPage = computed(() => {
  const totalItems = props.itemJustificationCart?.paiements?.length || 0;

  if (totalItems <= 5) {
    // Si on a 5 éléments ou moins, on affiche tout sur une seule page
    return 1;
  }

  // Pour les éléments au-delà de 5 : on calcule le nombre de pages restantes après avoir affiché 5 éléments sur la première page
  const remainingItems = totalItems - 5;

  // Le nombre de pages nécessaires après la première page (avec 10 éléments par page)
  // On multiplie par 10 pour s'assurer que le composant pagination collecte bien toutes les données afin d'afficher un bon nombe des pages
  const additionalPages = Math.ceil(remainingItems / 10) * 10;

  // 1 page pour les 5 premiers éléments + pages supplémentaires
  return additionalPages + 1;
});
const paginatedPayments = computed(() => {
  const totalPayments = props.itemJustificationCart?.paiements || [];
  const activePage = currentPage.value;

  if (activePage === 1) {
    // Première page : afficher les 5 premiers éléments
    return totalPayments.slice(0, 5);
  } else {
    // Autres pages : afficher 10 éléments par page
    const start = 5 + (activePage - 2) * 10;
    const end = start + 10;
    return totalPayments.slice(start, end);
  }
});

// Les paiements (facturations) par page : filtrés
const filteredPayementLines = computed(() => paginatedPayments.value?.filter(item => (
    validator.contains(item?.user?.firstname, search.value, {ignoreCase: true})
    ||
    validator.contains(item?.user?.lastname, search.value, {ignoreCase: true})
    ||
    validator.contains(item?.user?.email, search.value, {ignoreCase: true})
)))

// Aller à une justification de paiement
const getToOne = (id) => {
  pStore.setCurrentJustificationCartId(id)
  router.push({name: 'details-justifications'})
}


</script>
