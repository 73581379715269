import {createRouter, createWebHistory} from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import VirtualReality from "../views/VirtualReality.vue";
import RTL from "../views/Rtl.vue";
import Profile from "../views/Profile.vue";
import Signup from "../views/Signup.vue";
import Signin from "../views/Signin.vue";
import DetailCommande from "@/views/DetailCommande.vue";
import Commandes from "@/views/Commandes.vue";
import Facturations from "@/views/Facturations.vue";
import DetailPayement from "@/views/DetailPayement.vue";
import {checkUserLoggedIn} from "@/services-apis/users";
import Shop from "../views/Shop.vue";
import ProductsTable from "../views/components/ProductsTable.vue";
import CreateProduct from "../views/components/CreateProduct.vue";
import IndexCommandes from "../views/IndexCommandes.vue";
import IndexPayements from "../views/IndexPayements.vue";
import RequestsPayement from "../views/RequestsPayement.vue";
import JustificationsPayement from "../views/JustificationsPayement.vue";
import DetailJustificationPayement from "../views/DetailJustificationPayement.vue";
import JustificationsCartPayement from "../views/JustificationsCartPayement.vue";
import PayementBoutique from "../views/PayementBoutique.vue";
import IndexPayementBoutique from "../views/IndexPayementBoutique.vue";
import RequestsPayementPanier from "../views/RequestsPayementPanier.vue";
import IndexShopPanier from "../views/IndexShopPanier.vue";
import PaniersCommandesTable from "../views/components/PaniersCommandesTable.vue";
import DetailJustificationCartPayement from "../views/DetailJustificationCartPayement.vue";
import IndexCalendar from "../views/calendar/IndexCalendar.vue";
import DetailEventExpeditionCard from "../views/components/DetailEventExpeditionCard.vue";
import PlanificatorHome from "../views/default-pages-views/PlanificatorHome.vue";
import UpdateProduct from "../views/components/UpdateProduct.vue";

const routes = [
    {
        path: '/',
        redirect: "dashboard-default",
        meta: {requiresAuth: true},
    },
    {
        path: "/dashboard-default",
        name: "Dashboard",
        component: Dashboard,
        meta: {requiresAuth: true},
    },
    {
        path: "/commandes",
        name: "Commandes",
        component: IndexCommandes,
        redirect: 'accueil-commande',
        meta: {requiresAuth: true},
        children: [{
            path: "/accueil-commande",
            name: "accueil-commande",
            component: Commandes,
        },
            {
                path: "/details",
                name: "Details-Commande",
                component: DetailCommande,
            },],
    },
    {
        path: "/facturations",
        name: "Facturations",
        component: IndexPayements,
        redirect: 'accueil-facturations',
        meta: {requiresAuth: true},
        children: [
            {
                path: "/accueil-facturations",
                name: "accueil-facturations",
                component: Facturations,
            },
            {
                path: "/details-payement",
                name: "details-payement",
                component: DetailPayement,
            },

            {
                path: "/requete-payement",
                name: "requete-payement",
                component: RequestsPayement,
            },

            {
                path: "/justifications-payement",
                name: "justifications-payement",
                component: JustificationsPayement,
            },

            {
                path: "/justifications-details",
                name: "justifications-details",
                component: DetailJustificationPayement,
            },
        ],
    },


    // =============================================================
    // Les écrans de la boutique
    // =============================================================
    {
        path: "/shop",
        name: "Shop",
        redirect: "shop-home",
        component: Shop,
        children: [{
            path: "/shop-home",
            name: "ShopHome",
            component: ProductsTable,
        }, {
            path: "/create-product",
            name: "CreateProduct",
            component: CreateProduct,
        }, {
            path: "/update-product",
            name: "UpdateProduct",
            component: UpdateProduct,
        }],
        meta: {requiresAuth: true},
    },

    {
        path: "/paniers-commandes",
        name: "paniers-commandes",
        redirect: "accueil-paniers-commandes",
        component: IndexShopPanier,
        children: [{
            path: "/accueil-paniers-commandes",
            name: "accueil-paniers-commandes",
            component: PaniersCommandesTable,
        },
        ],
        meta: {requiresAuth: true},
    },

    {
        path: "/paiements",
        name: "Paiements",
        component: IndexPayementBoutique,
        redirect: 'accueil-paiements',
        meta: {requiresAuth: true},
        children: [
            {
                path: "/accueil-paiements",
                name: "accueil-paiements",
                component: PayementBoutique,
            },
            {
                path: "/details-paiements",
                name: "details-paiements",
                component: DetailPayement,
            },


            // ============ ecran à rétirer ============
            {
                path: "/requete-paiements",
                name: "requete-paiements",
                component: RequestsPayementPanier,
            },
            // ============ ============ ============ ==


            {
                path: "/justifications-paiements",
                name: "justifications-paiements",
                component: JustificationsCartPayement,
            },

            {
                path: "/details-justifications",
                name: "details-justifications",
                component: DetailJustificationCartPayement,
            },
        ],
    },

    {
        path: "/calendar",
        name: "calendar",
        component: IndexCalendar,
        redirect: 'planificator-home',
        meta: {requiresAuth: true},
        children: [
            {
                path: "/planificator-home",
                name: "planificator-home",
                component: PlanificatorHome,
            },
            {
                path: "/expedition-details",
                name: "expedition-details",
                component: DetailEventExpeditionCard,
            },
        ],
    },
    // =============================================================
    // =============================================================


    // =============================================================

    {
        path: "/virtual-reality",
        name: "Virtual Reality",
        component: VirtualReality,
        meta: {requiresAuth: true},
    },
    {
        path: "/rtl-page",
        name: "RTL",
        component: RTL,
        meta: {requiresAuth: true},
    },
    {
        path: "/profile",
        name: "Profile",
        component: Profile,
        meta: {requiresAuth: true},
    },

    {
        path: "/signin",
        name: "Signin",
        component: Signin,
    },
    {
        path: "/signup",
        name: "Signup",
        component: Signup,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: "active",
});

router.beforeEach(async (to, from, next) => {
    const isLoggedIn = await checkUserLoggedIn(); // Implement login check logic

    if (to.matched.some((record) => record.meta.requiresAuth) && !isLoggedIn) {
        next({path: '/signin', query: {redirect: to.fullPath}}); // Pass redirect URL
    } else {
        next(); // Allow navigation if authenticated or route doesn't require auth
    }
});

export default router;
