<template>

  <a-button class="border-radius-2xl bg-link-blue text-xs text-white" type="link" @click="showModal">
    Approuver
  </a-button>

  <a-modal v-model:open="open" :footer="false" :mask="false" :wrap-style="{ overflow: 'hidden' }"
           @ok="handleOk">

    <!--   ============== Le contenu du modal =============== -->
    <div>

      <div class="">

        <div class="modal-header">
          <div class="col">
            <h5 class="modal-title">Approuver l'authenticité de la justification</h5>
            <p class="text-sm text-secondary">
              Vous éffectuez la validation du paiement<span class="text-sm text-bold text-dark">{{ getTypeOnlyText(justification?.motif) }}</span> de
              la commande
              <span class="text-sm text-bold text-dark"></span>
            </p>

          </div>
        </div>

        <div class="modal-body">

          <p class="text-dark font-weight-normal text-sm">
            Vous êtes sur le point de confirmer cette justification de paiement. Cette opération enregistrera le
            paiement en tant que <span class="text-bold text-decoration-underline text-dark">"paiement authentique".</span>
          </p>

          <strong class="text-bold text-sm text-dark">Montant présent dans la justification:</strong>
          <form-input v-model="amount" class="w-100 mb-0"
                      placeholder="Montant dont le client a payé pour cette justification"
                      type="number"/>
          <div class="text-secondary text-xs mt-1 mb-3">
            Ce montant figure dans la liste de vos transactions (numéro compte de compte, ou mobile money) officiel C.A;
          </div>

          <strong class="text-bold text-sm text-dark">Confirmer votre identité en tapant votre mot de passe:</strong>
          <form-input v-model="password" class="w-100"
                      placeholder="Mot de passe associé à votre compte"
                      type="text"/>

          <p class="text-dark text-sm">
            Notez que cette opération est irréversible.
          </p>

          <div class="modal-footer">
            <div class="flex align-items-end">
              <form-button class="text-xs btn-sm border-radius-xl font-weight-normal bg-danger m-1" type="button"
                           @click="handleOk">
                Annuler
              </form-button>
              <form-button class="text-xs btn-sm border-radius-xl font-weight-normal m-1" type="button"
                           @click="handleSetApprobation">
                {{ store.isLoading ? 'Patientez...' : 'Enregistrer maintenant' }}
              </form-button>
            </div>
          </div>
        </div>

      </div>

      <contextHolder/>
    </div>
    <!-- ======================================================-->

    <template #title>
      <div ref="modalTitleRef" class="text-secondary font-weight-normal text-xs" style="width: 100%; cursor: move"><i
          class="fas fa-bars px-1"></i>Déplacer
      </div>
    </template>

    <template #modalRender="{ originVNode }">
      <div :style="transformStyle">
        <component :is="originVNode"/>
      </div>
    </template>

  </a-modal>

</template>


<script setup>

import FormButton from "@/components/FormButton.vue";
import FormInput from "@/components/FormInput.vue";
import {computed, ref} from "vue";
import {commandeStore} from "@/store/pinia-stores/commande-store";
import {userStore} from "@/store/pinia-stores/user.store";
import {useDraggableModal} from "@/hook/draggableModal";
import {message} from 'ant-design-vue';
import {_loginWithAndPassword} from "../../services-apis/users";
import {useTypeText} from "../../hook/frontHooks";
import {savePayementOfCartJustificationId} from "../../services-apis/payements";
import {useJustificationPayement} from "../../services-apis/payements/hooks.payements";

const props = defineProps(['commande', 'justification'])

const [messageApi, contextHolder] = message.useMessage();
const {getTypeOnlyText} = useTypeText()
const modalTitleRef = ref(null);
const {open, transformStyle, showModal, handleOk} = useDraggableModal(modalTitleRef);
const {refetch} = useJustificationPayement()
const store = commandeStore()
const ustore = userStore()
const email = computed(() => ustore.user?.email)

const password = ref("")
const amount = ref("")

/* Lancer l'approbation */
const handleSetApprobation = () => {

  // Exiger le montant
  if (!amount.value) {
    messageApi.error({
      content: () => 'Montant de la justification obligatoire',
      style: {
        marginTop: '88vh',
      },
    });

    return;
  }

  // Exiger le mot de passe
  if (!password.value) {
    messageApi.error({
      content: () => 'Mot de passe obligatoire',
      style: {
        marginTop: '88vh',
      },
    });

    return;
  }

  // Authentifier l'utilisateur pour confirmer son identité
  _loginWithAndPassword(email.value, password.value, true, sendApprobation)

}

/* Si tout va bien j'enregistre le paiement */
const sendApprobation = () => {
  savePayementOfCartJustificationId({
    "justificationId": props.justification?.id,
    "amount": Number(amount.value)
  }, refetch)
}


</script>
