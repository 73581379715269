<template>

  <a-modal ref="modalRef" v-model:open="payementReactive.isOpenModalReceiverPayementRequestOfClients" :closable="false"
           :footer="false"
           :mask="false"
           :mask-closable="false"
           :wrap-style="{ overflow: 'hidden' }">

    <!--   ============== Le contenu du modal =============== -->
    <div>

      <div class="card-header pb-0">
        <h5 id="exampleModalLabel" class="modal-title">Demande de validation d'un paiement</h5>
        <p class="text-dark font-weight-normal text-xs mt-2">Vous avez une nouvelle demande de paiement (confirmation de
          paiement) de la part du client
          <span class="text-bold text-sm">{{
              user?.firstname || user?.email
            }} {{ user?.lastname || '' }}</span>. Accepter afin d'enregistrer ces coûts.</p>
      </div>

      <ul class="list-group">
        <li class="list-group-item border-0 d-flex p-4 mb-2 mt-1 bg-gray-100 border-radius-lg">
          <div class="d-flex flex-column">
             <span class="text-dark ms-sm-1 mt-2">
                Commande: <span class="mb-3 text-sm text-bold">{{ commande?.title }}</span>
             </span>

            <span class="text-dark ms-sm-1 mt-2">
                Motif: <span class="mb-3 text-sm text-bold">{{ motifPayement }}</span>
             </span>

            <span class="text-dark ms-sm-1 mt-2">
                Montant: <span class="mb-3 text-sm text-bold">{{ Number(prixAPayer).toLocaleString('fr-FR') }} $</span>
             </span>

            <span class="text-secondary ms-sm-1 text-xs mt-2 lh-lg">
              En sauvegardant cette transaction, le client sera notifier sur ton téléphone et recevra une facture par email.
            </span>
          </div>
        </li>
      </ul>

      <div class="modal-footer border-0">
        <a-button class="text-xs btn-sm border-radius-xl font-weight-normal bg-danger m-1 text-white" type="link"
                  @click="payementReactive.setIsOpenModalReceiverPayementRequestOfClients(false)">
          Quitter sans sauvegarder
        </a-button>

        <a-button class="text-xs btn-sm border-radius-xl font-weight-normal bg-link-blue m-1 text-white" type="link"
                  @click="savePayement">
          {{ pStore.isLoading ? "Sauvegarde ..." : "Sauvegarder" }}
        </a-button>
      </div>

    </div>
    <!-- ======================================================-->

    <template #title>
      <div ref="modalTitleRef" class="text-secondary font-weight-normal text-xs" style="width: 100%; cursor: move"><i
          class="fas fa-bars px-1"></i>Déplacer
      </div>
    </template>

    <template #modalRender="{ originVNode }">
      <div :style="transformStyle">
        <component :is="originVNode"/>
      </div>
    </template>

  </a-modal>

</template>


<script setup>
// Ce composant ouvre le modal qui permet de valider/sauvegarder un paiement après, que le client aît accepter la requête de paiement
// Qui lui était envoyée
import {computed, ref} from "vue";
import {useDraggableModal} from "@/hook/draggableModal";
import {payementReactive} from "@/reactiveState/payements";
import {payementStore} from "@/store/pinia-stores/payements-store";
import {savePayementOfRequestId} from "../../services-apis/payements";

const modalTitleRef = ref(null);
const {transformStyle} = useDraggableModal(modalTitleRef);

const pStore = payementStore()
const user = computed(() => pStore.dataRequestFromClient?.user)
const commande = computed(() => pStore.dataRequestFromClient?.commande)
const motifPayement = computed(() => pStore.dataRequestFromClient?.motifPayement)
const prixAPayer = computed(() => pStore.dataRequestFromClient?.prixAPayer)

// Sauvegarde d'un paiement après action de validation côté client
const savePayement = () => {
  savePayementOfRequestId({
    "requestId": pStore.dataRequestFromClient?.requestId,
    "commandId": commande.value?.id
  }, () => {
    payementReactive.setIsOpenModalReceiverPayementRequestOfClients(false)
  })
}
</script>
