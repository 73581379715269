<script setup>
import Loading from "@/views/components/Loading.vue";
import RequestPayementTable from "./components/RequestPayementTable.vue";
import {useRequestPayement} from "../services-apis/payements/hooks.payements";
const {isLoading} = useRequestPayement()
</script>
<template>
  <div class="container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <request-payement-table />
        <Loading v-if="isLoading"/>
      </div>
    </div>
  </div>
</template>
