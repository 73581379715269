<script setup>
import {computed} from "vue";
import {MailOutlined, PhoneOutlined} from '@ant-design/icons-vue'
import {payementStore} from "../../store/pinia-stores/payements-store";
import {
  useJustificationCartPayement,
} from "../../services-apis/payements/hooks.payements";
import dayjs from "dayjs";
import {useSolutionPayementText, useTypeText} from "../../hook/frontHooks";
import ApprouveJustificationCartPayement from "../modals/ApprouveJustificationCartPayement.vue";

const pStore = payementStore()
const justificationId = computed(() => pStore.currentJustificationId)
const {justification, commande, refetch} = useJustificationCartPayement()
const {getTypeOnlyText} = useTypeText()
const {getTextSolution} = useSolutionPayementText()

console.log({id: justificationId })

const refreshData = async () => {
  await refetch()
}
console.log(refreshData)
// Le transport de la commande
const mailToLink = computed(() => `mailto:${commande.value?.creator?.email}?subject=${encodeURIComponent(`Contact sur la commande ${commande.value?.title}`)}&body=${encodeURIComponent(`Bonjour client ${commande.value?.creator?.firstname || ''} ${commande.value?.creator?.lastname || ''},`)}`)

</script>

<template>

  <div class="card">
    <div class="card-header pb-0 px-3">
      <h6 class="mb-0 font-weight-bold">Informations sur la justification du paiement du panier</h6>
    </div>
    <div class="card-body pt-4 p-3">
      <ul class="list-group">

        <!--        Les articles de la commande-->
        <li
            class="list-group-item border-0 d-flex p-4 mb-2 mt-3 bg-gray-100 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <h6 class="mb-2 text-sm font-weight-bold">Informations de référence sur la justification</h6>

            <span class="mb-2 text-sm">
              Indexation C.A:
              <span class="text-link-blue ms-sm-1 text-sm font-weight-bold text-decoration-underline">
                CA-J{{ justification?.id }}
              </span>
            </span>

            <span class="mb-2 text-sm">
              Référence:
              <span class="text-link-blue ms-sm-1 text-sm font-weight-bold text-decoration-underline">
                {{ justification?.reference || '-' }}
              </span>
            </span>
            <span class="mb-2 text-sm">
              Solution de paiement:
              <span class="text-link-blue ms-sm-1 text-sm font-weight-bold text-decoration-underline">
                {{ getTextSolution(justification?.solution_payement) }}
              </span>
            </span>

            <span class="mb-2 text-sm">
              Motif du paiement:
              <span class="text-link-blue ms-sm-1 text-sm font-weight-bold text-decoration-underline">
                {{ getTypeOnlyText(justification?.motif) }}
              </span>
            </span>
            <span class="mb-2 text-sm">
              Date de paiement (virement):
              <span class="text-link-blue ms-sm-1 text-sm font-weight-bold text-decoration-underline">
                {{ dayjs(justification?.datePayement).locale('fr').format('DD MMMM YYYY') }}
              </span>
            </span>
            <span class="mb-2 text-sm">
              Date de création (date où le client s'est justifié auprès de C.A):
              <span class="text-link-blue ms-sm-1 text-sm font-weight-bold text-decoration-underline">
                {{ dayjs(justification?.createdAt).locale('fr').format('DD MMMM YYYY') }}
              </span>
            </span>

            <div class="d-inline-grid">

              <approuve-justification-cart-payement v-if="!justification?.isConfirmed" :justification="justification" :commande="commande"/>

              <a-button class="border-radius-2xl text-xs text-danger mx-2 border-1 border-danger border-dashed font-weight-bold" type="link" >
                Désapprouver
              </a-button>
            </div>

          </div>
        </li>

        <li
            class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <h6 class="mb-3 text-sm">Auteur (client)</h6>
            <span class="mb-2 text-sm">
              Nom complet:
              <span class="text-dark font-weight-bold ms-sm-1 text-sm">
                {{ commande?.creator?.firstname || 'Non défini' }} {{ commande?.creator?.lastname }}
              </span>
            </span>
            <span class="mb-2 text-sm">
              Adresse e-mail:
              <a :href="mailToLink"
                 class="text-link-blue ms-sm-1 text-sm font-weight-bold text-decoration-underline">
                {{ commande?.creator?.email }}
                <MailOutlined class="pb-1"/>
              </a>
            </span>

            <span class="mb-2 text-sm">
              Téléphone:
              <a v-if="commande?.creator?.phoneNumber" :href="'tel:'+commande?.creator?.phoneNumber"
                 class="text-link-blue ms-sm-1 text-sm font-weight-bold text-decoration-underline">
                {{ commande?.creator?.phoneNumber }}
                    <PhoneOutlined class="pb-1"/>
              </a>
              <span v-else class="text-dark ms-sm-1 text-sm font-weight-bold">
                Aucun numéro fournis
              </span>
            </span>

            <span class="mb-2 text-sm">
              ID.Unique:
              <span class="text-dark ms-sm-1 text-sm font-weight-bold">
                {{ commande?.creator?.uuid }}
              </span>
            </span>

          </div>
        </li>

        <!--        Détails textuel de la commande (informations avancées) -->
        <li
            class="list-group-item border-0 d-flex p-4 mb-2 mt-3 bg-gray-100 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <h6 class="mb-2 text-sm font-weight-bold">Panier et produits</h6>

            <!--            Informations des articles-->
            <h6 class="mb-1 mt-2 text-sm text-uppercase text-secondary font-weight-bold">Articles</h6>

            <span class="mb-2 text-sm">
              En attente:
              <span class="text-dark ms-sm-1 text-sm font-weight-bold">
                {{ commande?.articles.length - commande?.articleAvailable }} article(s)
              </span>
            </span>

            <span class="mb-2 text-sm">
              Total:
              <span class="text-dark ms-sm-1 text-sm font-weight-bold"
              >{{ commande?.articles.length }} article(s)</span
              >
            </span>

          </div>
        </li>

      </ul>
    </div>
  </div>

</template>
