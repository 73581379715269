import {useQuery} from "@tanstack/vue-query";
import api from "@/services-apis/axios-init";
import {userStore} from "@/store/pinia-stores/user.store";
import {commandeStore} from "@/store/pinia-stores/commande-store";

// Utiliser tous les messages d'une commande
export const useMessageByCommande = (idCommande) => {

    // On récupère l'id depuis le store
    const store = commandeStore()
    let id = store.currentId || idCommande
    let token = userStore()?.tokens?.accessToken
    let headers = {Authorization: `Bearer ${token}`}

    // Toutes les commandes
    const fetcher = async () => {
        const res = await api.get(`/admin/messages/get-by-commande/${id}`, {headers})
        return res?.data?.data
    }

    const {
        data: messages, isPending, isLoading, isError, error
    } = useQuery({
        queryKey: ['get-messages-by-commande'], queryFn: fetcher, refetchInterval: 3000
    })

    return {
        isPending, isLoading, isError, messages, error
    }
}