<template>

  <a-modal ref="modalRef" v-model:open="payementReactive.isOpenModalChooseCommandeForReqPayement" :footer="false"
           :mask="false"
           :wrap-style="{ overflow: 'hidden' }" style="width: 60vw;min-width: 470px">

    <div>
      <commandes-list-for-choose-one fonction="REQ_PAYEMENT"/>
      <form-button class="text-xs btn-sm border-radius-xl font-weight-normal bg-danger m-1" type="button"
                   @click="payementReactive.setModalChooseCommandeForReqPayement(false)">
        Quitter
      </form-button>
    </div>
    <!-- ======================================================-->

    <template #title>
      <div ref="modalTitleRef" class="text-secondary font-weight-normal text-xs" style="width: 100%; cursor: move"><i
          class="fas fa-bars px-1"></i>Déplacer
      </div>
    </template>

    <template #modalRender="{ originVNode }">
      <div :style="transformStyle">
        <component :is="originVNode"/>
      </div>
    </template>

  </a-modal>

</template>


<script setup>
// Ce composant ouvre le modal de choix parmi les commandes dans la liste des Choix unique
import FormButton from "@/components/FormButton.vue";
import {ref} from "vue";
import {useDraggableModal} from "@/hook/draggableModal";
import {payementReactive} from "@/reactiveState/payements";
import CommandesListForChooseOne from "@/views/components/CommandesListForChooseOne.vue";

const modalTitleRef = ref(null);
const {transformStyle} = useDraggableModal(modalTitleRef);


</script>
