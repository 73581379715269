import {defineStore} from "pinia";


const currId = sessionStorage.getItem('cExpId')
const CURRENT_ID = currId ? JSON.parse(currId) : currId

export const expeditionStore = defineStore('expeditionStoreId', {

    state: () => {
        return {
            isLoading: false,
            currentExpId: CURRENT_ID,
        }
    },

    actions: {

        // Chargement
        setIsLoading(load) {
            this.isLoading = load
        },

        // Changer l'id de l'expedition actuelle
        setCurrentExpId(id) {
            this.currentExpId = id
            sessionStorage.setItem('cExpId', JSON.stringify(id))
        },


    }
})
