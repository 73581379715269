<template>
  <div>
    <a-modal ref="modalRef" v-model:open="messageReactive.isOpen" :footer="false" :mask="false"
             :wrap-style="{ overflow: 'hidden' }"
             @ok="handleOk">

      <!--  ============================    Contenu visible du modal ============================ -->
      <div class="d-flex flex-row flex-wrap justify-content-between align-items-center position-relative w-100 my-1">
            <textarea v-model="content"
                      class="focus-ring-light border-radius-xl px-3 py-2 w-100 border-1"
                      placeholder="Envoyez un message"
                      style="min-width:90%;width:90%;min-height: 50px;max-height: 300px;"
                      type="text"
                      @keydown="keydown"/>

        <p class="text-secondary text-sm mt-2">Les messages ne sont modifiables que pendant les 3 minutes qui succèdent leur
          création</p>
        <div class="flex flex-row align-items-end mt-2">
          <form-button class="text-xs bg-danger m-1 font-weight-normal btn-sm border-radius-xl"
                       type="button"
                       @click="messageReactive.closeEditionModal()">
            Fermer
          </form-button>

          <form-button
              class="text-xs m-1 font-weight-normal btn-sm border-radius-xl"
              @click="sendMessageNow"
          >
            <i class="fas fa-arrow-right text-white"></i>
          </form-button>
        </div>
      </div>
      <!--      ========================================================================================-->

      <template #title>
        <div ref="modalTitleRef" class="text-secondary font-weight-normal text-xs" style="width: 100%; cursor: move"><i
            class="fas fa-bars px-1"></i>Déplacer
        </div>
      </template>

      <template #modalRender="{ originVNode }">
        <div :style="transformStyle">
          <component :is="originVNode"/>
        </div>
      </template>

    </a-modal>
  </div>
</template>
<script setup>
import {useDraggableModal} from "@/hook/draggableModal";
import {computed, ref, watch} from 'vue';
import {messageReactive} from "@/reactiveState/message";
import {toast} from "vue3-toastify";
import {Styles} from "@/assets/js/styles";
import {updateMessageContent} from "@/services-apis/messages";
import {useCommande} from "@/services-apis/commandes/hooks.commandes";
import FormButton from "@/components/FormButton.vue";

const confirmLoading = ref(false);
const {refetch} = useCommande()
const messageForUpdate = computed(() => messageReactive.messageEdition)
const content = ref(messageForUpdate.value?.content)
// Ecouter les modifications de l'objet message
watch(messageForUpdate, (newVal) => {
  content.value = newVal?.content;
});

// Valider l'envoie du messsage
const sendMessageNow = () => {

  if (content.value.length < 15) return toast.error('Contenu du message trop court. (Caract. Min 15)', {
    hideProgressBar: true,
    position: 'bottom-right',
    bodyClassName: 'body-toaster',
    toastStyle: Styles.toastStyle
  })

  confirmLoading.value = true;

  updateMessageContent(messageForUpdate.value?.id, {
        newContent: content.value,
      },
      () => {
        refetch();
        messageReactive.closeEditionModal()
      }
  )
}

// // Vider les states à la fermeture tu modal
const keydown = (event) => {
  if (event.ctrlKey && event.key === 'Enter') {
    event.preventDefault(); // Empêche le comportement par défaut si nécessaire

    // Envoyer le message
    sendMessageNow()
  }
}

const modalTitleRef = ref(null);
const {transformStyle} = useDraggableModal(modalTitleRef);
</script>