<script setup>
import {computed, ref} from "vue";
import {useDraggableModal} from "../../hook/draggableModal";
import ExpeditionItem from "../calendar/ExpeditionItem.vue";
import {useExpeditionsEvents} from "../../services-apis/expeditions/hooks.expeditions";
import {addCommandIntoAirCargoExpedition, addCommandIntoShippingExpedition} from "../../services-apis/expeditions";
import {expeditionStore} from "../../store/pinia-stores/expedition-store";
import {useCommande} from "../../services-apis/commandes/hooks.commandes";

const store = expeditionStore()

const props = defineProps(({
  transport: {type: String}
}))

const {events: evenements} = useExpeditionsEvents()
const events = computed(() => evenements.value?.filter((item) => props.transport === 'AVION' ? item?.airCargo : item?.container))

const currentTransporterId = ref(null);
const modalTitleRef = ref(null);
const {open, transformStyle, showModal, handleOk} = useDraggableModal(modalTitleRef);
const {commande, refetch} = useCommande()
// const search = ref('')
// // Les configurations de la pagination
// const currentPage = ref(1);
// const lenPage = computed(() => {
//   const totalItems = events.value?.length || 0;
//
//   if (totalItems <= 7) {
//     // Si on a 7 éléments ou moins, on affiche tout sur une seule page
//     return 1;
//   }
//
//   // Pour les éléments au-delà de 7 : on calcule le nombre de pages restantes après avoir affiché 7 éléments sur la première page
//   const remainingItems = totalItems - 7;
//
//   // Le nombre de pages nécessaires après la première page (avec 10 éléments par page)
//   // On multiplie par 10 pour s'assurer que le composant pagination collecte bien toutes les données afin d'afficher un bon nombe des pages
//   const additionalPages = Math.ceil(remainingItems / 10) * 10;
//
//   // 1 page pour les 5 premiers éléments + pages supplémentaires
//   return additionalPages + 1;
// });

// const paginatedItems = computed(() => {
//   const totalEvents = events.value || [];
//   const activePage = currentPage.value;
//
//   if (activePage === 1) {
//     // Première page : afficher les 5 premiers éléments
//     return totalEvents.slice(0, 7);
//   } else {
//     // Autres pages : afficher 10 éléments par page
//     const start = 7 + (activePage - 2) * 10;
//     const end = start + 10;
//     return totalEvents.slice(start, end);
//   }
// });

// const resultSearch = computed(() => paginatedItems.value?.filter(item => (
//     validator.contains(item?.title, events.value, {ignoreCase: true})
//     ||
//     validator.contains(item?.airCargo?.airlineName, events.value, {ignoreCase: true})
//     ||
//     validator.contains(item?.airCargo?.flightNumber, events.value, {ignoreCase: true})
//     ||
//     validator.contains(item?.airCargo?.airwayBillNumber, events.value, {ignoreCase: true}) ||
//     validator.contains(item?.airCargo?.originAirport, events.value, {ignoreCase: true}) ||
//     validator.contains(item?.airCargo?.destinationAirport, events.value, {ignoreCase: true})
// )))
const refetchData = async () => {
  await refetch()
  handleOk()
}
const handleAddCommand = (params) => {
  currentTransporterId.value = params.item?.id
  const transportWay = commande.value?.transport?.way // AVION / BOAT

  const data = {
    "commandIds": [commande.value?.id]
  };

  // Expedition aérienne
  if (transportWay === 'AVION') {
    const transporter = params.item.airCargo
    addCommandIntoAirCargoExpedition(transporter.id, data, refetchData)
  }
  // Expedition maritime
  if (transportWay === 'BOAT') {
    const transporter = params.item.container
    addCommandIntoShippingExpedition(transporter.id, data, refetchData)
  }
}

</script>

<template>

  <div @click="showModal">
    <slot/>
  </div>

  <a-modal ref="modalRef" v-model:open="open" :footer="false" :mask="false" :wrap-style="{ overflow: 'hidden' }"
           style="width: 60vw;min-width: 470px"
           @ok="handleOk">
    <div class="card mb-4 border-radius-md shadow-none">

      <div class="d-flex justify-content-between">
        <div class="card-header pb-0">
          <h5 class="modal-title">Choisissez un
            {{ transport === 'AVION' ? 'avion' : 'conteneur (bateau)' }}</h5>
        </div>

        <!--        <input
                    v-model="search"
                    class="form-control m-3" placeholder="Tapez un mot clé pour faire la recherche"
                    style="width: 200px"
                    type="text"
                />-->
      </div>

      <div v-if="events?.length === 0" class="d-flex justify-content-center align-items-center flex-column">
        <img alt="empty illustration"
             class="img img-fluid" src="@/assets/img/illustrations/commande-vide.jpg" style="width: 15vw;height: 13vw"/>
        <span class="text-gray my-3 text-sm">Aucun élément d'embarquement disponible</span>
      </div>

      <div v-else class="card-body px-0 pt-0 pb-0">
        <div class="" style="max-height: 63vh;overflow-y: auto;">
          <!--  Les différentes expéditions par item : il est en loading lorsque le focus est sur lui et que l'action est lancée -->
          <expedition-item v-for="event in events" :key="event.id" :event="event"
                           :isLoading="currentTransporterId=== event.id ? store.isLoading:false"
                           @callback="handleAddCommand"/>

        </div>

        <!-- Les pages pour les utilisateurs (clients) lorsqu'ils sont nombreux -->
        <!--        <div class="d-flex justify-content-center border-top my-1 py-2">
                  <a-pagination v-model:current="currentPage" :total="lenPage" size="small"/>
                </div>-->
      </div>

    </div>
    <!-- ======================================================-->

    <template #title>
      <div ref="modalTitleRef" class="text-secondary font-weight-normal text-xs" style="width: 100%; cursor: move"><i
          class="fas fa-bars px-1"></i>Déplacer
      </div>
    </template>

    <template #modalRender="{ originVNode }">
      <div :style="transformStyle">
        <component :is="originVNode"/>
      </div>
    </template>

  </a-modal>

</template>
