<script setup>
import {computed} from "vue";
import {useRoute} from "vue-router";
import {useStore} from "vuex";

import SidenavItem from "./SidenavItem.vue";
import SidenavCard from "./SidenavCard.vue";

const store = useStore();
const isRTL = computed(() => store.state.isRTL);

const getRoute = () => {
  const route = useRoute();
  const routeArr = route.path.split("/");
  return routeArr[1];
};
</script>
<template>
  <div
      id="sidenav-collapse-main"
      class="collapse navbar-collapse w-auto h-auto h-100"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-item
            :class="getRoute() === 'dashboard-default' ? 'active' : ''"
            :navText="isRTL ? 'لوحة القيادة' : 'Tableau de bord'"
            to="/dashboard-default"
        >
          <template v-slot:icon>
            <i class="ni ni-tv-2 text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
            :class="getRoute() === 'commandes' ? 'active' : ''"
            :navText="isRTL ? '-الجداول' : 'Commandes'"
            to="/commandes"
        >
          <template v-slot:icon>
            <i
                class="fas fa-paper-plane text-warning text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
            :class="getRoute() === 'facturations' ? 'active' : ''"
            :navText="isRTL ? 'الفواتیر' : 'Facturations'"
            to="/facturations"
        >
          <template v-slot:icon>
            <i class="ni ni-credit-card text-success text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <!--
            <li class="nav-item">
              <sidenav-item
                to="/virtual-reality"
                :class="getRoute() === 'virtual-reality' ? 'active' : ''"
                :navText="isRTL ? 'الواقع الافتراضي' : 'Virtual Reality'"
              >
                <template v-slot:icon>
                  <i class="ni ni-app text-info text-sm opacity-10"></i>
                </template>
              </sidenav-item>
            </li>

            <li class="nav-item">
              <sidenav-item
                to="/rtl-page"
                :class="getRoute() === 'rtl-page' ? 'active' : ''"
                navText="RTL"
              >
                <template v-slot:icon>
                  <i class="ni ni-world-2 text-danger text-sm opacity-10"></i>
                </template>
              </sidenav-item>
            </li>
      -->


      <!--      ====================== LES PAGES SUR LA BOUTIQUE ======================-->
      <li class="mt-3 nav-item">
        <h6
            v-if="isRTL"
            :class="isRTL ? 'me-4' : 'ms-2'"
            class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
        >
          صفحات المرافق
        </h6>

        <h6
            v-else
            :class="isRTL ? 'me-4' : 'ms-2'"
            class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
        >
          PAGES DE LA BOUTIQUE
        </h6>
      </li>

      <li class="nav-item">
        <sidenav-item
            :class="getRoute() === 'shop' ? 'active' : ''"
            :navText="isRTL ? 'الفواتیر' : 'Boutique'"
            to="/shop"
        >
          <template v-slot:icon>
            <i aria-hidden="true" class="fa fa-shopping-bag text-sm" style="color: #0064ca;"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
            :class="getRoute() === 'paniers-commandes' ? 'active' : ''"
            :navText="isRTL ? 'اشتراك' : 'Paniers commandés'"
            to="/paniers-commandes"
        >
          <template v-slot:icon>
            <i aria-hidden="true" class="fa fa-cart-arrow-down text-success text-sm"></i>
          </template>
        </sidenav-item>
      </li>

      <!--      <li class="nav-item">
              <sidenav-item
                  :class="getRoute() === 'accueil-paiements' ? 'active' : ''"
                  :navText="isRTL ? 'تسجيل الدخول' : 'Paiements'"
                  to="/paiements"
              >
                <template v-slot:icon>
                  <i class="fa fa-money-check text-danger text-sm" aria-hidden="true"></i>
                </template>
              </sidenav-item>
            </li>-->

      <li class="nav-item">
        <sidenav-item
            :class="getRoute() === 'calendar' ? 'active' : ''"
            :navText="isRTL ? 'تسجيل الدخول' : 'Planificateur'"
            to="/calendar"
        >
          <template v-slot:icon>
            <i aria-hidden="true" class="fas fa-calendar text-dark text-sm"></i>
          </template>
        </sidenav-item>
      </li>
      <!--      ====================== ================= =================   ======================-->


      <li class="mt-3 nav-item">
        <h6
            v-if="isRTL"
            :class="isRTL ? 'me-4' : 'ms-2'"
            class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
        >
          صفحات المرافق
        </h6>

        <h6
            v-else
            :class="isRTL ? 'me-4' : 'ms-2'"
            class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
        >
          PAGES DE COMPTE
        </h6>
      </li>

      <li class="nav-item">
        <sidenav-item
            :class="getRoute() === 'profile' ? 'active' : ''"
            :navText="isRTL ? 'حساب تعريفي' : 'Profil'"
            to="/profile"
        >
          <template v-slot:icon>
            <i class="ni ni-single-02 text-dark text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <!--      <li class="nav-item">
              <sidenav-item
                  :class="getRoute() === 'signin' ? 'active' : ''"
                  :navText="isRTL ? 'تسجيل الدخول' : 'Se connecter'"
                  to="/signin"
              >
                <template v-slot:icon>
                  <i class="fa fa-right-to-bracket text-danger text-sm" aria-hidden="true"></i>
                </template>
              </sidenav-item>
            </li>-->

    </ul>
  </div>

  <div class="pt-3 mx-3 mt-3 sidenav-footer">
    <sidenav-card
        :card="{
        title: 'Need Help?',
        description: 'Please check our docs',
        links: [
          {
            label: 'Documentation',
            route:
              'https://www.creative-tim.com/learning-lab/vue/overview/argon-dashboard/',
            color: 'dark',
          },
          {
            label: 'Buy now',
            route:
              'https://www.creative-tim.com/product/vue-argon-dashboard-pro?ref=vadp',
            color: 'success',
          },
        ],
      }"
    />
  </div>
</template>
