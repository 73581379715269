<script setup>
import dayjs from "dayjs";
import {expeditionStore} from "../../store/pinia-stores/expedition-store";
import {computed} from "vue";

const store = expeditionStore()

const emit = defineEmits(['callback'])
const props = defineProps({
  event: {
    type: Object
  }, isLoading: {
    type: Boolean
  }
})

const isCurrent = computed(() => props.event?.id === store.currentExpId)
const isAirExpEvent = computed(() => !!props.event?.airCargo)
const cargo = computed(() => isAirExpEvent.value ? props.event?.airCargo : props.event?.container)

// Exemple : Émettre un événement avec une valeur
const handleClick = () => {
  emit('callback', {item: props.event});
};

</script>
<template>
  <div :class="{'border-1': isCurrent,'item-focused': isCurrent}" class="card my-1 shadow-none cursor-pointer"
       @click="handleClick">
    <div class="card-body d-flex align-items-center py-2">
      <!-- Icon -->
      <div class="me-3 d-flex align-items-center justify-content-center"
           style="width: 50px; height: 50px; background-color: #eaf4fe; border-radius: 50%;">
        <img
            :src="event?.container ? 'https://i.pinimg.com/1200x/4b/e2/72/4be27243e776095b1578e3ecb16e1c05.jpg':event?.airCargo ? 'https://i.pinimg.com/736x/27/b2/ab/27b2ab8792e35613982bed4ca4b6ab3a.jpg':''"
            alt="Zoom icon" class="border-radius-xl"
            style="width: 45px; height: 45px;"/>
      </div>

      <!-- Content -->
      <div class="flex-grow-1">
        <h6 class="mb-1 fw-bold text-sm">{{ event?.title }}</h6>
        <small class="text-muted">D: {{ dayjs(event?.departDate).format('DD MMM. YYYY, HH:mm') }}</small><br>
        <small class="text-muted">A: {{ dayjs(event?.livraisonDate).format('DD MMM. YYYY, HH:mm') }}
          (estimation)</small>
        <br>
        <small class="mx-1 my-1 text-sm text-link-blue">Commandes incluses ({{ cargo?.commandPackedAssociate?.length }} au
        total)</small>
      </div>
      <a-button :loading="isLoading" type="ghost"></a-button>

    </div>

  </div>
</template>

<style scoped>
.item-focused {
  border-color: var(--color-primary);
}

</style>
