import {URL_API} from "@/services-apis/axios-init";
import {io} from "socket.io-client";
import {
    EvACCEPT_REQUEST_PAYEMENT,
    EvADMINJOINROOM,
    EvADMINROOMJOINED,
    EvDECLINE_REQUEST_PAYEMENT
} from "@/socket-client/events";
import {userStore} from "@/store/pinia-stores/user.store";
import {payementReactive} from "@/reactiveState/payements";
import {payementStore} from "@/store/pinia-stores/payements-store";
import {EvSEND_REQUEST_PAYEMENT_TO_ADMIN} from "./events";

const socket = io(URL_API);

socket.on("connect", () => {
    const store = userStore()
    let user = store.user
    const room = `admin-room--${user?.uuid}--${user?.id}`;

    socket.emit(EvADMINJOINROOM, room);
    // console.log('Connect to server socket')
});

socket.on(EvADMINROOMJOINED, () => {
    console.log('Admin User connected to room server socket')
});

// Lorsqu'une requête de paiement est acceptée
socket.on(EvACCEPT_REQUEST_PAYEMENT, (data) => {
    const store = payementStore()
    store.setDataRequestAccepted(data?.data)

    // On ouvre le modal pour valider / enregistrer le paiement
    payementReactive.setIsOpenModalSavePayementOfReqAccepted(true)
    payementReactive.setIsWaitingAnswer(false)
    payementReactive.setModalSuccessReqPayement(false)
});

// Lorsqu'une requête de paiement est annulée / refusée
socket.on(EvDECLINE_REQUEST_PAYEMENT, (data) => {
    const store = payementStore()
    store.setDataRequestAccepted(data?.data)

    // On ouvre le modal pour valider / enregistrer le paiement
    payementReactive.setIsOpenModalCancelPayementReq(true)
    payementReactive.setIsWaitingAnswer(false)
    payementReactive.setModalSuccessReqPayement(false)
});

// Lorsqu'une requête de paiement est expédiée par un client (pour l'admin en cours de session
socket.on(EvSEND_REQUEST_PAYEMENT_TO_ADMIN, (data) => {
    const store = payementStore()
    store.setDataRequestFromClient(data)
    // On ouvre le modal pour valider / enregistrer le paiement
    payementReactive.setIsOpenModalReceiverPayementRequestOfClients(true)
});

socket.on("disconnect", () => {
    console.log('Leave server socket')
});

socket.on("connect_error", (err) => {
    console.error("Socket connection error: ", err);
});

export default socket
