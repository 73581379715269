// Afficher le type d'un payement
export function useTypeText() {
    // Fonction pour déterminer le type de texte à partir du paiement
    const getTypeText = (pymnt) => {
        return pymnt?.type === "paye_purcharge_fee" ? 'Frais service'
            : pymnt?.type === 'paye_commande' ? 'Paiement commande'
                : pymnt?.type === 'paye_commande_fe' ? 'Paiement frais d\'expédition'
                    : pymnt?.type === 'acompte_commande' ? 'Paiement acompte'
                        : pymnt?.type === 'buy_article' ? 'Achat article' : pymnt?.type;
    };

    // Fonction pour déterminer le type de texte à partir du paiement juste en passant le type directement
    const getTypeOnlyText = (type) => {
        return type === "paye_purcharge_fee" ? 'Frais service'
            : type === 'paye_commande_fe' ? 'Paiement frais d\'expédition'
                : type === 'paye_commande' ? 'Paiement commande'
                    : type === 'acompte_commande' ? 'Paiement acompte'
                        : type === 'buy_article' ? 'Achat article' : type;
    };

    return {
        getTypeText, getTypeOnlyText
    };
}

// Afficher le titre d'une solution de paiement donné
export function useSolutionPayementText() {

    // Fonction pour déterminer le type de texte à partir du paiement juste en passant le type directement
    const getTextSolution = (type) => {
        return type === "bank_virement" ? 'Virement bancaire'
            : type === 'mobile_money' ? "Paiement mobile" : type;
    };

    return {
        getTextSolution
    };
}

// Afficher le texte d'un moyen d'expédition
export function useExpeditionText() {

    // Fonction pour déterminer le type de texte à partir du paiement juste en passant le type directement
    const getTextExpedition = (transport) => {
        return !transport?.way ? 'Non défini' : transport?.way === 'BOAT' ? 'Maritime' : transport?.way === 'AVION' ? 'Aérien' : '';
    };

    return {
        getTextExpedition
    };
}
