import {reactive} from "vue";

export const globalReactive = reactive({
   // Ouverture de la vue création d'une expédition maritime
    isOpenNewShippingExp: false,
    setIsOpenNewShippingExp: (value) => {
        globalReactive.isOpenNewShippingExp = value
    },

    // Ouverture de la vue création d'une expédition aérienne
    isOpenNewAirExp: false,
    setIsOpenNewAirExp: (value) => {
        globalReactive.isOpenNewAirExp = value
    },
})
