<script setup>
// Ce composant affiche les commandes pour un choix unique d'une parmi elles
// A des fins de traitement comme paiement de commande par interaction
import {useCommande, useCommandes} from "@/services-apis/commandes/hooks.commandes";
import {computed, ref} from "vue";
import validator from "validator";
import {commandeStore} from "@/store/pinia-stores/commande-store";
import {payementReactive} from "@/reactiveState/payements";

const props = defineProps({
  fonction: {
    type: String,
    default: 'REQ_PAYEMENT'
  }
})
const {commandes} = useCommandes()
const {refetch} = useCommande()

const search = ref('')
// Les configurations de la pagination
const currentPage = ref(1);
const lenPage = computed(() => {
  const totalItems = commandes.value?.length || 0;

  if (totalItems <= 7) {
    // Si on a 7 éléments ou moins, on affiche tout sur une seule page
    return 1;
  }

  // Pour les éléments au-delà de 7 : on calcule le nombre de pages restantes après avoir affiché 7 éléments sur la première page
  const remainingItems = totalItems - 7;

  // Le nombre de pages nécessaires après la première page (avec 10 éléments par page)
  // On multiplie par 10 pour s'assurer que le composant pagination collecte bien toutes les données afin d'afficher un bon nombe des pages
  const additionalPages = Math.ceil(remainingItems / 10) * 10;

  // 1 page pour les 5 premiers éléments + pages supplémentaires
  return additionalPages + 1;
});
const paginatedCommandes = computed(() => {
  const totalCommandes = commandes.value || [];
  const activePage = currentPage.value;

  if (activePage === 1) {
    // Première page : afficher les 5 premiers éléments
    return totalCommandes.slice(0, 7);
  } else {
    // Autres pages : afficher 10 éléments par page
    const start = 7 + (activePage - 2) * 10;
    const end = start + 10;
    return totalCommandes.slice(start, end);
  }
});

const store = commandeStore();
/** Passez l'id de la commande choisie*/
const ifChoosed = (id) => {
  store.setCurrentId(id)
  // Ici, on va lancer le modal pour la requête de paiement
  if (props.fonction === 'REQ_PAYEMENT') {
    payementReactive.setModalCreateReqPayement(true)
    refetch()

  }
}


// Aller à une commande
const goToOne = (id) => {
  store.setCurrentId(id)
  // router.push({name: 'Details-Commande'})
}

const resultSearch = computed(() => paginatedCommandes.value?.filter(item => (
    validator.contains(item?.title, search.value, {ignoreCase: true})
    ||
    validator.contains(item?.creator?.firstname, search.value, {ignoreCase: true})
    ||
    validator.contains(item?.creator?.lastname, search.value, {ignoreCase: true})
    ||
    validator.contains(item?.creator?.email, search.value, {ignoreCase: true})
)))

</script>

<template>
  <div class="card mb-4 border-radius-md shadow-none">

    <div class="d-flex justify-content-between">
      <div class="card-header pb-0">
        <h5 id="exampleModalLabel" class="modal-title">Choisissez une commande</h5>
      </div>

      <input
          v-model="search"
          class="form-control m-3" placeholder="Tapez un mot clé pour faire la recherche"
          style="width: 200px"
          type="text"
      />
    </div>

    <div v-if="commandes?.length === 0" class="d-flex justify-content-center align-items-center flex-column">
      <img alt="empty illustration"
           class="img img-fluid" src="@/assets/img/illustrations/commande-vide.jpg" style="width: 35vw;height: 30vw"/>
      <span class="text-gray my-3 text-sm">Aucune commande actuellement</span>
    </div>

    <div v-else class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0" style="min-height: 50vh;">
        <table class="table align-items-center justify-content-center mb-0">
          <thead>
          <tr>
            <th
                class="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Titre
            </th>

            <th

                class="text-start text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
            >
              client <span class="text-capitalize">(Auteur)</span>
            </th>

            <th

                class="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Budget
            </th>
            <th

                class="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Articles
            </th>

            <th></th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="commande in resultSearch" :key="commande?.id">
            <td>
              <div class="d-flex">
                <div>
                  <img
                      alt="icône commande"
                      class="avatar avatar-sm rounded-circle me-2"
                      src="../../assets/img/illustrations/cart.jpg"
                  />
                </div>
                <div class="my-auto cursor-pointer" @click="goToOne(commande?.id)">
                  <h6 class="mb-0 text-sm text-truncate text-bold" style="width: 250px">{{ commande?.title }}</h6>
                  <span :class="commande?.isCancel ? 'text-danger':'text-success'"
                        class="mb-0 text-xs text-success font-weight-normal">{{ commande?.status }}</span>

                </div>
              </div>
            </td>

            <td>
              <p class="text-xs mb-0">{{ commande?.creator?.firstname || commande?.creator?.email }}
                {{ commande?.creator?.lastname }}</p>
            </td>

            <td>
              <p class="text-xs text-truncate font-weight-bold mb-0" style="width: 120px">
                {{
                  `${commande?.totalAPayer ? '$ ' + Number(commande?.totalAPayer).toLocaleString('fr-FR') : 'Non défini'}`
                }}
              </p>
            </td>

            <td>
              <h5 class="text-xs font-weight-bold">
                {{ `${commande?.articles?.length} article${commande?.articles?.length > 1 ? 's' : ''}` }}</h5>
            </td>

            <td>
              <a-button class="text-xs btn-sm border-radius-xl text-white font-weight-normal bg-link-blue m-1"
                        type="link" @click="ifChoosed(commande?.id)">
                Choisir
              </a-button>
            </td>
          </tr>

          </tbody>
        </table>
      </div>


      <!-- Les pages pour les utilisateurs (clients) lorsqu'ils sont nombreux -->
      <div class="d-flex justify-content-center border-top my-1 py-2">
        <a-pagination v-model:current="currentPage" :total="lenPage" size="small"/>
      </div>
    </div>
  </div>

</template>
