<script setup>
import {computed} from "vue";

const props = defineProps(['params']) // On passe les paramètres Aggrid
const panier = computed(() => props.params.data)
const isPayed = computed(() => panier.value.isPayed)
const isCompletePayed = computed(() => panier.value.isCompletePayed)
</script>

<template>
  <div>
    <span
        :class="{'bg-link-blue text-link-blue': isCompletePayed, 'bg-success text-success': isPayed, 'text-danger bg-danger': !isCompletePayed && !isPayed}"
        class="text-danger text-xs text-white py-1 px-2">
      <i class="fas fa-circle p-1" style="font-size: 8px"></i>
      {{
        isCompletePayed ? "Payé totalement" : isPayed ? "Payé en tranche" : "Non payé"
      }}</span>
  </div>
</template>

<style scoped>

</style>
