<template>

  <span @click="showModal">
    <i class="fas fa-check"></i>
    Accepter
  </span>

  <a-modal ref="modalRef" v-model:open="open" :footer="false" :mask="false" :wrap-style="{ overflow: 'hidden' }"
           @ok="handleOk">

    <!--   ============== Le contenu du modal =============== -->
    <div>

      <div class="">

        <div class="modal-header">
          <div class="col">
            <h5 class="modal-title">Fixer les frais de services</h5>
            <span class="text-sm text-bold text-dark">{{ commande?.title }}</span>
          </div>
        </div>

        <div class="modal-body">

          <p class="text-dark font-weight-normal text-sm">
            En lançant la demarche, vous signalez à l'
            auteur que cette commande répond à nos critères et que ses
            articles demandés ont bien été enregistrés !
          </p>

          <strong class="text-bold text-sm text-dark">Frais de service</strong>
          <form-input v-model="price" class="w-100" placeholder="Prix total des frais de service (min: 10$)"
                      type="number"/>

          <p class="text-dark text-sm">
            Notez que la devise utilisée est le <span class="text-bold">
             Dollar Americain ($)
          </span>
          </p>

          <p class="text-dark text-bold text-sm">
            Ce que l'auteur<span class="text-gradient text-dark font-weight-normal">(client)</span> verra:</p>
          <ol>
            <li class="text-dark text-sm mb-1">En attente de paiement des frais de service</li>
            <li class="text-dark text-sm mb-1">Commande acceptée</li>
            <li class="text-dark text-sm mb-1">Commande disponible</li>
          </ol>

          <div class="modal-footer">
            <div class="flex align-items-end">
              <form-button class="text-xs btn-sm border-radius-xl font-weight-normal bg-danger m-1" type="button"
                           @click="handleOk">
                Annuler
              </form-button>
              <form-button class="text-xs btn-sm border-radius-xl font-weight-normal m-1" type="button"
                           @click="handleSetApprobation">
                {{ store.isLoading ? 'Patientez...' : 'Lancer maintenant' }}
              </form-button>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- ======================================================-->

    <template #title>
      <div ref="modalTitleRef" class="text-secondary font-weight-normal text-xs" style="width: 100%; cursor: move"><i
          class="fas fa-bars px-1"></i>Déplacer
      </div>
    </template>

    <template #modalRender="{ originVNode }">
      <div :style="transformStyle">
        <component :is="originVNode"/>
      </div>
    </template>

  </a-modal>

</template>


<script setup>

import FormButton from "@/components/FormButton.vue";
import FormInput from "@/components/FormInput.vue";
import {setApprobationCommande} from "@/services-apis/commandes";
import {toast} from "vue3-toastify";
import {Styles} from "@/assets/js/styles";
import {ref} from "vue";
import {commandeStore} from "@/store/pinia-stores/commande-store";
import {useCommande} from "@/services-apis/commandes/hooks.commandes";
import {userStore} from "@/store/pinia-stores/user.store";
import {useDraggableModal} from "@/hook/draggableModal";

const {refetch, commande} = useCommande()
const modalTitleRef = ref(null);
const {open, transformStyle, showModal, handleOk} = useDraggableModal(modalTitleRef);

const store = commandeStore()
const ustore = userStore()
const price = ref("")

/*Lancer la recherche*/
const handleSetApprobation = () => {
  if (Number(price.value) < 10) {
    // Message du succès d'enregistrement user / admin
    return toast.error('Donnez un price valide', {
      position: 'bottom-center',
      hideProgressBar: true,
      bodyClassName: 'body-toaster',
      toastStyle: Styles.toastStyle,
      autoClose: 2000,
      // onClose: () => router.push('/')
    })
  }

  setApprobationCommande({
    approbation: true,
    price: price.value,
    adminId: ustore.user?.id
  }, refetch, '', () => {
    handleOk()
  })
}


</script>
