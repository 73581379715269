<script setup>
import {computed, ref, watch} from "vue";
import dayjs from "dayjs";
import {toast} from "vue3-toastify";
import {Styles} from "../../../assets/js/styles";
import {useCommande, useCommandLivraisons} from "../../../services-apis/commandes/hooks.commandes";
import {addStepDateLivraison} from "../../../services-apis/commandes";
import {commandeStore} from "../../../store/pinia-stores/commande-store";

const cStore = commandeStore()
const currentId = computed(() => cStore.currentId)
const isLoading = computed(() => cStore.isLoading)
const isOpen = ref(false)
const labelStep = ref("")

const {commande} = useCommande()
const commandeCourante = computed(() => commande.value)

const {livraisons: dates, refetch} = useCommandLivraisons()

// Lancer l'enregistrement de l'étape
const handleAdd = (livraisonId) => {
  if (!labelStep.value) {

    // Message du succès d'enregistrement user / admin
    toast.success("Impossible d'ajouter avec une description vide", {
      position: 'bottom-center',
      hideProgressBar: true,
      bodyClassName: 'body-toaster',
      toastStyle: Styles.toastStyle,
      autoClose: 3000,
    })
    return
  }

  addStepDateLivraison(currentId.value, {
    "label": labelStep.value,
    "livraisonEventId": livraisonId,
    "stepColorIndicator": "#0f0",
    "stepColorText": "#0ff",
    "completed": false
  }, async () => {
    labelStep.value = ""
    await refetch()
  }, "")
}

// Ouverture de la side
const openSide = () => {
  isOpen.value = !isOpen.value
}

// Watch pour surveiller les changements dynamiques des dates sur la livraison du panier courant
watch(
    commandeCourante,
    () => {
      refetch()
    },
    {immediate: true} // Met à jour dès le premier rendu
);
</script>

<template>

  <div @click="openSide">
    <slot/>
  </div>

  <a-drawer v-model:open="isOpen" :mask="true" placement="right" width="550">

    <template #title>
      <div class="card-header pb-0 px-3">
        <h6 class="mb-0 modal-title">Événements de livraison commande</h6>
      </div>
    </template>

    <!--   ============== Le contenu du modal =============== -->
    <div>

      <div class="card-body py-1">

        <div class="list-group">
          <div
              class="list-group-item border-0 d-flex flex-column px-4 py-2 mb-2 bg-gray-100 border-radius-lg"
          >
            <div class="font-weight-bold my-2 text-lg text-dark">Événements & progression des livraisons</div>
            <div class="d-flex flex-column">
            <span class="mb-2 text-xs">
             Ces informations seront communiquées au client
            </span>
            </div>
          </div>

          <div
              v-for="eventDate in dates" :key="eventDate.id"
              class="list-group-item border-0 d-flex flex-column px-4 py-2 mb-2 border-radius-lg"
          >

            <div class="mb-2 text-sm text-dark">
              Livraison planifiée pour le:
              <span class="text-link-blue font-weight-bold text-sm">
                {{ dayjs(eventDate.livraisonDate).format('DD MMM YYYY') }}
              </span>
            </div>

            <!-- Le collapse-->
            <a-collapse ghost>
              <a-collapse-panel key="1" :bordered="false" :show-arrow="false">
                <template #header>
                  <div class="d-flex flex-row py-1 justify-content-between align-items-center">
                    <strong class="text-link-blue text-decoration-underline font-weight-bold text-sm">
                      Ajouter une étape
                    </strong>
                    <i class="fas fa-plus text-xs"></i>
                  </div>
                </template>

                <!--  Le contenu du panel-->
                <a-textarea
                    v-model:value="labelStep"
                    :bordered="false"
                    placeholder="Ajouter une étape... Cette étape fait allusion aux différentes évolutions avant échéance de la date de livraison. il peut s'agir (d'une commande embarquée), (arrivée dans la ville de destination)"
                    style="width:100%;min-width:100%;max-height: 140px;min-height: 100px;"
                />
                <a-button
                    :loading="isLoading"
                    class="text-xs mx-2 mt-2 text-white bg-link-blue mt-0 border-0 d-flex flex-row align-items-center"
                    type="default"
                    @click="handleAdd(eventDate?.id)">
                  <i class="fas fa-plus px-1"></i>
                  Ajouter
                </a-button>
              </a-collapse-panel>
            </a-collapse>

            <hr class="horizontal dark"/>

            <div class="py-2">
              <strong class="text-link-blue p-3 text-decoration-underline font-weight-bold text-sm">
                Schéma de progression actuel
              </strong>
              <!--  Les étapes fournies par l'admin pour l'expédition-->
              <div class="py-2">
                <a-timeline mode="alternate">
                  <a-timeline-item v-for="step in eventDate.steps" :key="step.id">
                    {{ step.stepLabel || 'Texte non chargé' }}
                  </a-timeline-item>
                </a-timeline>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- ======================================================-->
  </a-drawer>

</template>

<style scoped>
.status-render {
  max-width: 165px;
}

</style>
