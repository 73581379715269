import {defineStore} from "pinia";

const curr = sessionStorage.getItem('cp')
const CURRENT = curr ? JSON.parse(curr) : curr

const currID = sessionStorage.getItem('cpId')
const CURRENT_ID = currID ? JSON.parse(currID) : currID

const currJustID = sessionStorage.getItem('cjID')
const CURRENT_JUST_ID = currJustID ? JSON.parse(currJustID) : currJustID

// L'id du de la justification de paiement d'un panier donné
const currJustCartID = sessionStorage.getItem('cjcID')
const CURRENT_JUST_CART_ID = currJustCartID ? JSON.parse(currJustCartID) : currJustCartID

export const payementStore = defineStore('payementStoreId', {

    state: () => {
        return {
            textSearch: "",
            page: 0,
            items: 5,
            current: CURRENT,
            currentId: CURRENT_ID,

            // La justification de paiement actuelle
            currentJustificationId: CURRENT_JUST_ID,

            // La justification de paiement d'un panier
            currentJustificationCartId: CURRENT_JUST_CART_ID,

            /** Les données d'une requête de paiement acceptée */
            dataRequestAccepted: null,

            /** Les données d'une requête de paiement provenant d'un client */
            dataRequestFromClient: null,

            isLoading: false,
        }
    },

    actions: {

        // Changer le paiement courant
        setCurrent(payement) {
            this.current = payement
            sessionStorage.setItem('cp', JSON.stringify(payement))
        },

        /** Changer / Charger les données d'une requête de paiement acceptée */
        setDataRequestAccepted(data) {
            this.dataRequestAccepted = data
        },

        /** Changer / Charger les données d'une requête de paiement provenant d'un client */
        setDataRequestFromClient(data) {
            this.dataRequestFromClient = data
        },

        // Garder en session l'id de la justification de paiement courante
        setCurrentJustificationId(justificationId) {
            this.currentJustificationId = justificationId
            sessionStorage.setItem('cjID', JSON.stringify(justificationId))
        },

        // Garder en session l'id de la justification courante d'un paiement de panier
        setCurrentJustificationCartId(justificationId) {
            this.currentJustificationCartId = justificationId
            sessionStorage.setItem('cjcID', JSON.stringify(justificationId))
        },

        // Changer l'id sur le paiement courant
        setCurrentId(id) {
            this.currentId = id
            sessionStorage.setItem('cpId', JSON.stringify(id))
        },

        // Chargement
        setIsLoading(loading) {
            this.isLoading = loading
        },

        // Changer les valeurs de la pagination
        setPagination(page, items) {
            this.page = page
            this.items = items
        },

    }
})

export const PayementTypeArray = [
    { key: 'Frais de service', value: 'paye_purcharge_fee' },
    { key: 'Paiement commande (solder)', value: 'paye_commande' },
];
