<template>
  <div>
    <div @click="showModal">
      <slot></slot>
    </div>

    <a-drawer ref="modalRef" v-model:open="open" :mask="false" body-style="min-width: 500px;width: 35vw;"
              placement="right" width="auto"
              @ok="handleOk">

      <!--   ===========   Le contenu du modal ============ -->
      <div>
        <div class="">

          <div v-if="messages?.length === 0" class="p-1 mb-4 col-lg-12 d-flex flex-column align-items-center">
            <img
                alt="Illustration messages"
                class="img-thumbnail img-fluid border-0"
                src="https://img.freepik.com/vecteurs-libre/illustration-concept-conversation_114360-1305.jpg?t=st=1724836426~exp=1724840026~hmac=eafc545eba9c812187c497bdcc77a66c283deab425f99c0cb777236925225db4&w=740"
                style="max-height: 50%;max-width: 50%;">

            <span class="text-secondary text-xs my-3">Démarrez la conversation maintenant</span>
          </div>

          <ul class="list-group" style="max-height: 50vh;overflow-y: scroll;scrollbar-width: none">

            <!--           Composant retournant un <li></li>  -->
            <ItemMessage v-for="message in messages" :key="message?.id" :message="message"/>

          </ul>

          <div class="d-flex flex-row flex-wrap justify-content-between align-items-center position-relative w-100">
            <textarea v-model="message"
                      class="focus-ring-light border-radius-xl px-3 py-2 w-100 border-1"
                      placeholder="Envoyez un message"
                      style="min-width:90%;width:90%;min-height: 50px;max-height: 120px;"
                      type="text"
                      @keydown="keydown"/>
          </div>

          <div class="sticky-bottom bottom-0">
            <!--            ======== Les tags pour ajouter les informations en relation avec le message ========  -->
            <collapse style="border: none;background-color: transparent">
              <collapse-panel key="1" header="Taguez les articles" style="border: none;">
                <div class="d-flex flex-row flex-wrap">

                  <!--                  ===================== Un élément (article)  ======================= -->
                  <div v-for="(article, index) in articles" :key="article?.id"
                       class="btn d-flex flex-row align-items-center justify-content-between border-radius-xl bg-white mx-1"
                       @click="selectArticles(article)">
                    <span class="text-dark text-xs font-weight-normal">{{ article?.name }}</span>
                    <divider v-if="isSelected(index)" style="height: 100%" type="vertical"/>
                    <div v-if="isSelected(index)"
                         class="mt-1 bg-gradient-success p-1 border-radius-xl d-flex flex-row align-items-center justify-content-center"
                         style="width: 14px;height: 14px;"><i class="fas fa-check text-white"></i></div>
                  </div>
                  <!--                    ==================================================================== -->
                </div>

                <span class="text-xs">Les articles tagués sont associés au message que vous envoyez au client. Le client sera en mesure de reconnaître plus facilement de quoi s'agit-t-il.</span>
              </collapse-panel>
            </collapse>
          </div>
        </div>

        <div class="modal-footer">
          <div class="flex flex-row">
            <form-button
                class="text-xs bg-danger m-1 font-weight-normal btn-sm border-radius-xl"
                type="button"
                @click="handleOk">
              Fermer
            </form-button>

            <!--             Faire suivant pour afin afficher la vue où saisir le price -->
            <form-button
                aria-expanded="false" class="text-xs m-1 font-weight-normal btn-sm border-radius-xl"
                data-bs-toggle="collapse"
                @click="sendMessageNow"
            >
              <div v-if="store.isLoading">Go</div>
              <i class="fas fa-arrow-right text-white"></i>
            </form-button>
          </div>

        </div>

      </div>

      <!--  modifier un message déjà envoyé -->
      <UpdateMessage/>

      <!--      ====================================================-->

      <template #title>
        <div class="col">
          <h5 id="removeRevocationModalLabel" class="modal-title text-dark">Echanges sur la commande</h5>
          <span class="text-xs text-bold text-dark">{{ commande?.title }}</span>
        </div>
      </template>
      <template #modalRender="{ originVNode }">
        <div :style="transformStyle">
          <component :is="originVNode"/>
        </div>
      </template>
    </a-drawer>
  </div>
</template>
<script setup>
import {useDraggableModal} from "@/hook/draggableModal";
import UpdateMessage from "@/views/modals/UpdateMessage.vue";
import ItemMessage from "@/views/components/ItemMessage.vue";
import FormButton from "@/components/FormButton.vue";
import {computed, ref} from "vue";
import {commandeStore} from "@/store/pinia-stores/commande-store";
import {useCommande} from "@/services-apis/commandes/hooks.commandes";
import {Collapse, CollapsePanel, Divider} from "ant-design-vue";
import {sendMessage} from "@/services-apis/messages";
import {toast} from "vue3-toastify";
import {Styles} from "@/assets/js/styles";
import {useMessageByCommande} from "@/services-apis/messages/hooks.messages";

const props = defineProps({
  defaultContent: {
    type: String,
    default: ''
  }
})

const modalTitleRef = ref(null);
const {open, transformStyle, showModal, handleOk} = useDraggableModal(modalTitleRef);

const {commande, refetch} = useCommande()
const {messages} = useMessageByCommande(commande.value?.id)
const store = commandeStore()
const message = ref(props.defaultContent)
const articles = computed(() => commande.value?.articles)
const selectedIndexList = ref([])

// Ajouter un message
const eachArticle = () => {
  let final = []
  // Boucle pour afficher les articles avec leur état (checked ou non)
  articles.value.forEach((article, index) => {
    // On recupère juste l'id de l'objet depuis les resultats de l'api puisşon l'ajoute à la liste des articles
    isSelected(index) ? final.push(article.id) : '';
  });

  return final

}

// Valider l'envoie du messsage
const sendMessageNow = () => {
  const articleIds = eachArticle() || [] // est un tableau [number, number,...]
  if (message.value.length < 15) return toast.error('Contenu du message trop court. (Caract. Min 15)', {
    hideProgressBar: true,
    position: 'bottom-right',
    bodyClassName: 'body-toaster',
    toastStyle: Styles.toastStyle
  })

  sendMessage({
        content: message.value,
        commandId: commande.value?.id,
        articleIds
      },
      () => {
        refetch()
        resetStates()
      }
  )
}

//
// // Vider les states à la fermeture tu modal
const resetStates = () => {
  selectedIndexList.value = []
  message.value = ""
}


// // Vider les states à la fermeture tu modal
const keydown = (event) => {
  if (event.ctrlKey && event.key === 'Enter') {
    event.preventDefault(); // Empêche le comportement par défaut si nécessaire

    // Envoyer le message
    sendMessageNow()
  }
}


// Fonction pour vérifier si un article est sélectionné
const isSelected = (index) => selectedIndexList.value.includes(index);

// Sélectionner les articles
const selectArticles = (item) => {
  // Index de l'article dans la liste de base (tous les articles)
  const index = articles.value?.indexOf(item);

  // On vérifie si l'index est déjà dans la liste des index sélectionnés
  const indexInSelected = selectedIndexList.value.includes(index);

  if (indexInSelected) {
    // Si l'index est déjà sélectionné, on le retire
    selectedIndexList.value = selectedIndexList.value.filter(i => i !== index);
    console.log("Index supprimé : ", index);
  } else {
    // Sinon, on l'ajoute
    selectedIndexList.value = [...selectedIndexList.value, index];
    console.log("Index ajouté : ", index);
  }
}

</script>
