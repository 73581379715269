// Changer le price de l'article
import {userStore} from "@/store/pinia-stores/user.store";
import {productStore} from "../../store/pinia-stores/products-store";
import api from "../axios-init";
import {toast} from "vue3-toastify";
import {Styles} from "../../assets/js/styles";

export const createProductCategory = (data, callback, errorSave) => {
    let token = userStore()?.tokens?.accessToken
    let headers = {Authorization: `Bearer ${token}`}

    const store = productStore()
    store.setIsLoading(true)

    api.post(`/admin/products/create-category`, data, {headers}).then(res => {
        callback && callback()
        const data = res.data.data
        console.log(data)

        // Arrêter le chargement
        store.setIsLoading(false)

    }).catch(err => {
        // Arrêter le chargement
        store.setIsLoading(false)
        let msg = err?.response?.data.message || ''
        errorSave && errorSave(msg)
        console.log({err})
    })
}


export const createProduct = (data, callback, errorSave) => {
    let token = userStore()?.tokens?.accessToken
    let headers = {Authorization: `Bearer ${token}`}

    const store = productStore()
    store.setIsLoading(true)

    api.post(`/admin/products/create`, data, {headers}).then(res => {
        callback && callback()
        const data = res.data.data

        // Choisir le e produit actuel comme étant celui qui a été créer : Pour traiter des images
        store.setCurrent(data)

        // Arrêter le chargement
        store.setIsLoading(false)

    }).catch(err => {
        // Arrêter le chargement
        store.setIsLoading(false)
        let msg = err?.response?.data.message || ''
        errorSave && errorSave(msg)
        console.log({err})
    })
}

export const updateProduct = (productId, data, callback, errorSave) => {
    let token = userStore()?.tokens?.accessToken
    let headers = {Authorization: `Bearer ${token}`}

    const store = productStore()
    store.setIsLoading(true)

    api.patch(`/admin/products/${productId}/update`, data, {headers}).then(res => {
        callback && callback()
        console.log(res)

        // Arrêter le chargement
        store.setIsLoading(false)

    }).catch(err => {
        // Arrêter le chargement
        store.setIsLoading(false)

        let msg = err?.response?.data.message || ''
        errorSave && errorSave(msg)

        console.log({err})

    })
}

// Ajouter une image à un produit
export const setImage = (productId, data, success, errorSauvegarde) => {
    let token = userStore()?.tokens?.accessToken
    let headers = {Authorization: `Bearer ${token}`}

    const store = productStore()
    store.setIsLoading(true)

    // noinspection Annotator
    api.post(`/product/${productId}/set-image`, data, {headers}).then(res => {
        console.log(res.data.data)
        success()
        store.setIsLoading(false)

    }).catch(err => {
        let msg = err.response?.data?.message
        errorSauvegarde && errorSauvegarde(msg)
        console.log(err.response.data)
        store.setIsLoading(false)

    })
}

/*
* Supprimer un produit de la boutique
* */
export const deleteProduct = (productId, refetch) => {
    let token = userStore()?.tokens?.accessToken
    let headers = {Authorization: `Bearer ${token}`}

    const store = productStore()
    store.setIsLoading(true)

    api.delete(`/admin/products/${productId}/delete`, {headers}).then(res => {
        const msg = res.data.message

        // On recharge les données
        refetch()

        // Arrêter le chargement
        store.setIsLoading(false)

        // Message du succès d'enregistrement user / admin
        toast.success(msg || "Produit supprimé", {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle,
            autoClose: 3000,
        })

    }).catch(err => {
        // Arrêter le chargement
        store.setIsLoading(false)

        let msg = err.response.data.message

        toast.error(msg || "impossible de supprimer maintenant", {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle
        })

        console.log({err})

    })
}
