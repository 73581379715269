<script setup>
</script>

<template>
  <div>
    <a-page-header sub-title="Magasin de la Centrale" title="Paniers commandés" @back="() => $router.go(-1)">
    </a-page-header>
  </div>
</template>

