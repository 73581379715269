<template>

  <div @click="showModal">
    <slot/>
  </div>

  <a-modal ref="modalRef" v-model:open="open" :footer="false" :mask="false" :wrap-style="{ overflow: 'hidden' }"
           @ok="handleOk">

    <!--   ============== Le contenu du modal =============== -->
    <div>
      <div class="">
        <div class="modal-header">
          <h5 id="exampleModalLabel" class="modal-title">Ajouter une catégorie</h5>
        </div>

        <div class="modal-body">

          <!--  Expédition aérienne -->
          <div class="d-flex flex-column p-2 my-1">
            <h5 class="text-sm">Titre</h5>
            <input v-model="name" class="form-control border-0 bg-gray-100" placeholder="Tapez le titre"/>
          </div>

          <!--  Expédition maritime -->
          <div class="d-flex flex-column p-2 my-1">
            <h5 class="text-sm">Description</h5>
            <textarea v-model="description" class="form-control border-0 bg-gray-100" cols="10"
                      placeholder="Tapez la description (facultative)" rows="5"
                      style="resize: none"/>
          </div>


          <div class="modal-footer">
            <a-button
                class="text-xs text-white mb-2 bg-danger border-0"
                type="default" @click="handleOk">
              Quitter
            </a-button>

            <a-button
                :loading="store.isLoading"
                class="text-xs text-white bg-link-blue mb-2 border-0"
                type="default" @click="handleCreate">
              Enregistrer maintenant
            </a-button>
          </div>
        </div>

      </div>
    </div>
    <!-- ======================================================-->

    <template #title>
      <div ref="modalTitleRef" class="text-secondary font-weight-normal text-xs" style="width: 100%; cursor: move"><i
          class="fas fa-bars px-1"></i>Déplacer
      </div>
    </template>

    <template #modalRender="{ originVNode }">
      <div :style="transformStyle">
        <component :is="originVNode"/>
      </div>
    </template>

  </a-modal>

</template>

<script setup>
import {ref} from "vue";
import {useDraggableModal} from "@/hook/draggableModal";
import {createProductCategory} from "../../services-apis/products/products-request";
import {useProductCategories} from "../../services-apis/products/hooks.products";
import {productStore} from "../../store/pinia-stores/products-store";

const description = ref("");
const name = ref("");

const store = productStore()
const modalTitleRef = ref(null);
const {open, transformStyle, showModal, handleOk} = useDraggableModal(modalTitleRef);
const {refetch} = useProductCategories()

// Succès de création
const handleSuccess = async () => {
  await refetch()
  handleOk()
}

// Erreur de création
const handleError = () => {
}

// Créer la categorie
const handleCreate = () => {
  createProductCategory({name: name.value, description: description.value}, handleSuccess, handleError)
}
</script>

<style scoped>
.icone {
  height: 80px;
  width: 80px;
}
</style>
