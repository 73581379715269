<template>
  <div></div>
</template>

<script setup>

import {onMounted, onUnmounted} from "vue";
import socket from "@/socket-client/socket";

// Quand on veut monter le composant
onMounted(() => {
  socket.connect()
})

// Quand on veut démonter le composant
onUnmounted(() => {
  socket.disconnect()
})
</script>