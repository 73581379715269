<script setup>
import {computed} from "vue";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import Breadcrumbs from "../Breadcrumbs.vue";
import {userStore} from "@/store/pinia-stores/user.store";

const store = useStore();
const isRTL = computed(() => store.state.isRTL);

const route = useRoute();

const currentRouteName = computed(() => {
  return route.name;
});
const currentDirectory = computed(() => {
  let dir = route.path.split("/")[1];
  return dir.charAt(0).toUpperCase() + dir.slice(1);
});

const minimizeSidebar = () => store.commit("sidebarMinimize");

// Utilisateur courant
const storeUser = userStore()
const user = computed(() => storeUser?.user)

</script>
<template>
  <nav
      id="navbarBlur"
      :class="isRTL ? 'top-0 position-sticky z-index-sticky' : ''"
      class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-md"
      data-scroll="true"
      v-bind="$attrs"
  >
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs
          :current-directory="currentDirectory"
          :current-page="currentRouteName"
      />

      <div
          id="navbar"
          :class="isRTL ? 'px-0' : 'me-sm-4'"
          class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
      >
        <div
            :class="isRTL ? 'me-md-auto' : 'ms-md-auto'"
            class="pe-md-3 d-flex align-items-center"
        >
          <!--          <div class="input-group">
                      <span class="input-group-text text-body">
                        <i class="fas fa-search" aria-hidden="true"></i>
                      </span>
                      <input
                          type="text"
                          class="form-control"
                          :placeholder="isRTL ? 'أكتب هنا...' : 'Rechercher ici...'"
                      />
                    </div>-->
        </div>

        <ul class="navbar-nav justify-content-end">
          <li class="nav-item d-flex align-items-center mx-2">
            <router-link
                :target=" user ?'': '_blank'"
                :to="{ name: user ? 'Profile':'Signin' }"
                class="px-0 nav-link font-weight-bold text-dark"
            >
              <!--              <span v-if="isRTL" class="d-sm-inline d-none">يسجل دخول</span>-->
              <span v-if="user"
                    class="d-sm-inline d-none">{{ user?.firstname || 'Utilisateur' }} {{ user?.lastname }}</span>
              <span v-else class="d-sm-inline d-none">Connexion</span>
            </router-link>
          </li>

          <!--          Ce qui permet de fermer la sidebar en cas d'un écran plus petit-->
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center mx-2">
            <a
                id="iconNavbarSidenav"
                class="p-0 nav-link text-dark"
                href="#"
                @click="minimizeSidebar"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line bg-dark"></i>
                <i class="sidenav-toggler-line bg-dark"></i>
                <i class="sidenav-toggler-line bg-dark"></i>
              </div>
            </a>
          </li>


        </ul>
      </div>
    </div>
  </nav>

</template>
