<script setup>
const props = defineProps(['params'])
const adresse = props.params.value

</script>

<template>
  <div class="d-flex align-items-center flex-wrap cursor-pointer flex-row">

    <!--  Si une adresse est fournie , nous allons l'afficher à ce niveau-->
    <div v-if="adresse" class="mx-1 px-1 border-radius-xl">
      <i class="fas fa-map px-1"></i>
      <span class="font-weight-bold">
      {{ adresse?.country }}, {{ adresse?.city }}, C/ {{ adresse?.commune || '' }},
          Q / {{
          adresse?.quartier || '-'
        }}
      </span>
    </div>

    <!--  Si aucune adresse n'est fournie -->
    <div v-else class="mx-1 px-1 border-radius-xl">
      <span class="text-secondary">
        -- Aucune fournie --
      </span>
    </div>
  </div>
</template>

<style scoped>
</style>
