<script setup>
import Loading from "@/views/components/Loading.vue";
import {useJustificationCartPayement} from "../services-apis/payements/hooks.payements";
import DetailJustificationCartPayementCard from "./components/DetailJustificationCartPayementCard.vue";
import MostDetailJustificationCartPayementCard from "./components/MostDetailJustificationCartPayementCard.vue";
const {isLoading} = useJustificationCartPayement()

</script>
<template>
  <div class="container-fluid">
    <div class="row">

      <div class="col-md-7">
        <Loading v-if="isLoading"/>
        <detail-justification-cart-payement-card />
      </div>

      <div class="col-md-5 mt-sm-0">
        <most-detail-justification-cart-payement-card />
      </div>

    </div>
  </div>
</template>
