<template>

  <div @click="showModal">
    <slot/>
  </div>

  <a-modal ref="modalRef" v-model:open="open" :footer="false" :mask="false" :wrap-style="{ overflow: 'hidden' }"
           @ok="handleOk">

    <!--   ============== Le contenu du modal =============== -->
    <div>
      <div class="">
        <div class="modal-header">
          <h5 class="modal-title text-danger">Voulez-vous supprimer ?</h5>
        </div>

        <div class="modal-body">

          <p class="text-dark font-weight-normal text-sm lh-lg fw-light">
            Vous êtes sur le point de supprimer le produit <span class="fw-bold text-link-blue">{{
              product?.name
            }}</span>. Cette action entraînera la suppression définitive de ce produit de votre boutique ainsi que de
            toutes les commandes clients associées.
            <br> Cette opération est irreversible ! Veuillez confirmer votre choix."
          </p>
          <div class="modal-footer">
            <a-button class="text-xs btn-sm m-1" type="default"
                      @click="handleOk">
              Annuler
            </a-button>

            <a-button :loading="store.isLoading" class="text-xs btn-sm text-white bg-danger m-1" type="default"
                      @click="handleDelete">
              Supprimer
            </a-button>
          </div>
        </div>

      </div>
    </div>
    <!-- ======================================================-->

    <template #title>
      <div ref="modalTitleRef" class="text-secondary font-weight-normal text-xs" style="width: 100%; cursor: move"><i
          class="fas fa-bars px-1"></i>Déplacer
      </div>
    </template>

    <template #modalRender="{ originVNode }">
      <div :style="transformStyle">
        <component :is="originVNode"/>
      </div>
    </template>

  </a-modal>

</template>


<script setup>
// Ce composant affiche les différentes options de traitement pour tout ce qui concerne les paiements
import {ref} from "vue";
import {useDraggableModal} from "@/hook/draggableModal";
import {useExpeditionsEvents} from "../../services-apis/expeditions/hooks.expeditions";
import router from "../../router";
import {deleteProduct} from "../../services-apis/products/products-request";
import {productStore} from "../../store/pinia-stores/products-store";

const store = productStore()
const props = defineProps({
  product: {type: Object}
})

const modalTitleRef = ref(null);
const {open, transformStyle, showModal, handleOk} = useDraggableModal(modalTitleRef);
const {refetch} = useExpeditionsEvents()

const callback = async () => {
  await refetch()
  handleOk()
  router.back()
}

const handleDelete = () => {
  deleteProduct(props.product?.id, callback)
}

</script>
