<template>

  <div @click="showModal">
    <slot/>
  </div>

  <a-modal ref="modalRef" v-model:open="open" :footer="false" :mask="false" :wrap-style="{ overflow: 'hidden' }"
           @ok="handleOk">

    <!--   ============== Le contenu du modal =============== -->
    <div>
      <div class="">
        <div class="modal-header">
          <div class="col">
            <h5 id="exampleModalLabel" class="modal-title">Nouveau cargo d'expédition</h5>
            <p class="text-dark font-weight-normal text-xs">
              Les évenéments ou annonces sont des publications annonçant des expéditions ou des livraisons à venir ou
              effectives.
            </p>
          </div>
        </div>

        <div class="modal-body">

          <strong class="text-bold text-sm text-dark">
            Cliquez sur le boutons suivants
            <!--            Choisissez une des options suivantes-->
          </strong>

          <ul class="list-group">

            <!--  Expédition aérienne -->
            <li
                class="list-group-item border-1 d-flex p-3 my-2 bg-gray-100 cursor-pointer"
                @click="()=>{
                  handleOk()
                  states.setIsOpenNewAirExp(true)}"
            >
              <div class="row">
                <div class="d-flex flex-column col-md-3 col-sm-2">
                  <div
                      class="border-0 icone border-radius-md bg-white d-flex justify-content-center align-items-center">
                    <i class="fas fa-plane text-md"></i>
                  </div>
                </div>

                <div class="d-flex flex-column col-md-8 col-sm-6">
                  <h6 class="mb-2 text-sm">Expédition aérienne</h6>
                  <span class="text-dark ms-sm-1 text-xs">Annoncer une date de départ et d'arrivée d'un avion cargo ( aéroport de depart, d'arrivée, nom de la compagnie ... )</span>
                </div>
              </div>
            </li>

            <!--  Expédition maritime -->
            <li
                class="list-group-item border-1 d-flex p-3 my-2 bg-gray-100 cursor-pointer"
                @click="()=>{
                  handleOk()
                  states.setIsOpenNewShippingExp(true)}"
            >
              <div class="row">
                <div class="d-flex flex-column col-md-3 col-sm-2">
                  <div
                      class="border-0 icone border-radius-md bg-white d-flex justify-content-center align-items-center">
                    <i class="fas fa-ship text-md"></i>
                  </div>
                </div>

                <div class="d-flex flex-column col-md-8 col-sm-6">
                  <h6 class="mb-2 text-sm">Expédition maritime</h6>
                  <span class="text-dark ms-sm-1 text-xs">
                  Annoncer une date de départ et d'arrivée d'un navire ( port de départ, port de destination, nom de la compagnie ... )</span>
                </div>
              </div>
            </li>

          </ul>

          <div class="modal-footer">
            <a-button
                class="text-xs text-white mb-2 bg-danger border-0"
                type="default" @click="handleOk">
              Quitter
            </a-button>
          </div>
        </div>

      </div>
    </div>
    <!-- ======================================================-->

    <template #title>
      <div ref="modalTitleRef" class="text-secondary font-weight-normal text-xs" style="width: 100%; cursor: move"><i
          class="fas fa-bars px-1"></i>Déplacer
      </div>
    </template>

    <template #modalRender="{ originVNode }">
      <div :style="transformStyle">
        <component :is="originVNode"/>
      </div>
    </template>

  </a-modal>

</template>


<script setup>
import {ref} from "vue";
import {useDraggableModal} from "@/hook/draggableModal";
import {globalReactive} from "../../reactiveState/global";

const states = globalReactive
const modalTitleRef = ref(null);
const {open, transformStyle, showModal, handleOk} = useDraggableModal(modalTitleRef);

</script>

<style scoped>
.icone {
  height: 80px;
  width: 80px;
}
</style>
