import {userStore} from "@/store/pinia-stores/user.store";
import api from "@/services-apis/axios-init";
import {toast} from "vue3-toastify";
import {Styles} from "@/assets/js/styles";
import {expeditionStore} from "../../store/pinia-stores/expedition-store";

// Créer une nouvelle expedition aérienne
export const createAirExpedition = (data, refetch, messageSuccess, closeModal) => {
    let token = userStore()?.tokens?.accessToken
    let headers = {Authorization: `Bearer ${token}`}

    const store = expeditionStore()

    store.setIsLoading(true)

    api.post(`/admin/expedition-air/new`, data, {headers}).then(res => {
        const msg = res.data.message
        console.log("Les datas:", res.data.data)

        // On change l'état de la commande actuelle
        refetch()

        // Arrêter le chargement
        store.setIsLoading(false)

        // On ferme le modal
        closeModal()

        // Message du succès d'enregistrement user / admin
        toast.success(messageSuccess || msg || 'Avion cargo enregistré', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle,
            autoClose: 2000,
        })

    }).catch(err => {
        // Arrêter le chargement
        store.setIsLoading(false)
        let msg = err?.response?.data.message

        toast.error(msg || 'Impossible de réaliser cette opération', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle
        })

        console.log("Error avion création:", {err})

    })
}

// Créer une nouvelle expedition maritime
export const createShippingExpedition = (data, refetch, messageSuccess, closeModal) => {
    let token = userStore()?.tokens?.accessToken
    let headers = {Authorization: `Bearer ${token}`}

    const store = expeditionStore()

    store.setIsLoading(true)

    api.post(`/admin/expedition-ship/new`, data, {headers}).then(res => {
        const msg = res.data.message
        // console.log("Les datas:", res.data.data)

        // On change l'état de la commande actuelle
        refetch()

        // Arrêter le chargement
        store.setIsLoading(false)

        // On ferme le modal
        closeModal()

        // Message du succès d'enregistrement user / admin
        toast.success(messageSuccess || msg || 'Conteneur enregistré', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle,
            autoClose: 2000,
        })

    }).catch(err => {
        // Arrêter le chargement
        store.setIsLoading(false)
        let msg = err?.response?.data.message

        toast.error(msg || 'Impossible de réaliser cette opération', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle
        })

        console.log("Error avion création:", {err})

    })
}

// Ajouter des commandes dans une expédition maritime (ou dans un conteneur)
export const addCommandIntoShippingExpedition = (transporterId, data, refetch) => {
    let token = userStore()?.tokens?.accessToken
    let headers = {Authorization: `Bearer ${token}`}

    const store = expeditionStore()

    store.setIsLoading(true)

    api.post(`/admin/container/${transporterId}/add-command`, data, {headers}).then(res => {
        const msg = res.data.message
        // console.log("Les datas:", res.data.data)

        // On change l'état de la commande actuelle
        refetch()

        // Arrêter le chargement
        store.setIsLoading(false)

        // Message du succès d'enregistrement user / admin
        toast.success(msg || 'Commande ajoutée dans le conteneur', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle,
            autoClose: 2000,
        })

    }).catch(err => {
        // Arrêter le chargement
        store.setIsLoading(false)
        let msg = err?.response?.data.message

        toast.error(msg || 'Impossible de réaliser cette opération', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle
        })

        console.log("Error avion création:", {err})

    })
}

// Ajouter des commandes dans une expédition aérienne (ou dans un avion)
export const addCommandIntoAirCargoExpedition = (transporterId, data, refetch) => {
    let token = userStore()?.tokens?.accessToken
    let headers = {Authorization: `Bearer ${token}`}

    const store = expeditionStore()

    store.setIsLoading(true)

    api.post(`/admin/aircargo/${transporterId}/add-command`, data, {headers}).then(res => {
        const msg = res.data.message
        // console.log("Les datas:", res.data.data)

        // On change l'état de la commande actuelle
        refetch()

        // Arrêter le chargement
        store.setIsLoading(false)


        // Message du succès d'enregistrement user / admin
        toast.success(msg || "Commande ajoutée dans l'avion cargo", {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle,
            autoClose: 2000,
        })

    }).catch(err => {
        // Arrêter le chargement
        store.setIsLoading(false)
        let msg = err?.response?.data.message

        toast.error(msg || 'Impossible de réaliser cette opération', {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle
        })

        console.log("Error avion création:", {err})

    })
}

/*
* Ajouter une étape dans la progression de l'expédition
* */
export const addStepExpedition = (expeditionId, data, refetch, messageSuccess) => {
    let token = userStore()?.tokens?.accessToken
    let headers = {Authorization: `Bearer ${token}`}

    const store = expeditionStore()
    store.setIsLoading(true)

    api.post(`/admin/expedition/${expeditionId}/add-step`, data, {headers}).then(res => {
        const msg = res.data.message

        // On recharge les données
        refetch()

        // Arrêter le chargement
        store.setIsLoading(false)

        // Message du succès d'enregistrement user / admin
        toast.success(messageSuccess || msg || "Etape ajoutée", {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle,
            autoClose: 3000,
        })

    }).catch(err => {
        // Arrêter le chargement
        store.setIsLoading(false)

        let msg = err.response.data.message

        toast.error(msg || "impossible d'ajouter cette étape de livraison", {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle
        })

        console.log({err})

    })
}

/*
* Supprimer un cargo en expédition
* */
export const deleteCargo = (expeditionId, refetch) => {
    let token = userStore()?.tokens?.accessToken
    let headers = {Authorization: `Bearer ${token}`}

    const store = expeditionStore()
    store.setIsLoading(true)

    api.delete(`/admin/expedition/${expeditionId}/delete-cargo`, {headers}).then(res => {
        const msg = res.data.message

        // On recharge les données
        refetch()

        // Arrêter le chargement
        store.setIsLoading(false)

        // Message du succès d'enregistrement user / admin
        toast.success(msg || "Cargo et expédition supprimée", {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle,
            autoClose: 3000,
        })

    }).catch(err => {
        // Arrêter le chargement
        store.setIsLoading(false)

        let msg = err.response.data.message

        toast.error(msg || "impossible de supprimer maintenant", {
            position: 'bottom-center',
            hideProgressBar: true,
            bodyClassName: 'body-toaster',
            toastStyle: Styles.toastStyle
        })

        console.log({err})

    })
}

