<script setup>
import dayjs from "dayjs";
import {computed} from "vue";
import {useExpedition} from "../../services-apis/expeditions/hooks.expeditions";
import ExpeditionTimeLineSide from "./side-drawer/ExpeditionTimeLineSide.vue";
import AddCommandesInExpedition from "./side-drawer/AddCommandesInExpedition.vue";
import ItemCommandeInExpedition from "./ItemCommandeInExpedition.vue";
import DeleteExpeditionAndCargo from "../modals/DeleteExpeditionAndCargo.vue";

const {expedition, commandes, steps} = useExpedition()
// Données sur l'expédition
const isAirExp = computed(() => !!expedition.value?.airCargo)
const departPlace = computed(() => expedition.value?.airCargo ? expedition.value?.airCargo?.originAirport : expedition.value?.container?.originPort)
const destinationPlace = computed(() => expedition.value?.airCargo ? expedition.value?.airCargo?.destinationAirport : expedition.value?.container?.destinationPort)
const companyName = computed(() => expedition.value?.airCargo ? expedition.value?.airCargo?.airlineName : expedition.value?.container?.companyName)
const refContainer = computed(() => expedition.value?.container ? expedition.value?.container?.containerReference : null)
const cargoFlightNumber = computed(() => expedition.value?.airCargo ? expedition.value?.airCargo?.flightNumber : null)
const cargoAirwayBillNumber = computed(() => expedition.value?.airCargo ? expedition.value?.airCargo?.airwayBillNumber : null)
const urlImgDepart = computed(() => expedition.value?.airCargo ? `https://i.pinimg.com/736x/d8/4c/ce/d84cce83e759ed4a5759f6ed8e0ff3bd.jpg` : `https://i.pinimg.com/736x/8b/20/a9/8b20a985115f0c0c1d6f38219fd4a25f.jpg`)
const urlImgArrivee = computed(() => expedition.value?.airCargo ? `https://i.pinimg.com/736x/d6/a2/49/d6a24975a4a55b99f7eeaae3f98fd24f.jpg` : `https://i.pinimg.com/736x/fa/5a/45/fa5a45a303ff6c6bd154925066cb7110.jpg`)

// Le transport de la commande
const transportText = computed(() => !isAirExp.value ? 'Maritime' : 'Aérien')

</script>

<template>
  <div class="card">
    <div class="card-header pb-0 px-3">
      <h6 class="mb-0 font-weight-bold">Informations sur l'expédition</h6>
    </div>
    <div class="card-body pt-4 p-3">
      <ul class="list-group">
        <li
            class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <h6 class="mb-3 text-sm font-weight-bold text-link-blue">Détails du voyage</h6>

            <div class="d-flex flex-row gap-1 flex-wrap">
              <div class="card my-1 cursor-pointer shadow-none bg-transparent"
                   style="border-radius: 10px; max-width: 250px;">
                <div class="card-body d-flex align-items-center py-2">
                  <!-- Content -->
                  <div class="flex-grow-1">
                    <h6 class="mb-1 fw-bold text-sm">
                      <small class="text-muted text-sm text-normal">Compagnie:</small>
                      <br>
                      {{ companyName }}
                    </h6>
                  </div>
                </div>
              </div>
              <!--  Départ -->
              <div class="card my-1 cursor-pointer shadow-none"
                   style="border-radius: 10px; max-width: 250px;">
                <div class="card-body d-flex align-items-center py-2">
                  <!-- Icon -->
                  <div class="border-radius-xl me-2 d-flex align-items-center justify-content-center"
                       style="width: 40px; height: 40px;border: .5px solid rgba(128,128,128,0.27);padding: 4px;">
                    <img
                        :src="urlImgDepart"
                        alt="Zoom icon"
                        class="border-radius-xl"
                        style="width: 30px; height: 30px;"/>
                  </div>
                  <!-- Content -->
                  <div class="flex-grow-1">
                    <h6 class="mb-1 fw-bold text-sm">{{ departPlace }}</h6>
                    <!-- Affichons ces details de façon dynamiques -->
                    <small v-if="isAirExp" class="text-muted">No. Vol: {{ cargoFlightNumber }}</small>
                    <small v-else class="text-muted">Ref. Conteneur: {{ refContainer }}</small><br>

                    <small v-if="isAirExp" class="text-muted">No. (AWB): {{ cargoAirwayBillNumber }}</small>
                  </div>
                </div>
              </div>

              <!--  Arrivée -->
              <div class="card my-1 cursor-pointer shadow-none"
                   style="border-radius: 10px; max-width: 250px;">
                <div class="card-body d-flex align-items-center py-2">
                  <!-- Icon -->
                  <div class="border-radius-xl me-2 d-flex align-items-center justify-content-center rotate-180"
                       style="width: 40px; height: 40px;border: .5px solid rgba(128,128,128,0.27);padding: 4px;">
                    <img
                        :src="urlImgArrivee"
                        alt="Zoom icon"
                        class="border-radius-xl"
                        style="width: 30px; height: 30px;"/>
                  </div>
                  <!-- Content -->
                  <div class="flex-grow-1">

                    <h6 class="mb-1 fw-bold text-sm">{{ destinationPlace }}</h6>
                    <small class="text-muted">A: {{ dayjs(expedition?.livraisonDate).format('DD MMM. YYYY, HH:mm') }}
                      (estimation)</small>
                  </div>
                </div>
              </div>

              <!-- Suppression d'un cargo-->
              <delete-expedition-and-cargo :event="expedition">
                <a-button class="text-xs text-danger bg-danger-soft" type="link">Supprimer cargo</a-button>
              </delete-expedition-and-cargo>

            </div>

          </div>

        </li>

        <!--        Détails textuel de la commande (informations avancées) -->
        <li
            class="list-group-item border-0 d-flex p-4 mb-2 mt-3 bg-gray-100 border-radius-lg"
        >
          <div class="d-flex flex-column w-100">
            <div class="d-flex flex-row justify-content-between align-items-center mb-3 w-100">
              <h6 class=" text-sm font-weight-bold text-link-blue">Données sur le tracking</h6>
              <expedition-time-line-side>
                <a-button class="text-xs text-dark" type="default">Mettre à jour le parcours</a-button>
              </expedition-time-line-side>
            </div>

            <!-- Les étapes à afficher -->
            <template v-if="steps?.length>0">
              <a-timeline mode="alternate">
                <a-timeline-item v-for="step in steps" :key="step.id">
                  {{ step.stepLabel || 'Texte non chargé' }}
                </a-timeline-item>
              </a-timeline>
            </template>

            <!-- En cas d'étapes absentes -->
            <div v-if="steps?.length===0" class="text-center">
                <span class="mb-2 text-sm">
              Aucune donnée disponible pour l'instant,
                  <!--              <span class="text-dark font-weight-bold ms-sm-1 text-sm">les données sur le tracking apparaîtront ici une fois disponible</span>-->
              les données sur le tracking apparaîtront ici une fois disponible
            </span>
            </div>

          </div>

        </li>

        <!-- Les commandes concernées -->
        <li
            class="list-group-item border-0 d-flex p-4 mb-2 mt-3 bg-gray-100 border-radius-lg w-100"
        >
          <div class="d-flex flex-column w-100">

            <div class="d-flex flex-row justify-content-between align-items-center mb-3 w-100">
              <h6 class="mb-1 text-sm font-weight-bold text-link-blue">Commandes incluses ({{ commandes?.length }} au
                total)</h6>
              <add-commandes-in-expedition>
                <a-button class="text-xs text-dark" type="default">Ajouter des commandes</a-button>
              </add-commandes-in-expedition>
            </div>

            <span v-if="commandes?.length===0"
                  class="mb-2 text-muted text-sm">Aucune commande embarquée pour le moment</span>
            <span v-else
                  class="mb-2 text-muted text-sm">Toutes les commandes des clients embarquées dans cette expédition</span>


            <div class="d-flex flex-row flex-wrap gap-1 align-items-start">
              <!--  Item d'une commande Donnée -->
              <ItemCommandeInExpedition v-for="colis in commandes" :key="colis?.id" :commande="colis"/>
            </div>
          </div>
        </li>

        <!--        Section livraison-->
        <li
            class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <h6 class="mb-3 text-sm font-weight-bold text-link-blue">Informations du transporteur</h6>

            <span class="mb-2 text-sm">
              Ces informations proviennent des différentes agences ou compagnies avec lesquelles CA aurait traités deṡ accords d'expédition en amont.
            </span>

            <span class="text-sm mt-3">
              Type d'expédition:
              <span class="text-dark ms-sm-1 text-sm font-weight-bold">
                {{ transportText || "(Inconnu)" }}
              </span>
            </span>
            <hr class="dashed">

            <span class="mb-2 text-sm">
              Compagnie:
              <span class="text-dark ms-sm-1 text-sm font-weight-bold">
                {{ companyName }}
              </span>
            </span>

            <span class="mb-2 text-sm">
              Conteneur/Avion:
                <span v-if="isAirExp" class="text-dark ms-sm-1 text-sm font-weight-bold">No. Vol: {{
                    cargoFlightNumber
                  }}</span>
                <span v-else class="text-dark ms-sm-1 text-sm font-weight-bold">{{
                    refContainer
                  }}</span><br>
            </span>

            <span v-if="isAirExp" class="mb-2 text-sm">
             No. (AWB):
                <span v-if="isAirExp" class="text-dark ms-sm-1 text-sm font-weight-bold"> {{
                    cargoAirwayBillNumber
                  }}</span>
            </span>

            <span class="mb-2 text-sm">
              Voyage prévu:
              <span class="text-dark ms-sm-1 text-sm font-weight-bold">
               {{ departPlace }} <i class="fas fa-arrow-right px-3"></i> {{ destinationPlace }}
              </span>
            </span>

          </div>

        </li>

      </ul>
    </div>
  </div>

</template>
