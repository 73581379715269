import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyAfS2FyHOd9nkjN8MrhJrz8iLLqILrq1hs",
    authDomain: "centralachat-app.firebaseapp.com",
    projectId: "centralachat-app",
    storageBucket: "centralachat-app.appspot.com",
    messagingSenderId: "847565002550",
    appId: "1:847565002550:web:b40cd56a0cd1221a148597"
};

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
