// Changer le price de l'article
import {userStore} from "@/store/pinia-stores/user.store";
import api from "@/services-apis/axios-init";
import {cartStore} from "../../store/pinia-stores/cart-store";
import {computed} from "vue";
import {useQuery} from "@tanstack/vue-query";

// Récupère toutes les dates planifiées pour la livraison du panier ( les dates ainsi leurs étapes )
export const useCartLivraisonDates = () => {
    const store = cartStore()
    const panierCourant = computed(() => store.currentCart)
    const id = computed(() => panierCourant.value?.id)

    // Toutes les commandes
    const getCommands = async () => {
        let token = userStore()?.tokens?.accessToken
        let headers = {Authorization: `Bearer ${token}`}
        // console.log({token})
        const res = id.value ? await api.get(`/admin/carts/${id.value}/delivery-dates`, {headers}) : []
        return res?.data?.data || []
    }

    const {
        data: dates, isPending, isLoading, isError, error, refetch
    } = useQuery({
        queryKey: ['get-dates-livraison-cart', `/admin/carts/${id.value}/delivery-dates`],
        queryFn: getCommands,
        refetchInterval: 3000
    })

    return {
        isPending, isLoading, isError, dates, error,refetch
    }

}
