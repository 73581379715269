<template>

  <div @click="showModal">
    <slot/>
  </div>

  <a-modal ref="modalRef" v-model:open="open" :footer="false" :mask="false" :wrap-style="{ overflow: 'hidden' }"
           @ok="handleOk">

    <!--   ============== Le contenu du modal =============== -->
    <div>
      <div class="">
        <div class="modal-header">
          <div class="col">
            <h5 id="exampleModalLabel" class="modal-title">Nouveau paiement</h5>
            <p class="text-dark font-weight-normal text-xs">
              Faites une opération de création de paiement et confirmez instantanément vos opérations de transaction !
            </p>
          </div>
        </div>

        <div class="modal-body">

          <strong class="text-bold text-sm text-dark">
            Cliquez sur le bouton suivant
            <!--            Choisissez une des options suivantes-->
          </strong>

          <ul class="list-group">
            <li
                class="list-group-item border-1 d-flex p-4 mb-2 mt-3 bg-gray-100 cursor-pointer"
                @click="()=>{
                  handleOk()
                  payementReactive.setModalChooseCommandeForReqPayement(true)}"
            >
              <div class="d-flex flex-column">
                <h6 class="mb-3 text-sm">Requête de paiement</h6>

                <span class="text-dark ms-sm-1 text-xs">
             Vous demanderez au client d'accepter votre requête suite à laquelle vous confirmerez le paiement de celui-ci.</span>
              </div>
            </li>

          </ul>

          <div class="modal-footer">
            <a-button class="text-xs btn-sm text-white font-weight-normal bg-danger m-1"
                      @click="handleOk">
              Quitter
            </a-button>
          </div>
        </div>

      </div>
    </div>
    <!-- ======================================================-->

    <template #title>
      <div ref="modalTitleRef" class="text-secondary font-weight-normal text-xs" style="width: 100%; cursor: move"><i
          class="fas fa-bars px-1"></i>Déplacer
      </div>
    </template>

    <template #modalRender="{ originVNode }">
      <div :style="transformStyle">
        <component :is="originVNode"/>
      </div>
    </template>

  </a-modal>

</template>


<script setup>
// Ce composant affiche les différentes options de traitement pour tout ce qui concerne les paiements
import {ref} from "vue";
import {useDraggableModal} from "@/hook/draggableModal";
import {payementReactive} from "@/reactiveState/payements";

const modalTitleRef = ref(null);
const {open, transformStyle, showModal, handleOk} = useDraggableModal(modalTitleRef);

</script>
