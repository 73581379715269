<script setup>
import {ref} from 'vue';
import {FileAddOutlined} from '@ant-design/icons-vue'

const emit = defineEmits(['update:files', 'remove:file']);
defineProps({
  maxCount: {type: Number}
});

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const previewVisible = ref(false);
const previewImage = ref('');
const previewTitle = ref('');
const fileList = ref([]);

const handleCancel = () => {
  previewVisible.value = false;
  previewTitle.value = '';
};

const handlePreview = async file => {
  if (!file.url && !file.preview) {
    file.preview = await getBase64(file.originFileObj);
  }
  previewImage.value = file.url || file.preview;
  previewVisible.value = true;
  previewTitle.value = file.name || file.url.substring(file.url.lastIndexOf('/') + 1);
};

// Gérer le changement de fichier et émettre les fichiers vers le parent
const handleChange = ({fileList}) => {
  // Mettre à jour la liste de fichiers localement
  fileList.value = fileList;

  // Émettre l'événement avec les fichiers sélectionnés
  emit('update:files', fileList);

};

// Lorsqu'un fichier est rétiré
const handleRemove = (file) => {
  // Emettre un évenement lorsque le fichier est rétiré
  emit('remove:file', file);
};

</script>


<template>
  <div class="clearfix">

    <h4 class="font-weight-bold">Sélectionner un fichier</h4>
    <!--    <h4 class="font-weight-bold">Sélectionner un fichier (3 fichiers au max.)</h4>-->
    <a-upload
        v-model:file-list="fileList"
        :max-count="maxCount"
        accept="image/png, image/jpeg"
        action=""
        list-type="picture-card"
        @change="handleChange"
        @preview="handlePreview"
        @remove="handleRemove"
    >
      <div v-if="fileList.length < 3">
        <FileAddOutlined/>
        <div style="margin-top: 8px">Sélectionner</div>
      </div>
    </a-upload>

    <!--    Preview de l'image-->
    <a-modal :footer="null" :mask="false" :open="previewVisible" @cancel="handleCancel">
      <template #title>
        <h4 class="font-weight-bold">Aperçu de l'image</h4>
        <span>{{ previewTitle }}</span>
      </template>
      <a :href="previewImage" target="_blank">
        <img :src="previewImage" alt="example" style="width: 100%"/>
      </a>
    </a-modal>

  </div>
</template>

<style scoped>
.clearfix {
  clear: both;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

img {
  width: 100%;
  height: auto;
}

button {
  color: #4b00e0;
}
</style>
