<script setup>
import {ref} from "vue";
import {AgGridVue} from "ag-grid-vue3";
import {useCartPayed} from "../../services-apis/products/hooks.products";
import UserRender from "./aggrid-templates/UserRender.vue";
import StatusRender from "./aggrid-templates/StatusRender.vue";
import TotalAmountRender from "./aggrid-templates/TotalAmountRender.vue";
import LinkCartRender from "./aggrid-templates/LinkCartRender.vue";

const {carts} = useCartPayed()
const search = ref('')

const colDefs = ref([
  {headerName: "Nom du client", field: "user", cellRenderer: UserRender},
  {headerName: "Statut", field: "description", width: 150, maxWidth: 200, cellRenderer: StatusRender},
  {
    headerName: "Quantité achetée", field: "pricePerCarton", minWidth: 180,
    children: [
      {headerName: "En gros", field: "qtityCarton", minWidth: 80, width: 170, maxWidth: 250,},
      {headerName: "En détails", field: "qtityUnit", minWidth: 80, width: 170, maxWidth: 250,},
    ]
  },
  {headerName: "Nombre de paiement", field: "numberOfCartPayed", minWidth: 100, width: 170, maxWidth: 300},
  {
    headerName: "Total payé",
    field: "totalAmount",
    minWidth: 100,
    width: 170,
    maxWidth: 300,
    cellRenderer: TotalAmountRender
  },
  {headerName: "", pinned: 'right', minWidth: 100, maxWidth: 150, cellRenderer: LinkCartRender},

]);

const pagination = true;
const paginationPageSize = 10;
const paginationPageSizeSelector = [10, 20, 50, 100];

</script>

<template>
  <div class="card mb-4 border-radius-md">

    <div class="d-flex justify-content-between">
      <div class="card-header pb-0">
        <h6>Paniers payés</h6>
      </div>

      <div class="d-flex flex-row align-items-center">
        <a-input
            v-model:value="search"
            class="m-3 border-radius-xl" placeholder="Trouver un client"
            style="width:18vw;"
            type="text"
        />
      </div>

    </div>

    <div class="card-body px-0 pt-0 pb-2">
      <!-- Si aucun paiement n'est trouvé -->
      <div v-if="carts?.length === 0" class="text-center">
        <div>
          <img alt="" class="img-fluid pointer-none" src="@/assets/img/paye.jpg" style="height: 300px; width: 300px"/>
          <p class="text-normal text-sm text-dark">Aucun paiement enregistré pour le moment</p>
        </div>
      </div>

      <div v-else class="px-3" style="min-height: 55vh;">

        <!-- Contenu et détails dans un tableau -->
        <ag-grid-vue
            :columnDefs="colDefs"
            :pagination="pagination"
            :paginationPageSize="paginationPageSize"
            :paginationPageSizeSelector="paginationPageSizeSelector"
            :rowData="carts"
            class="ag-theme-quartz"
            style="height: 500px"
        >
        </ag-grid-vue>

      </div>
    </div>
  </div>

</template>
