<script setup>
import Loading from "@/views/components/Loading.vue";
import DetailJustificationPayementCard from "./components/DetailJustificationPayementCard.vue";
import {useJustificationPayement} from "../services-apis/payements/hooks.payements";
import MostDetailJustificationPayementCard from "./components/MostDetailJustificationPayementCard.vue";
const {isLoading} = useJustificationPayement()

</script>
<template>
  <div class="container-fluid">
    <div class="row">

      <div class="col-md-7">
        <Loading v-if="isLoading"/>
        <detail-justification-payement-card />
      </div>

      <div class="col-md-5 mt-sm-0">
        <most-detail-justification-payement-card />
      </div>

    </div>
  </div>
</template>
