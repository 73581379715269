export const EvACCEPT_REQUEST_PAYEMENT = "request-payement-ftf-accepted";
export const EvREQUEST_PAYEMENT_SAVED = "request-payement-ftf-save";
export const EvDECLINE_REQUEST_PAYEMENT = "request-payement-ftf-decline";
export const EvREQUEST_PAYEMENT = "request-payement-ftf";
export const EvSEND_REQUEST_PAYEMENT_TO_ADMIN = "request-payement-ftf-client-to-admin";
export const EvJOINROOM = "joinRoom";
export const EvROOMJOINED = "roomJoined";
export const EvADMINJOINROOM = "adminJoinRoom";
export const EvADMINROOMJOINED = "adminRoomJoined";
export const EvErrorJOINROOM = "'errorJoinRoom";
