import axios from 'axios';
export const URL_API = process.env.VUE_APP_URL_API

const api = axios.create({
    baseURL: URL_API,
    maxBodyLength: Infinity,
    headers: {
        "Content-Type": "application/json",
    },
    transformRequest: [(data, headers) => {
        // Si la data est un objet FormData, ne pas fixer de Content-Type
        if (data instanceof FormData) {
            delete headers['Content-Type']; // Axios gérera automatiquement le Content-Type
            return data;
        }

        // Pour les autres requêtes, traiter en JSON
        return JSON.stringify(data);
    }],
    transformResponse: [(data) => JSON.parse(data)],
});

// Message d'erreur après un temps passé et que le serveur ne répond pas
api.interceptors.response.use(
    response => response,
    error => {
        if (error.code === 'ECONNABORTED' || error.code === 'ECONNREFUSED' || error.code === 'ERR_NETWORK') {
            // Timeout error
            return Promise.reject(new Error('Le serveur ne répond pas. Veuillez réessayer plus tard.'));
        }
        return Promise.reject(error);
    }
);


export default api
