<template>

  <a-modal ref="modalRef" v-model:open="payementReactive.isOpenModalSuccessReqPayement" :footer="false" :mask-closable="false"
           :mask="false"
           :wrap-style="{ overflow: 'hidden' }">

    <!--   ============== Le contenu du modal =============== -->
    <div>

      <div class="card-header pb-0">
        <h5 id="exampleModalLabel" class="modal-title">Requête envoyée</h5>
        <p class="text-dark font-weight-normal text-xs">En attente de la réponse du client
          <span class="text-bold text-sm"> {{
              commande?.creator?.firstname || commande?.creator?.email
            }} {{ commande?.creator?.lastname || '' }}</span></p>
      </div>


      <ul class="list-group">
        <li class="list-group-item border-0 d-flex p-4 mb-2 mt-1 bg-gray-100 border-radius-lg">
          <div class="d-flex flex-column">
             <p v-if="payementReactive.isWaitingAnswer" class="text-dark ms-sm-1 text-xs">
                Statut de la requête: <span class="mb-3 text-sm text-bold">En attente de la réponse</span>

              </p>
            <span class="text-secondary ms-sm-1 text-xs mt-2 lh-lg">
              Opération réussie. Patientez pendant que le client accepte votre requête de paiement. Vous pouvez cliquer sur continuer en arrière-plan et faire d'autres opérations, vous serez avertit lorsque le client acceptera votre demande.
            </span>
          </div>
        </li>
      </ul>

      <div class="modal-footer border-0">
        <a-button class="text-xs btn-sm border-radius-xl font-weight-normal bg-link-blue m-1 text-white" type="link"
                  @click="payementReactive.setModalSuccessReqPayement(false)">
          Continuer en arrière-plan
        </a-button>
      </div>

    </div>
    <!-- ======================================================-->

    <template #title>
      <div ref="modalTitleRef" class="text-secondary font-weight-normal text-xs" style="width: 100%; cursor: move"><i
          class="fas fa-bars px-1"></i>Déplacer
      </div>
    </template>

    <template #modalRender="{ originVNode }">
      <div :style="transformStyle">
        <component :is="originVNode"/>
      </div>
    </template>

  </a-modal>

</template>


<script setup>
// Ce composant ouvre le modal qui permet de patientez, pendant que le client doit accepter la requête de paiement
import {ref} from "vue";
import {useDraggableModal} from "@/hook/draggableModal";
import {payementReactive} from "@/reactiveState/payements";
import {useCommande} from "@/services-apis/commandes/hooks.commandes";

const {commande} = useCommande()
const modalTitleRef = ref(null);
const {transformStyle} = useDraggableModal(modalTitleRef);

</script>