import {useQuery} from "@tanstack/vue-query";
import api from "@/services-apis/axios-init";
import {userStore} from "@/store/pinia-stores/user.store";
import {expeditionStore} from "../../store/pinia-stores/expedition-store";
import {computed} from "vue";

// Utiliser tous les évenéments d'expeditions existants
export const useExpeditionsEvents = () => {

    // Toutes les events
    const getEvents = async () => {
        let token = userStore()?.tokens?.accessToken
        let headers = {Authorization: `Bearer ${token}`}
        // console.log({token})
        const res = await api.get('/admin/expedition/events', {headers})
        return res?.data?.data || []
    }

    const {
        data: events, isPending, isLoading, isError, error, refetch
    } = useQuery({
        queryKey: ['get-expeditions-events'], queryFn: getEvents, refetchInterval: 3000
    })

    // console.log({events: events.value})

    return {
        isPending, isLoading, isError, events, error, refetch
    }
}

// Utiliser tous les évenéments d'expeditions existants
export const useExpedition = () => {

    // Une expedition
    const getExp = async () => {
        let token = userStore()?.tokens?.accessToken
        let id = expeditionStore()?.currentExpId
        let headers = {Authorization: `Bearer ${token}`}
        // console.log({token})
        const res = await api.get(`/admin/expedition/${id}`, {headers})
        return res?.data?.data || []
    }

    const {
        data: expedition, isPending, isLoading, isError, error, refetch
    } = useQuery({
        queryKey: ['get-expedition'], queryFn: getExp, refetchInterval: 5000
    })

    const commandes = computed(() => expedition.value?.clientCommandes)
    const steps = computed(() => expedition.value?.steps)
    // console.log({expedition: expedition.value})

    return {
        isPending, isLoading, isError, expedition, steps, commandes, error, refetch
    }
}

