<template>
  <a-modal ref="modalRef" v-model:open="store.isOpenModalConfigureFlatRatePackedCommande" :footer="false" :mask="false"
           :wrap-style="{ overflow: 'hidden' }"
           @ok="handleOk">

    <!--   ===========   Le contenu du modal ============ -->
    <div style="max-height: 85vh; overflow-y: scroll;scrollbar-width: none;">

      <div class="modal-header">
        <div class="col">
          <h5 id="removeRevocationModalLabel" class="modal-title text-dark">Utiliser un forfait</h5>
          <span class="text-xs text-bold text-dark text-decoration-underline">{{ commande?.title }}</span>
          <br>
          <p
              class="text-sm my-3">Il semblerait qu'actuellement le total obtenu pour le type d'expédition <span
              class="text-success bold text-decoration-underline">{{ getTextExpedition(commande?.transport) }}</span>
            est inférieur à <span
                class="text-success bold text-decoration-underline">CBM/2 (moitié d'un CBM)</span></p>
        </div>
      </div>

      <!--      Si l'écran des config d'un forfait n'est pas encore activé -->
      <div v-if="!isConfigFlatRate" class="modal-body">

        <div class="d-flex flex-row align-items-center justify-content-between my-2">
          <div class="d-flex align-items-center flex-row">
            <i class="fas fa-money"></i>
            <span class="text-sm font-weight-bold px-2 text-sm">Prix actuel par CBM</span>
          </div>

          <div class="font-weight-bold px-2 text-sm py-1 border-radius-2xl"
               style="background-color: rgba(0,34,122,0.19);color: rgb(0,37,129)">
            {{ store.dataErrorFlatRate?.pricePerCBM }} • USD
          </div>
        </div>

        <div class="d-flex flex-row align-items-center justify-content-between my-2">
          <div class="d-flex align-items-center flex-row">
            <i class="fas fa-money"></i>
            <span class="text-sm font-weight-bold px-2 text-sm">Total obtenu pour cette expédition</span>
          </div>

          <div class="font-weight-bold px-2 text-sm py-1 border-radius-2xl"
               style="background-color: rgba(0,34,122,0.19);color: rgb(0,37,129)">
            {{ store.dataErrorFlatRate?.currentTotalCBM }} • USD
          </div>
        </div>

        <div class="d-flex flex-row align-items-center justify-content-between my-2">
          <div class="d-flex align-items-center flex-row">
            <i class="fas fa-money"></i>
            <span class="text-sm px-2 text-sm">Valeur autorisable • À partir de </span>
          </div>

          <div class="font-weight-bold px-2 text-sm py-1 border-radius-2xl"
               style="background-color: rgba(0,34,122,0.19);color: rgb(0,37,129)">
            {{ Number(store.dataErrorFlatRate?.pricePerCBM) / 2 }} • USD
          </div>
        </div>

        <div class="my-3 bg-body-secondary mx-2">
          <strong
              class="text-sm">Dans ces cas de figure, vous avez les choix : </strong>

          <ul class="list-group mt-2">
            <li class="list-group-item bg-color-ghost-orange-ca cursor-pointer d-flex flex-row align-items-center justify-content-between"
                @click="isConfigFlatRate=!isConfigFlatRate">
              <span class="color-text-orange-ca">Faire un forfait</span>
              <i aria-hidden="true" class="fa fa-chevron-right text-xs color-text-orange-ca"></i>
            </li>

            <MessagesChats
                defaultContent="Bonjour cher client, votre commande nécessite une attention de votre part sur son transport d'expédition">
              <li class="list-group-item bg-color-ghost-orange-ca cursor-pointer d-flex flex-row align-items-center justify-content-between">
                <span class="color-text-orange-ca">Notifier le client (message)</span>
                <i aria-hidden="true" class="fa fa-chevron-right text-xs color-text-orange-ca"></i>
              </li>
            </MessagesChats>
          </ul>
        </div>

      </div>

      <!--      Lorsqu'on veut configurer un forfait sur une commande-->
      <div v-else class="modal-body">

        <div class="d-flex flex-row align-items-center justify-content-between my-2">
          <div class="font-weight-bold px-2 cursor-pointer text-sm py-1 border-radius-2xl"
               style="background-color: rgba(0,34,122,0.09);color: rgb(0,37,129)"
               @click="isConfigFlatRate=false">
            <i aria-hidden="true" class="fa fa-chevron-left mx-1"></i>
            Retour
          </div>

          <div/>
        </div>

        <div class="my-3 bg-body-secondary mx-2">

          <strong
              class="text-sm">Désigner une série d'article afin d'appliquer le forfait qui correspond: </strong>

          <a-collapse ghost>
            <a-collapse-panel v-for="(rate, index) in flatrates" :key="index" :panel-key="index"
                              :show-arrow="false" header-class="border-none" style="background-color: transparent;">
              <template #header>
                <div
                    class="px-3 py-2 bg-color-ghost-blue-ca border-radius-xl cursor-pointer d-flex flex-row align-items-center justify-content-between">
                  <span class="color-text-blue-ca">{{ rate?.title || rate?.title }}</span>

                </div>
              </template>
              {{ rate?.description }}
              <br>
              <a-button class="text-sm my-3 border-radius-2xl color-text-orange-ca bg-color-ghost-orange-ca bold"
                        type="default"
                        @click="setValueForfait(rate?.id);useCallbackForfait(rate?.id)">
                <i class="fa fa-check px-1"></i>
                Utiliser ce forfait
              </a-button>
            </a-collapse-panel>

          </a-collapse>
        </div>

      </div>

      <div class="modal-footer">

        <!--             Faire suivant pour afin afficher la vue où saisir le price -->
        <form-button
            aria-expanded="false" class="text-xs m-1 font-weight-normal btn-sm bg-dark border-radius-xl"
            data-bs-toggle="collapse"
            @click="store.setIsOpenModalConfigureFlatRatePackedCommande(false)"
        >
          Fermer
        </form-button>
      </div>

    </div>

    <!--      ====================================================-->
    <template #title>
      <div ref="modalTitleRef" class="text-secondary font-weight-normal text-xs" style="width: 100%; cursor: move"><i
          class="fas fa-bars px-1"></i>Déplacer
      </div>
    </template>
    <template #modalRender="{ originVNode }">
      <div :style="transformStyle">
        <component :is="originVNode"/>
      </div>
    </template>
  </a-modal>

</template>
<script setup>
import {useDraggableModal} from "@/hook/draggableModal";
import FormButton from "@/components/FormButton.vue";
import {ref} from "vue";
import {commandeStore} from "@/store/pinia-stores/commande-store";
import {useExpeditionText} from "../../hook/frontHooks";
import {useCommande} from "../../services-apis/commandes/hooks.commandes";
import {useFlatRates} from "../../services-apis/payements/hooks.payements";
import {commandeReactive} from "../../reactiveState/commandes";
import {createPackedCommande} from "../../services-apis/commandes"; // Voir la vue config des fraits forfaitaires
import MessagesChats from "@/views/modals/MessagesChats.vue";

// Voir la vue config des fraits forfaitaires
const isConfigFlatRate = ref(false)

const {getTextExpedition} = useExpeditionText()
const store = commandeStore()
const modalTitleRef = ref(null);
const {transformStyle, handleOk} = useDraggableModal(modalTitleRef);
const {flatrates} = useFlatRates()
const {commande, refetch} = useCommande()

// Code en attendant de savoir s'il sera important plus tard
const setValueForfait = (id) => {
  store.setMetadataPackedCommande({...store.metadataPackedCommande, forfaitId: id})
}

// Renvoyer la requete d'emballage
const useCallbackForfait = (id) => {
  // Commencer la sauvegarde
  createPackedCommande({...commandeReactive.dataPacked, metadata: {forfaitId: id}}, refetch, () => {

  })
}

</script>
