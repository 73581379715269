import {defineStore} from "pinia";

export const cartStore = defineStore('cartStoreId', {

    state: () => {
        return {
            sideDetails: false,
            currentCart: null,
            isLoading: false
        }
    },

    actions: {
        //
        setSideDetails(bool) {
            this.sideDetails = bool
        },

        // Changer le panier actuel
        setCurrentCart(cart) {
            this.currentCart = cart
        },

        // Chargement
        setIsLoading(bool) {
            this.isLoading = bool
        },

    }
})
