import {reactive} from "vue";

export const payementReactive = reactive({
    // Ouverture du modal pour choisir une commande lors de la requête de paiement
    isOpenModalChooseCommandeForReqPayement: false,
    setModalChooseCommandeForReqPayement: (value) => {
        payementReactive.isOpenModalChooseCommandeForReqPayement = value
    },

    // Ouverture du modal pour créer une requête de paiement d'une commande choisie
    isOpenModalCreateReqPayement: false,
    setModalCreateReqPayement: (value) => {
        payementReactive.isOpenModalCreateReqPayement = value
    },

    // Ouverture du modal pour créer une requête de paiement d'une commande choisie
    isOpenModalSuccessReqPayement: false,
    setModalSuccessReqPayement: (value) => {
        payementReactive.isOpenModalSuccessReqPayement = value
    },

    // Ouverture du modal pour de valider/sauvegarder un paiement après, que le client aît accepter la requête de paiement
    isOpenModalSavePayementOfReqAccepted: false,
    setIsOpenModalSavePayementOfReqAccepted: (value) => {
        payementReactive.isOpenModalSavePayementOfReqAccepted = value
    },

    // Ouverture du modal pour Visualiser une nouvelle demande de paiement provenant d'un client X ou Y
    isOpenModalReceiverPayementRequestOfClients: false,
    setIsOpenModalReceiverPayementRequestOfClients: (value) => {
        payementReactive.isOpenModalReceiverPayementRequestOfClients = value
    },

    // Ouverture du modal pour afficher que cle client a annulé/refusé une requête de paiement
    isOpenModalCancelPayementReq: false,
    setIsOpenModalCancelPayementReq: (value) => {
        payementReactive.isOpenModalCancelPayementReq = value
    },

    // Afficher le spinner pendant l'attente de la réponse du client
    isWaitingAnswer: false,
    setIsWaitingAnswer: (value) => {
        payementReactive.isWaitingAnswer = value
    }
})
