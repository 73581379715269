<template>
  <a-modal ref="modalRef" v-model:open="store.isOpenModalResultForPackedCommandeSuccess" :footer="false"
           :wrap-style="{ overflow: 'hidden' }"
           @ok="handleOk">

    <!--   ===========   Le contenu du modal ============ -->
    <div style="max-height: 85vh; overflow-y: scroll;scrollbar-width: none;">

      <div class="modal-header">
        <div class="col">
          <h5 id="removeRevocationModalLabel" class="modal-title text-dark">Résultat du colis et frais</h5>
          <span class="text-xs text-bold text-dark text-decoration-underline">{{ commande?.title }}</span>
          <br>
          <span class="text-xs">Le client recevra une notification que sa commande a été bien emballée.</span>
        </div>
      </div>

      <div class="modal-body">

        <div class="d-flex flex-row align-items-center justify-content-between my-2">
          <div class="d-flex align-items-center flex-row">
            <i class="fas fa-money"></i>
            <span class="text-sm font-weight-bold px-2 text-sm">Total de la facture</span>
          </div>

          <div class="font-weight-bold px-2 text-sm py-1 border-radius-2xl"
               style="background-color: rgba(0,34,122,0.19);color: rgb(0,37,129)">
            {{ valuesTestResult?.totalApayer }}
          </div>
        </div>

        <a-collapse ghost>
          <a-collapse-panel key="1" :showArrow="false" header="Voir plus des détails">

            <div v-if="flyFret">
              <div class="d-flex flex-row align-items-center justify-content-between my-2">
                <div class="d-flex align-items-center flex-row">
                  <i class="fas fa-check"></i>
                  <span class="text-sm font-weight-bold px-2 text-xs">Poids réel</span>
                </div>

                <div class="font-weight-bold px-2 text-xs py-1 border-radius-2xl"
                     style="background-color: rgba(0,34,122,0.19);color: rgb(0,37,129)">
                  {{ valuesTestResult?.weight }} KG
                </div>
              </div>

              <div class="d-flex flex-row align-items-center justify-content-between my-2">
                <div class="d-flex align-items-center flex-row">
                  <i class="fas fa-check"></i>
                  <span class="text-sm font-weight-bold px-2 text-xs">Poids volumétrique</span>
                </div>

                <div class="font-weight-bold px-2 text-xs py-1 border-radius-2xl"
                     style="background-color: rgba(0,34,122,0.19);color: rgb(0,37,129)">
                  {{ valuesTestResult?.poidsVolume }} KG
                </div>
              </div>

              <div class="d-flex flex-row align-items-center justify-content-between my-2">
                <div class="d-flex align-items-center flex-row">
                  <i class="fas fa-check"></i>
                  <span class="text-sm font-weight-bold px-2 text-xs">Prix par poids</span>
                </div>

                <div class="font-weight-bold px-2 text-xs py-1 border-radius-2xl"
                     style="background-color: rgba(0,34,122,0.19);color: rgb(0,37,129)">
                  {{ valuesTestResult?.prixParKG }}
                </div>
              </div>
              <hr class="solid">

            </div>
            <div class="d-flex flex-row align-items-center justify-content-between my-2">
              <div class="d-flex align-items-center flex-row">
                <i class="fas fa-check"></i>
                <span class="text-sm font-weight-bold px-2 text-xs">Longueur</span>
              </div>

              <div class="font-weight-bold px-2 text-xs py-1 border-radius-2xl"
                   style="background-color: rgba(0,34,122,0.19);color: rgb(0,37,129)">
                {{ valuesTestResult?.length }} cm
              </div>
            </div>

            <div class="d-flex flex-row align-items-center justify-content-between my-2">
              <div class="d-flex align-items-center flex-row">
                <i class="fas fa-check"></i>
                <span class="text-sm font-weight-bold px-2 text-xs">Largeur</span>
              </div>

              <div class="font-weight-bold px-2 text-xs py-1 border-radius-2xl"
                   style="background-color: rgba(0,34,122,0.19);color: rgb(0,37,129)">
                {{ valuesTestResult?.width }} cm
              </div>
            </div>

            <div class="d-flex flex-row align-items-center justify-content-between my-2">
              <div class="d-flex align-items-center flex-row">
                <i class="fas fa-check"></i>
                <span class="text-sm font-weight-bold px-2 text-xs">Hauteur</span>
              </div>

              <div class="font-weight-bold px-2 text-xs py-1 border-radius-2xl"
                   style="background-color: rgba(0,34,122,0.19);color: rgb(0,37,129)">
                {{ valuesTestResult?.height }} cm
              </div>
            </div>


            <hr class="solid">

            <div class="d-flex flex-row align-items-center justify-content-between my-2">
              <div class="d-flex align-items-center flex-row">
                <i class="fas fa-check"></i>
                <span class="text-sm font-weight-bold px-2 text-xs">Total à payer</span>
              </div>

              <div class="font-weight-bold px-2 text-xs py-1 border-radius-2xl"
                   style="background-color: rgba(248,130,4,0.18);color: rgb(248,130,4)">
                {{ valuesTestResult?.totalApayer }}
              </div>
            </div>

            <hr class="solid">

            <span class="text-sm font-weight-bold px-2 text-xs">Choix du client (méthode de transport)</span>
            <div class="d-flex flex-row align-items-center justify-content-between my-2">
              <span class="text-sm px-2">Estimation de la durée d'expédition</span>
              <span
                  class="text-sm font-weight-bold px-2 text-xs">{{
                  valuesTestResult?.transport?.estimatedDeliveryDays
                }} jours</span>

              <div class="font-weight-bold px-2 text-xs py-1 border-radius-2xl"
                   style="background-color: rgba(0,34,122,0.19);color: rgb(0,37,129)">
                {{ valuesTestResult?.fretType }}
              </div>
            </div>

          </a-collapse-panel>
        </a-collapse>

      </div>

      <div class="modal-footer">

        <!--             Faire suivant pour afin afficher la vue où saisir le price -->
        <form-button
            aria-expanded="false" class="text-xs m-1 font-weight-normal btn-sm bg-dark border-radius-xl"
            data-bs-toggle="collapse"
            @click="
            store.setIsOpenModalResultForPackedCommandeSuccess(false);
            store.setIsOpenModalResultForPackedCommandeSuccess(false);
            store.setIsOpenModalConfigureFlatRatePackedCommande(false, null)"
        >
          Fermer
        </form-button>
      </div>

    </div>

    <!--      ====================================================-->
    <template #title>
      <div ref="modalTitleRef" class="text-secondary font-weight-normal text-xs" style="width: 100%; cursor: move"><i
          class="fas fa-bars px-1"></i>Déplacer
      </div>
    </template>
    <template #modalRender="{ originVNode }">
      <div :style="transformStyle">
        <component :is="originVNode"/>
      </div>
    </template>
  </a-modal>

</template>
<script setup>
import {useDraggableModal} from "@/hook/draggableModal";
import FormButton from "@/components/FormButton.vue";
import {computed, ref} from "vue";
import {commandeStore} from "@/store/pinia-stores/commande-store";

const store = commandeStore()
const valuesTestResult = computed(() => store.valuesTestResult)
const flyFret = computed(() => valuesTestResult.value?.transport?.way === 'AVION')

const modalTitleRef = ref(null);
const {transformStyle, handleOk} = useDraggableModal(modalTitleRef);

</script>
