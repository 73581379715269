<script setup>
import {computed, ref} from "vue";
import {useExpedition} from "../../../services-apis/expeditions/hooks.expeditions";
import {addCommandIntoAirCargoExpedition, addCommandIntoShippingExpedition} from "../../../services-apis/expeditions";
import {expeditionStore} from "../../../store/pinia-stores/expedition-store";
import {useCommandes} from "../../../services-apis/commandes/hooks.commandes";
import ItemCommandeAddingExpedition from "../ItemCommandeAddingExpedition.vue";
import validator from "validator";

const search = ref('')

const expStore = expeditionStore()
const {expedition, refetch} = useExpedition()
const isAirExp = computed(() => !!expedition.value?.airCargo)
const isLoading = computed(() => expStore.isLoading)
const isOpen = ref(false)
const currentPage = ref(1);

const {commandes: allCommandes} = useCommandes()
const lenPage = computed(() => {
  const totalItems = commandes.value?.length || 0;

  if (totalItems <= 7) {
    // Si on a 7 éléments ou moins, on affiche tout sur une seule page
    return 1;
  }

  // Pour les éléments au-delà de 7 : on calcule le nombre de pages restantes après avoir affiché 7 éléments sur la première page
  const remainingItems = totalItems - 7;

  // Le nombre de pages nécessaires après la première page (avec 10 éléments par page)
  // On multiplie par 10 pour s'assurer que le composant pagination collecte bien toutes les données afin d'afficher un bon nombe des pages
  const additionalPages = Math.ceil(remainingItems / 10) * 10;

  // 1 page pour les 5 premiers éléments + pages supplémentaires
  return additionalPages + 1;
});
// Pagination des composants
const paginatedCommandes = computed(() => {
  const totalCommandes = allCommandes.value || [];
  const activePage = currentPage.value;
  if (activePage === 1) {
    // Première page : afficher les 15 premiers éléments
    return totalCommandes.slice(0, 15);
  } else {
    // Autres pages : afficher 10 éléments par page
    const start = 15 + (activePage - 2) * 10;
    const end = start + 10;
    return totalCommandes.slice(start, end);
  }
});
const commandeSearch = computed(() => paginatedCommandes.value?.filter(item => (
    validator.contains(item?.title, search.value, {ignoreCase: true})
    ||
    validator.contains(item?.creator?.firstname, search.value, {ignoreCase: true})
    ||
    validator.contains(item?.creator?.lastname, search.value, {ignoreCase: true})
    ||
    validator.contains(item?.creator?.email, search.value, {ignoreCase: true})
)))

// Les commandes concernées par le type d'expéditon actuelle
const commandes = computed(() => commandeSearch.value?.filter((cmd) => isAirExp.value ? cmd?.transport?.way === 'AVION' : cmd?.transport?.way === 'BOAT'))
// Le type de transport de l'expedition actuelle
const transportWay = computed(() => isAirExp.value ? 'AVION' : 'BOAT')

// Les commandes ajoutées en forme d'objet complet
const commandeAdded = ref([])
// Les commandes ajoutées en forme d'objet d'un tableau des ids
const commandIds = computed(() => {
  return commandeAdded.value.map((item) => item.id);
});

// Ajouter une commande dans la liste de celle à ajouter dans l'expedition
const addCommande = (params) => {
  commandeAdded.value = [...commandeAdded.value, params.data]
  // console.log("Add :", params.data)
}

// retirer une commande dans la liste de celle à retirer de l'expedition
const removeCommande = (params) => {
  let clone = commandeAdded.value = [...commandeAdded.value]
  let futurTab = clone.filter((item) => item !== params.data)
  commandeAdded.value = futurTab
  // console.log("Remove :", params.data)
}

const refetchData = async () => {
  // Réinitialiser les valeurs
  commandIds.value = []
  commandeAdded.value = []

  // Puis recharger les expeditions
  await refetch()
  toggleSide()
}

const handleAddCommand = () => {
  if (commandIds.value.length === 0) {
    toggleSide()
    return
  }

  const data = {
    "commandIds": commandIds.value
  };
  const transporter = isAirExp.value ? expedition.value?.airCargo : expedition.value?.container

  // Expedition aérienne
  if (transportWay.value === 'AVION') {
    addCommandIntoAirCargoExpedition(transporter.id, data, refetchData)
  }

  // Expedition maritime
  if (transportWay.value === 'BOAT') {
    addCommandIntoShippingExpedition(transporter.id, data, refetchData)
  }
}

const toggleSide = () => isOpen.value = !isOpen.value

</script>

<template>

  <div @click="toggleSide">
    <slot/>
  </div>

  <a-drawer v-model:open="isOpen" :mask="true" body-style="min-width: 500px;width: 40vw;padding: 0px;" placement="right"
            width="auto">

    <template #title>
      <div class="card-header pb-0 px-2">
        <h6 class="mb-0 modal-title">Commandes dans l'expédition du transporteur</h6>

        <a-input
            v-model:value="search"
            class="form-control bg-gray-100 font-weight-normal my-1 w-90"
            placeholder="Tapez un mot clé pour faire trouver une commande ..."
        />
      </div>
    </template>

    <!--   ============== Le contenu du modal =============== -->
    <div class="position-relative">
      <div class="card-body py-1">
        <!--        <div
                    class="list-group-item border-0 d-flex flex-column px-4 bg-gray-100 border-radius-lg"
                >
                  <div class="font-weight-bold text-lg text-dark">Ajouter des commandes à expédition cette fois-ci</div>
                  <div class="d-flex flex-column">
                    <span class="mb-2 text-xs">
                     Ces informations seront communiquées aux clients dont les commandes seront embarquées dans cette expédition
                    </span>
                  </div>
                </div>-->

        <div class="list-group-item border-0 d-flex flex-column border-radius-lg w-100">

          <!--  Une ligne vide: aucune commande à afficher -->
          <div v-if="commandes?.length===0" class="text-center">
                    <span class="mb-2 text-xs">
                   Aucune ligne à afficher
                    </span>
          </div>

          <!-- Liste des commandes de ce type d'expédition -->
          <ItemCommandeAddingExpedition v-for="colis in commandes" :key="colis?.id" :commande="colis"
                                        @remove-this="removeCommande" @add-this="addCommande"/>
        </div>
      </div>

      <!-- Les vues persistant au scroll-->
      <div class="d-flex justify-content-between position-sticky sticky-bottom-0 px-5 py-2 bg-white">
        <a-button :loading="isLoading" class="bg-link-blue text-white text-xs" @click="handleAddCommand">Enregistrer
          maintenant
        </a-button>
        <a-pagination v-model:current="currentPage" :total="lenPage" size="small"/>
      </div>

    </div>
    <!-- ======================================================-->
  </a-drawer>

</template>

<style scoped>
.sticky-bottom-0 {
  bottom: -5%;
}
</style>
