<template>

  <span @click="showModal">
    <i aria-hidden="true" class="fas fa-wrench"></i>
      Modifier statut
  </span>

  <a-modal ref="modalRef" v-model:open="open" :footer="false" :mask="false" :wrap-style="{ overflow: 'hidden' }"
           @ok="handleOk">

    <!--   ============== Le contenu du modal =============== -->
    <div class="">

      <div class="modal-header">
        <div class="col">
          <h5 id="removeRevocationModalLabel" class="modal-title text-dark">Modifier le statut de la commande</h5>
          <span class="text-xs text-bold text-dark">{{ commande?.title }}</span>
        </div>
        <!--          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>-->
      </div>

      <div class="modal-body">

        <p class="text-dark font-weight-normal text-xs">
          Cette commande a été signalé de: "<span
            class="text-success text-xs text-bold">{{ commande?.revokeReason?.title || "Non acceptée" }}</span>".
          Vous êtes sur le point de changer cet état.
        </p>

        <!--          La raison de la révocation-->
        <p class="text-dark text-bold text-sm">Donnez la raison de ce changement (acceptation):</p>
        <div class="flex flex-row flex-wrap center">

          <div class="btn btn-xs border-radius-xl m-1 border border-light"
               @click="reasonChoosed=null">
            <i v-if="!reasonChoosed" aria-hidden="true" class="fa fa-check text-success"></i>
            <span class="text-xs text-dark m-1">Aucune</span>
          </div>

          <div v-for="reason in reasons" :key="reason?.id"
               class="btn btn-xs border-radius-xl m-1 border border-light"
               @click="reasonChoosed=reason">
            <i v-if="reasonChoosed?.id === reason?.id" aria-hidden="true" class="fa fa-check text-success"></i>
            <span class="text-xs text-dark m-1">{{ reason?.title }}</span>
          </div>
        </div>

        <!-- ======Les details de la raison de revocation ===========-->
        <div class="mt-2">
          <strong class="text-bold text-sm text-dark">Donnez le montant des frais de service</strong>
          <form-input v-model="price" class="w-100" placeholder="Prix total des frais de service (min: 10$)"
                      type="number"/>
        </div>
        <!--========================================================-->

        <br>
        <p class="text-dark text-bold text-sm">
          Ce que l'auteur<span class="text-gradient text-dark font-weight-normal">(client)</span> verra:</p>
        <ul>
          <li class="text-dark text-xs mb-1">Commande en attente de paiement de frais de service</li>
          <li v-if="reasonChoosed" class="text-dark text-xs mb-1">Certains articles de cette commande sont:
            "{{ reasonChoosed?.title }}".
          </li>
        </ul>

        <span class="text-dark font-weight-normal text-xs text-center">
              Vous et d'autres admins, pourrez changer cette action quand vous voulez!
          </span>

        <div class="modal-footer">


          <div class="flex align-items-end">
            <form-button v-if="!next"
                         class="text-xs bg-danger m-1 font-weight-normal btn-sm border-radius-xl" type="button"
                         @click="handleOk">
              Annuler
            </form-button>

            <!-- Faire suivant pour afin afficher la vue où saisir le price-->
            <form-button
                v-if="!next"
                :aria-controls="reasonChoosed &&'collapsePurchargPrice'"
                :data-bs-target="reasonChoosed &&'#collapsePurchargPrice'"
                aria-expanded="false" class="text-xs m-1 font-weight-normal btn-sm border-radius-xl"
                data-bs-toggle="collapse"
                @click="nextFunction"
            >
              <div v-if="store.isLoading">Go</div>
              Suivant
              <i class="fas fa-arrow-right text-white"></i>
            </form-button>
            <!------------------------------------------------------------>

            <!-- Faire précedent -->
            <form-button
                v-if="next"
                aria-controls="collapsePurchargPrice"
                aria-expanded="false"
                class="text-xs bg-danger m-1 font-weight-normal btn-sm border-radius-xl"
                data-bs-target="#collapsePurchargPrice" data-bs-toggle="collapse"
                type="button"
                @click="next = next ? !next: true"
            >
              Retour
            </form-button>

            <!-- Faire suivant pour afin afficher la vue où saisir le price-->
            <form-button
                v-if="next"
                class="text-xs m-1 font-weight-normal btn-sm border-radius-xl"
                @click="handleSetApprobation">
              {{ store.isLoading ? "Patientez ..." : " Confirmez votre choix" }}
              <i class="fas fa-check text-white"></i>
            </form-button>
            <!------------------------------------------------------------>

          </div>
        </div>
      </div>

    </div>
    <!-- ======================================================-->

    <template #title>
      <div ref="modalTitleRef" class="text-secondary font-weight-normal text-xs" style="width: 100%; cursor: move"><i
          class="fas fa-bars px-1"></i>Déplacer
      </div>
    </template>

    <template #modalRender="{ originVNode }">
      <div :style="transformStyle">
        <component :is="originVNode"/>
      </div>
    </template>

  </a-modal>

</template>

<script setup>
import FormButton from "@/components/FormButton.vue";
import {setApprobationCommande} from "@/services-apis/commandes";
import {computed, ref, watchEffect} from "vue";
import {commandeStore} from "@/store/pinia-stores/commande-store";
import {useAcceptReasons, useCommande} from "@/services-apis/commandes/hooks.commandes";
import {userStore} from "@/store/pinia-stores/user.store";
import FormInput from "@/components/FormInput.vue";
import {toast} from "vue3-toastify";
import {Styles} from "@/assets/js/styles";
import {useDraggableModal} from "@/hook/draggableModal";

const {refetch, commande} = useCommande()
const {reasons} = useAcceptReasons()
const store = commandeStore()
const ustore = userStore()

const modalTitleRef = ref(null);
const {open, transformStyle, showModal, handleOk} = useDraggableModal(modalTitleRef);

// Etape suivante
const next = ref(false)

// Prix des frais de service
const price = ref("")
const computedPrice = computed(() => commande.value?.price)

// La raison choisie
const computedReason = computed(() => commande.value?.acceptReason || null)
const reasonChoosed = ref(computedReason.value)

// Aller de l'avant
const nextFunction = () => {
  if (!price.value)
    toast.error('Donnez un montant valable des frais de service', {
      position: 'bottom-center',
      hideProgressBar: true,
      bodyClassName: 'body-toaster',
      toastStyle: Styles.toastStyle,
      autoClose: 3000,
    })
  else
    next.value = !next.value
}


/*Lancer la recherche*/
const handleSetApprobation = () => {
  if (Number(price.value) < 10) {
    // Message du succès d'enregistrement user / admin
    return toast.error('Donnez un price valide', {
      position: 'bottom-center',
      hideProgressBar: true,
      bodyClassName: 'body-toaster',
      toastStyle: Styles.toastStyle,
      autoClose: 2000,
    })
  }

  setApprobationCommande({
    approbation: true,
    price: price.value,
    adminId: ustore.user?.id,
    reasonId: reasonChoosed.value?.id,
  }, refetch, "Statut mis à jour", () => {
    handleOk()
  })
}

watchEffect(() => {
  price.value = computedPrice.value
  reasonChoosed.value = computedReason.value
})


</script>