const formatCurrency = (value, separator = ',') => {
    // Fixer le nombre de décimales à 2
    const formattedValue = value.toFixed(2);

    // Séparer la partie entière de la partie décimale
    const parts = formattedValue.split('.');

    // Ajouter des séparateurs de milliers à la partie entière
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator === ',' ? ',' : ' ');

    // Joindre la partie entière et décimale avec une virgule ou un point
    return parts.join(separator === ',' ? '.' : ',');
};

export default formatCurrency;
