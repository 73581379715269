import api from "../axios-init";
import {useQuery} from "@tanstack/vue-query";
import {computed} from "vue";
import {userStore} from "@/store/pinia-stores/user.store";
import {payementStore} from "@/store/pinia-stores/payements-store";

// Utiliser toutes les factures / paiements
export const usePayements = () => {

    const getPayements = async () => {
        let token = userStore()?.tokens?.accessToken
        let searchValue = payementStore()?.textSearch

        let page = searchValue ? undefined : payementStore()?.page
        let items = searchValue ? undefined : payementStore()?.items || 5

        let params = {page, items}
        let headers = {Authorization: `Bearer ${token}`}
        const res = await api.get('/admin/payements/', {headers, params})
        return res?.data?.data
    }

    const {
        data: response,
        refetch,
        isPending,
        isLoading, isError,
        error
    } = useQuery({
        queryKey: ['get-all-payements'],
        queryFn: getPayements,
        refetchInterval: 3000
    })

    const payements = computed(() => response.value?.payements)
    const totalItems = computed(() => response.value?.totalItems)

    return {
        isPending, isLoading, refetch, totalItems, isError, payements: payements, error
    }
}

// Utiliser tous les paiements qui concernent un seul admin
export const useMyPayements = () => {

    const getMyPayements = async () => {
        let token = userStore()?.tokens?.accessToken
        let searchValue = payementStore()?.textSearch

        let page = searchValue ? undefined : payementStore()?.page
        let items = searchValue ? undefined : payementStore()?.items || 5

        let params = {page, items}
        let headers = {Authorization: `Bearer ${token}`}
        const res = await api.get('/admin/my-payements/', {headers, params})
        return res?.data?.data
    }

    const {
        data: response,
        refetch,
        isPending,
        isFetching, isError,
        error
    } = useQuery({
        queryKey: ['get-my-payements'],
        queryFn: getMyPayements,
        refetchInterval: 3000
    })

    const payements = computed(() => response.value?.payements)
    const totalItems = computed(() => response.value?.totalItems)

    return {
        isPending, isFetching, refetch, totalItems, isError, payements: payements, error
    }
}


// Utiliser un paiement
export const usePayement = () => {

    let token = userStore()?.tokens?.accessToken
    let id = payementStore().currentId

    const getOnePayement = async () => {
        let headers = {Authorization: `Bearer ${token}`}
        const res = id ? await api.get(`/admin/payement/${id}`, {headers}) : null
        return res?.data?.data
    }

    const {
        data: response,
        refetch,
        isPending,
        isLoading, isError,
        error
    } = useQuery({
        queryKey: ['get-one-payement'],
        queryFn: id ? getOnePayement : null,
        staleTime: Infinity,
        refetchInterval: 2500
    })

    const payement = computed(() => response.value?.payement)
    const commande = computed(() => response.value?.command)
    const articles = computed(() => commande.value?.articles)
    const payement_articles = computed(() => response.value?.articles_payements)

    return {
        isPending,
        isLoading,
        refetch,
        commande,
        articles,
        payement_articles,
        isError,
        payement: payement,
        error
    }
}


// Utiliser toutes requêtes de paiement
export const useRequestPayement = () => {

    const getRequest = async () => {
        let token = userStore()?.tokens?.accessToken
        let searchValue = payementStore()?.textSearch

        let page = searchValue ? undefined : payementStore()?.page
        let items = searchValue ? undefined : payementStore()?.items || 5

        let params = {page, items}
        let headers = {Authorization: `Bearer ${token}`}
        const res = await api.get('/admin/request-payements/', {headers, params})
        console.log({res})
        return res?.data?.data
    }

    const {
        data: response,
        refetch,
        isPending,
        isLoading, isError,
        error
    } = useQuery({
        queryKey: ['get-requests-payements', '/admin/request-payements/'],
        queryFn: getRequest,
        refetchInterval: 3000
    })

    const requests = computed(() => response.value)

    return {
        isPending, isLoading, refetch, isError, requests, error
    }
}


// Utiliser toutes justifications de paiement des clients
export const useJustificationsPayement = () => {

    const getRequest = async () => {
        let token = userStore()?.tokens?.accessToken
        let searchValue = payementStore()?.textSearch

        let page = searchValue ? undefined : payementStore()?.page
        let items = searchValue ? undefined : payementStore()?.items || 5

        let params = {page, items}
        let headers = {Authorization: `Bearer ${token}`}
        const res = await api.get('/admin/justification-payements/', {headers, params})
        console.log({res})
        return res?.data?.data
    }

    const {
        data: response,
        refetch,
        isPending,
        isLoading, isError,
        error
    } = useQuery({
        queryKey: ['get-requests-payements', '/admin/request-payements/'],
        queryFn: getRequest,
        refetchInterval: 3000
    })

    const justifications = computed(() => response.value)
    const paniers = computed(() => response.value)

    return {
        isPending, isLoading, refetch, isError, justifications, paniers, error, response
    }
}

// Utiliser toutes justifications de paiement des clients
export const useJustificationsCartsPayement = () => {

    const getRequest = async () => {
        let token = userStore()?.tokens?.accessToken
        let searchValue = payementStore()?.textSearch

        let page = searchValue ? undefined : payementStore()?.page
        let items = searchValue ? undefined : payementStore()?.items || 5

        let params = {page, items}
        let headers = {Authorization: `Bearer ${token}`}
        const res = await api.get('/admin/justification-payements-carts/', {headers, params})
        console.log({res})
        return res?.data?.data
    }

    const {
        data: response,
        refetch,
        isPending,
        isLoading, isError,
        error
    } = useQuery({
        queryKey: ['get-requests-payements-carts', '/admin/request-payements-carts/'],
        queryFn: getRequest,
        refetchInterval: 3000
    })

    const justifications = computed(() => response.value)
    const paniers = computed(() => response.value)

    return {
        isPending, isLoading, refetch, isError, justifications, paniers, error, response
    }
}

// Utiliser une justification de paiement
export const useJustificationPayement = () => {

    let token = userStore()?.tokens?.accessToken
    let id = payementStore().currentJustificationId

    const getOnePayement = async () => {
        let headers = {Authorization: `Bearer ${token}`}
        const res = id ? await api.get(`/admin/justification-payement/${id}`, {headers}) : null
        return res?.data?.data
    }

    const {
        data: response,
        refetch,
        isPending,
        isLoading, isError,
        error
    } = useQuery({
        queryKey: ['get-one-justification', `/admin/justification-payement/${id}`],
        queryFn: id ? getOnePayement : null,
        staleTime: Infinity,
        refetchInterval: 2500
    })

    const justification = computed(() => response.value)
    const images = computed(() => justification.value?.images)
    const commande = computed(() => justification.value?.command)
    const articles = computed(() => commande.value?.articles)

    return {
        isPending,
        isLoading,
        refetch,
        commande,
        articles,
        isError,
        justification,
        images,
        error
    }
}

// Utiliser une justification de paiement d'un panier
export const useJustificationCartPayement = () => {

    let token = userStore()?.tokens?.accessToken
    let id = payementStore().currentJustificationCartId

    const getOnePayement = async () => {
        let headers = {Authorization: `Bearer ${token}`}
        const res = id ? await api.get(`/admin/justification-cart-payement/${id}`, {headers}) : null
        return res?.data?.data
    }

    const {
        data: response,
        refetch,
        isPending,
        isLoading, isError,
        error
    } = useQuery({
        queryKey: ['get-one-cart-justification', `/admin/justification-cart-payement/${id}`],
        queryFn: id ? getOnePayement : null,
        staleTime: Infinity,
        refetchInterval: 2500
    })

    const justification = computed(() => response.value)
    const images = computed(() => justification.value?.images)
    const commande = computed(() => justification.value?.command)
    const articles = computed(() => commande.value?.articles)

    return {
        isPending,
        isLoading,
        refetch,
        commande,
        articles,
        isError,
        justification,
        images,
        error
    }
}


// Utiliser tous les forfaits de payement d'expéditions existants
export const useFlatRates = () => {

    // Tous les forfaits
    const getFlatRates = async () => {

        let token = userStore()?.tokens?.accessToken
        let headers = {Authorization: `Bearer ${token}`}
        // console.log({token})
        const res = await api.get(`/admin/flat-rates/`, {headers})
        return res?.data?.data
    }

    const {
        data: flatrates, isPending, isLoading, isError, error, refetch
    } = useQuery({
        queryKey: ['get-all-flatrates'], queryFn: getFlatRates, refetchInterval: 3000
    })

    return {
        isPending, isLoading, isError, flatrates, error, refetch
    }
}

