<script setup>
import Loading from "@/views/components/Loading.vue";
import {useJustificationsPayement} from "../services-apis/payements/hooks.payements";
import JustificationsPayementTable from "./components/JustificationsPayementTable.vue";
const {isLoading} = useJustificationsPayement()

</script>
<template>
  <div class="container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <justifications-payement-table />
        <Loading v-if="isLoading"/>
      </div>
    </div>
  </div>
</template>
