<script setup>
import PlanificatorModal from "../modals/PlanificatorModal.vue";
import {useExpedition, useExpeditionsEvents} from "../../services-apis/expeditions/hooks.expeditions";
import ExpeditionItem from "./ExpeditionItem.vue";
import {expeditionStore} from "../../store/pinia-stores/expedition-store";
import router from "../../router";

const store = expeditionStore()
const {events} = useExpeditionsEvents()
const {refetch} = useExpedition()

// Lors du clic d'un élément
const handleClickItem = (params) => {
  store.setCurrentExpId(params.item.id)
  router.push({name: 'expedition-details'})
  window.scrollTo({top: 0, behavior: "smooth"})
  refetch()
}

</script>
<template>
  <div class="card">
    <div class="card shadow-none py-4 px-1 border-radius-md">
      <div class="col-12">
        <div class="d-flex flex-row justify-content-between align-items-center px-3">
          <h2 class="font-weight-bold text-lg">Evénements à venir</h2>

          <planificator-modal>
            <a-button
                class="text-xs text-white mb-2 bg-link-blue border-0"
                type="default">
              <i class="fas fa-plus"></i>
            </a-button>
          </planificator-modal>

        </div>

        <div class="my-3 px-2 content-scrollable h-100">

          <div v-if="events?.length===0" class="text-center py-2">
            <span class="text-gray text-sm">Aucune planification pour l'instant</span>
          </div>

          <!--  Les différentes expéditions par item -->
          <expedition-item v-for="event in events" :key="event.id" :event="event"
                           @callback="handleClickItem"/>
        </div>

        <!--        <div class="mt-1">-->
        <!--          <h2 class="font-weight-bold text-lg">Aperçu sur calendrier</h2>-->
        <!--          <v-calendar v-model="today" expanded mode="dateTime" show-weeknumbers="right" title-position="left"/>-->
        <!--        </div>-->
      </div>
    </div>

  </div>
</template>

<style scoped>
.content-scrollable {
  overflow-y: scroll;
  scrollbar-width: thin;
  max-height: 100vh;
}
</style>

