<script setup>
import DetailCommandeCard from "@/views/components/DetailCommandeCard.vue";
import MostDetailCommandeCard from "@/views/components/MostDetailCommandeCard.vue";
import Loading from "@/views/components/Loading.vue";
import {useCommande} from "@/services-apis/commandes/hooks.commandes";
import CommandDrawerSide from "./components/side-drawer/CommandDrawerSide.vue";

const {isLoading} = useCommande()
</script>
<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-7">
        <Loading v-if="isLoading"/>
        <detail-commande-card/>
      </div>

      <div class="col-md-5 mt-sm-0">
        <most-detail-commande-card/>
      </div>
    </div>

    <!--    Le menu droit sur la commande-->
    <CommandDrawerSide/>

  </div>
</template>
