<script setup>

import {useCommandes} from "@/services-apis/commandes/hooks.commandes";
import router from "@/router";
import {commandeStore} from "@/store/pinia-stores/commande-store";

import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime';
import BlocArticleDB from "@/views/components/BlocArticleDB.vue";
import Loading from "@/views/components/Loading.vue";

const {commandes, isLoading} = useCommandes()

const locale = require('dayjs/locale/fr')
dayjs.locale(locale)
dayjs.extend(relativeTime)

const store = commandeStore();

// Aller à une commande
const goToOne = (id) => {
  store.setCurrentId(id)
  router.push({name: 'Details-Commande'})
}

</script>
<template>
  <div class="py-3 container-fluid">
    <div class="row">
      <div class="col-lg-12">

        <!--        <MinCardStatistics/>-->

        <div class="row mt-4">
          <div class="col-lg-7 mb-lg-0 mb-4">
            <div class="card" style="min-height: 75vh;">
              <div class="p-3 pb-0 card-header">
                <div class="d-flex justify-content-between align-items-center">
                  <h6 class="mb-2">Commandes enregistrées</h6>
                  <Loading v-if="isLoading"/>
                </div>
              </div>

              <div v-if="commandes?.length === 0" class="d-flex justify-content-center align-items-center flex-column">
                <img alt="empty illustration"
                     class="img img-fluid"
                     src="@/assets/img/illustrations/commande-vide.jpg" style="width: 35vw;height: 30vw"/>
                <span class="text-gray my-3 text-sm">Aucune nouvelle commande actuellement</span>
              </div>

              <div v-else class="table-responsive">
                <table class="table align-items-center">
                  <tbody>
                  <tr v-for="(commande, index) in commandes?.slice(0, 10)" :key="index" class="cursor-pointer"
                      @click="goToOne(commande.id)">
                    <td class="w-30">
                      <div class="px-2 py-1 d-flex align-items-center">
                        <div>
                          <img
                              alt="icône commande"
                              class="avatar avatar-sm rounded-circle me-2"
                              src="@/assets/img/illustrations/cart.jpg"
                          />
                        </div>
                        <div class="ms-4">
                          <p class="mb-0 text-xs font-weight-bold">
                            Titre:
                          </p>
                          <h6 class="mb-0 text-sm text-truncate" style="width: 16rem;">{{ commande?.title }}</h6>
                          <a-tag v-if="commande?.isCompletePayedExpeditionFee" class="mx-2" color="green">Disponible pour une livraison
                          </a-tag>
                          <!--                  Nouveau créée aujourd'hui-->
                          <a-tag v-if="commande?.isNew" class="mx-2" color="blue">Nouveau</a-tag>

                          <!--                  Si la commmande est créée hier ou avant on affiche celui-ci-->
                          <a-tag v-if="commande?.isNeverAllowed" class="mx-2" color="blue">Nouveauté non vue</a-tag>

                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="text-center">
                        <p class="mb-0 text-xs font-weight-bold">Articles:</p>
                        <h6 class="mb-0 text-sm">{{ commande?.articles.length }}</h6>
                      </div>
                    </td>
                    <td>
                      <div class="text-center">
                        <p class="mb-0 text-xs font-weight-bold">Auteur:</p>
                        <h6 class="mb-0 text-sm">{{
                            commande?.creator?.firstname || `Client-${commande?.creator?.id}`
                          }}</h6>
                      </div>
                    </td>
                    <td class="text-sm align-middle">
                      <div class="text-center col">
                        <p class="mb-0 text-xs font-weight-bold">Date:</p>
                        <h6 class="mb-0 text-sm">{{ dayjs(commande?.createdAt).format('DD MMM') }}</h6>
                      </div>
                    </td>
                  </tr>
                  </tbody>

                  <tfoot v-if="commandes?.length > 10" class="border-top-0 w-100">
                  <tr>
                    <td class="border-0">
                      <a-button class="text-sm text-dark" type="link" @click="router.push('/commandes')">
                        Voir plus des commandes
                      </a-button>
                    </td>
                  </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>

          <!-- Les nouveaux articles des commandes -->
          <bloc-article-d-b/>

        </div>
      </div>
    </div>
  </div>

</template>
