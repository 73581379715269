<script setup>
import {useCommandes} from "@/services-apis/commandes/hooks.commandes";
import {computed, ref} from "vue";
import validator from "validator";
import router from "@/router";
import {commandeStore} from "@/store/pinia-stores/commande-store";

import {formatName} from "../../middlewares/formatName";
import formatCurrency from "../../middlewares/formatCurrency";
import dayjs from "dayjs";

const {commandes} = useCommandes()

const search = ref('')
// Les configurations de la pagination
const currentPage = ref(1);
const lenPage = computed(() => {
  const totalItems = commandes.value?.length || 0;

  if (totalItems <= 7) {
    // Si on a 7 éléments ou moins, on affiche tout sur une seule page
    return 1;
  }

  // Pour les éléments au-delà de 7 : on calcule le nombre de pages restantes après avoir affiché 7 éléments sur la première page
  const remainingItems = totalItems - 7;

  // Le nombre de pages nécessaires après la première page (avec 10 éléments par page)
  // On multiplie par 10 pour s'assurer que le composant pagination collecte bien toutes les données afin d'afficher un bon nombe des pages
  const additionalPages = Math.ceil(remainingItems / 10) * 10;

  // 1 page pour les 5 premiers éléments + pages supplémentaires
  return additionalPages + 1;
});
const paginatedCommandes = computed(() => {
  const totalCommandes = commandes.value || [];
  const activePage = currentPage.value;

  if (activePage === 1) {
    // Première page : afficher les 5 premiers éléments
    return totalCommandes.slice(0, 7);
  } else {
    // Autres pages : afficher 10 éléments par page
    const start = 7 + (activePage - 2) * 10;
    const end = start + 10;
    return totalCommandes.slice(start, end);
  }
});

const store = commandeStore();

// Aller à une commande
const goToOne = (id) => {
  store.setCurrentId(id)
  router.push({name: 'Details-Commande'})
}

const resultSearch = computed(() => paginatedCommandes.value?.filter(item => (
    validator.contains(item?.title, search.value, {ignoreCase: true})
    ||
    validator.contains(item?.creator?.firstname, search.value, {ignoreCase: true})
    ||
    validator.contains(item?.creator?.lastname, search.value, {ignoreCase: true})
    ||
    validator.contains(item?.creator?.email, search.value, {ignoreCase: true})
)))

</script>

<template>
  <div class="card mb-4 border-radius-md">

    <div class="d-flex justify-content-between">
      <div class="card-header pb-0">
        <h6>Toutes les commandes</h6>
      </div>

      <input
          v-model="search"
          class="form-control m-3" placeholder="Tapez un mot clé pour faire la recherche"
          style="width: 200px"
          type="text"
      />
    </div>

    <div v-if="commandes?.length === 0" class="d-flex justify-content-center align-items-center flex-column">
      <img alt="empty illustration"
           class="img img-fluid" src="@/assets/img/illustrations/commande-vide.jpg" style="width: 35vw;height: 30vw"/>
      <span class="text-gray my-3 text-sm">Aucune commande actuellement</span>
    </div>

    <div v-else class="d-flex justify-content-between flex-column card-body px-0 pt-0 pb-2" style="min-height: 60vh;">
      <div class="table-responsive p-0">
        <table class="table align-items-start justify-content-center mb-0">
          <thead>
          <tr>
            <th class="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
              Titre
            </th>

            <th class="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
              client
            </th>

            <th class="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
              Budget
            </th>
            <th class="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
              Articles
            </th>

            <th class="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
              Création
            </th>

          </tr>
          </thead>

          <tbody>

          <tr v-for="commande in resultSearch" :key="commande?.id">
            <td>
              <div class="d-flex">
                <div>
                  <img
                      alt="icône commande"
                      class="avatar avatar-sm rounded-circle me-2"
                      src="../../assets/img/illustrations/cart.jpg"
                  />
                </div>
                <div class="my-auto cursor-pointer" @click="goToOne(commande?.id)">
                  <h6 class="mb-0 text-sm text-truncate text-bold" style="width: 250px">{{ commande?.title }}</h6>
                  <span :class="commande?.isCancel ? 'text-danger':'text-success'"
                        class="mb-0 text-xs text-success font-weight-normal">{{ commande?.status }}</span>

                  <a-tag v-if="commande?.isCompletePayedExpeditionFee" class="mx-2" color="green">Disponible pour une livraison
                  </a-tag>
                  <!--                  Nouveau créée aujourd'hui-->
                  <a-tag v-if="commande?.isNew" class="mx-2" color="blue">Nouveau</a-tag>

                  <!--                  Si la commmande est créée hier ou avant on affiche celui-ci-->
                  <a-tag v-if="commande?.isNeverAllowed" class="mx-2" color="blue">Nouveauté non vue</a-tag>

                </div>
              </div>
            </td>

            <td>
              <h5 class="text-xs font-weight-bold py-3">
                {{ formatName(commande?.creator) }}</h5>
            </td>

            <td>
              <p class="text-xs text-truncate font-weight-bold mb-0 py-3" style="width: 120px">
                {{
                  `${commande?.totalAPayer ? '$ ' + formatCurrency(commande?.totalAPayer) : 'Non défini'}`
                }}
              </p>
            </td>

            <td>
              <h5 class="text-xs font-weight-bold py-3">
                {{ `${commande?.articles?.length} article${commande?.articles?.length > 1 ? 's' : ''}` }}</h5>
            </td>

            <td>
              <h5 class="text-xs font-weight-bold py-3">
                {{ dayjs(commande?.createdAt).format('DD MMM YYYY') }}</h5>
            </td>
          </tr>

          <!--            ======================================================-->
          <!--          <tr>
                      <td>
                        <div class="d-flex px-2">

                          <div>
                            <img
                                src="../../assets/img/small-logos/logo-invision.svg"
                                class="avatar avatar-sm rounded-circle me-2"
                                alt="invision"
                            />
                          </div>
                          <div class="my-auto">
                            <h6 class="mb-0 text-sm">Invision</h6>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p class="text-sm font-weight-bold mb-0">$5,000</p>
                      </td>
                      <td>
                        <span class="text-xs font-weight-bold">done</span>
                      </td>
                      <td class="text-start text-center">
                        <div class="d-flex align-items-center justify-content-center">
                          <span class="me-2 text-xs font-weight-bold">100%</span>
                          <div>
                            <div class="progress">
                              <div
                                  class="progress-bar bg-gradient-success"
                                  role="progressbar"
                                  aria-valuenow="100"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                  style="width: 100%"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td class="text-start">
                        <button
                            class="btn btn-link text-secondary mb-0"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                          <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="d-flex px-2">
                          <div>
                            <img
                                src="../../assets/img/small-logos/logo-jira.svg"
                                class="avatar avatar-sm rounded-circle me-2"
                                alt="jira"
                            />
                          </div>
                          <div class="my-auto">
                            <h6 class="mb-0 text-sm">Jira</h6>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p class="text-sm font-weight-bold mb-0">$3,400</p>
                      </td>
                      <td>
                        <span class="text-xs font-weight-bold">canceled</span>
                      </td>
                      <td class="text-start text-center">
                        <div class="d-flex align-items-center justify-content-center">
                          <span class="me-2 text-xs font-weight-bold">30%</span>
                          <div>
                            <div class="progress">
                              <div
                                  class="progress-bar bg-gradient-danger"
                                  role="progressbar"
                                  aria-valuenow="30"
                                  aria-valuemin="0"
                                  aria-valuemax="30"
                                  style="width: 30%"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td class="text-start">
                        <button
                            class="btn btn-link text-secondary mb-0"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                          <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="d-flex px-2">
                          <div>
                            <img
                                src="../../assets/img/small-logos/logo-slack.svg"
                                class="avatar avatar-sm rounded-circle me-2"
                                alt="slack"
                            />
                          </div>
                          <div class="my-auto">
                            <h6 class="mb-0 text-sm">Slack</h6>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p class="text-sm font-weight-bold mb-0">$1,000</p>
                      </td>
                      <td>
                        <span class="text-xs font-weight-bold">canceled</span>
                      </td>
                      <td class="text-start text-center">
                        <div class="d-flex align-items-center justify-content-center">
                          <span class="me-2 text-xs font-weight-bold">0%</span>
                          <div>
                            <div class="progress">
                              <div
                                  class="progress-bar bg-gradient-success"
                                  role="progressbar"
                                  aria-valuenow="0"
                                  aria-valuemin="0"
                                  aria-valuemax="0"
                                  style="width: 0%"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td class="text-start">
                        <button
                            class="btn btn-link text-secondary mb-0"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                          <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="d-flex px-2">
                          <div>
                            <img
                                src="https://demos.creative-tim.com/argon-dashboard/assets/img/small-logos/logo-webdev.svg"
                                class="avatar avatar-sm rounded-circle me-2"
                                alt="webdev"
                            />
                          </div>
                          <div class="my-auto">
                            <h6 class="mb-0 text-sm">Webdev</h6>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p class="text-sm font-weight-bold mb-0">$14,000</p>
                      </td>
                      <td>
                        <span class="text-xs font-weight-bold">working</span>
                      </td>
                      <td class="text-start text-center">
                        <div class="d-flex align-items-center justify-content-center">
                          <span class="me-2 text-xs font-weight-bold">80%</span>
                          <div>
                            <div class="progress">
                              <div
                                  class="progress-bar bg-gradient-info"
                                  role="progressbar"
                                  aria-valuenow="80"
                                  aria-valuemin="0"
                                  aria-valuemax="80"
                                  style="width: 80%"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td class="text-start">
                        <button
                            class="btn btn-link text-secondary mb-0"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                          <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="d-flex px-2">
                          <div>
                            <img
                                src="../../assets/img/small-logos/logo-xd.svg"
                                class="avatar avatar-sm rounded-circle me-2"
                                alt="xd"
                            />
                          </div>
                          <div class="my-auto">
                            <h6 class="mb-0 text-sm">Adobe XD</h6>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p class="text-sm font-weight-bold mb-0">$2,300</p>
                      </td>
                      <td>
                        <span class="text-xs font-weight-bold">done</span>
                      </td>
                      <td class="text-start">
                        <div class="d-flex align-items-center justify-content-center">
                          <span class="me-2 text-xs font-weight-bold">100%</span>
                          <div>
                            <div class="progress">
                              <div
                                  class="progress-bar bg-gradient-success"
                                  role="progressbar"
                                  aria-valuenow="100"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                  style="width: 100%"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td class="text-start">
                        <button
                            class="btn btn-link text-secondary mb-0"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                          <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                        </button>
                      </td>
                    </tr>-->
          </tbody>
        </table>
      </div>

      <!-- Les pages pour les utilisateurs (clients) lorsqu'ils sont nombreux -->
      <div class="d-flex justify-content-center border-top my-1 py-2">
        <a-pagination v-model:current="currentPage" :total="lenPage" size="small"/>
      </div>
    </div>
  </div>

</template>
