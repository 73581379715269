<template>

  <span @click="showModal">
      <i aria-hidden="true" class="fas fa-circle-xmark me-2"></i
      >
              <i v-if="commande?.revokeReason " aria-hidden="true" class="fas fa-pen-square"></i>
     {{ commande?.revokeReason ? 'Annulation' : 'Annuler' }}
  </span>

  <a-modal ref="modalRef" v-model:open="open" :footer="false" :mask="false" :wrap-style="{ overflow: 'hidden' }"
           @ok="handleOk">

    <!--   ============== Le contenu du modal =============== -->
    <div class="">
      <div class="modal-header">
        <div class="col">
          <h5 id="revokeCommandeModalLabel" class="modal-title text-danger">
            {{ commande?.revokeReason ? 'Modifier la raison de l\'annulation' : 'Annuler cette commande' }}</h5>
          <span class="text-xs text-bold text-dark">{{ commande?.title }}</span>
        </div>
        <!--          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>-->
      </div>

      <div class="modal-body">

        <p class="text-dark font-weight-normal text-xs">
          En annulant une commande, vous signalez à l'auteur que sa demande ne répond pas à nos règles et politiques
          de
          confidentialité.
        </p>

        <!--          Messge si un paiement est detecté-->
        <p v-if="commande?.payed" class="text-danger font-weight-normal text-xs">
          Cette opération n'est possible que lorsqu'une commande n'a pas encore réçue de paiement de frais de
          service!
        </p>

        <!--          Message si un raison d'acceptation est detectée -->
        <p v-if="commande?.acceptReason" class="text-danger font-weight-normal text-xs">
          Cette opération supprimera toute raison d'acception existante pour cette commande
        </p>

        <!--          La raison de la révocation-->
        <p class="text-dark text-bold text-sm">Signaler la raison de l'annulation:</p>
        <div class="flex flex-row flex-wrap center">
          <div class="btn btn-xs border-radius-xl m-1 border border-light"
               @click="reasonChoosed=null">
            <i v-if="!reasonChoosed" aria-hidden="true" class="fa fa-check text-success"></i>
            <span class="text-xs text-dark m-1">Aucune</span>
          </div>

          <div v-for="reason in reasons" :key="reason?.id"
               class="btn btn-xs border-radius-xl m-1 border border-light"
               @click="reasonChoosed=reason">
            <i v-if="reasonChoosed?.id === reason?.id" aria-hidden="true" class="fa fa-check text-success"></i>
            <span class="text-xs text-dark m-1">{{ reason?.title }}</span>
          </div>
        </div>

        <br>
        <p class="text-dark text-bold text-sm">
          Ce que l'auteur<span class="text-gradient text-dark font-weight-normal">(client)</span> verra:</p>
        <ul>
          <li class="text-dark text-xs mb-1">Commande non autorisée par Central-Achat</li>
          <li v-if="reasonChoosed" class="text-dark text-xs mb-1">Certains articles de cette commande sont:
            "{{ reasonChoosed?.title }}"
          </li>
        </ul>

        <span class="text-dark font-weight-normal text-xs text-center">
              Vous et d'autres admins, pourrez changer cette action quand vous voulez!
          </span>

        <div class="modal-footer">

          <div class="flex align-items-end">
            <form-button class="text-xs bg-danger m-1 font-weight-normal btn-sm border-radius-xl"
                         type="button" @click="handleOk">
              Fermer
            </form-button>
            <form-button class="text-xs m-1 font-weight-normal btn-sm border-radius-xl" type="button"
                         @click="handleCancelApprobation">
              {{ store.isLoading ? "Annulation ..." : "Annuler maintenant" }}
            </form-button>
          </div>
        </div>
      </div>

    </div>

    <!-- ======================================================-->

    <template #title>
      <div ref="modalTitleRef" class="text-secondary font-weight-normal text-xs" style="width: 100%; cursor: move"><i
          class="fas fa-bars px-1"></i>Déplacer
      </div>
    </template>

    <template #modalRender="{ originVNode }">
      <div :style="transformStyle">
        <component :is="originVNode"/>
      </div>
    </template>

  </a-modal>

</template>


<script setup>
import FormButton from "@/components/FormButton.vue";
import {cancelApprobationCommande} from "@/services-apis/commandes";
import {computed, ref, watchEffect} from "vue";
import {commandeStore} from "@/store/pinia-stores/commande-store";
import {useCommande, useRevokeReasons} from "@/services-apis/commandes/hooks.commandes";
import {userStore} from "@/store/pinia-stores/user.store";
import {useDraggableModal} from "@/hook/draggableModal";

const modalTitleRef = ref(null);
const {open, transformStyle, showModal, handleOk} = useDraggableModal(modalTitleRef);

const {refetch, commande} = useCommande()
const {reasons} = useRevokeReasons()

const store = commandeStore()
const ustore = userStore()

// La raison choisie
const currentReason = computed(() => commande.value?.revokeReason)
const reasonChoosed = ref(currentReason.value)
watchEffect(() => reasonChoosed.value = currentReason.value)

/*Lancer la recherche*/
const handleCancelApprobation = () => {
  cancelApprobationCommande({
    adminId: reasonChoosed.value?.id ? ustore.user?.id : null,
    reasonId: reasonChoosed.value?.id
  }, () => {
    refetch()
    handleOk()
  }, "")
}


</script>