import {userStore} from "@/store/pinia-stores/user.store";
import api from "@/services-apis/axios-init";
import {useQuery} from "@tanstack/vue-query";

// Utiliser tous les produits
export const useProducts = () => {

    // Tous les articles
    const getProducts = async () => {
        let token = userStore()?.tokens?.accessToken
        let headers = {Authorization: `Bearer ${token}`}
        // console.log({token})
        const res = await api.get('/admin/products/', {headers})
        return res?.data?.data || []
    }

    const {
        data: products, isPending, isLoading, isError, error, refetch
    } = useQuery({
        queryKey: ['get-all-products'], queryFn: getProducts, refetchInterval: 5000
    })

    // console.log({commandes})

    return {
        isPending, isLoading, isError, products, error, refetch
    }
}

// Utiliser toutes les catégories des produits
export const useProductCategories = () => {

    const getProductCategories = async () => {
        let token = userStore()?.tokens?.accessToken
        let headers = {Authorization: `Bearer ${token}`}
        // console.log({token})
        const res = await api.get('/admin/products/categories', {headers})
        return res?.data?.data || []
    }

    const {
        data: categories, isPending, isLoading, isError, error, refetch
    } = useQuery({
        queryKey: ['get-products-categories'], queryFn: getProductCategories, refetchInterval: 5000
    })

    // console.log({commandes})

    return {
        isPending, isLoading, isError, categories, error, refetch
    }
}

// Utiliser tous les paniers des produits payés
export const useCartPayed = () => {

    // Tous les articles
    const getCarts = async () => {
        let token = userStore()?.tokens?.accessToken
        let headers = {Authorization: `Bearer ${token}`}
        // console.log({token})
        const res = await api.get('/admin/carts-payed/', {headers})
        return res?.data?.data || []
    }

    const {
        data: carts, isPending, isLoading, isError, error, refetch
    } = useQuery({
        queryKey: ['get-all-carts-payed', '/admin/carts-payed'], queryFn: getCarts, refetchInterval: 5000
    })

    // console.log({commandes})

    return {
        isPending, isLoading, isError, carts, error, refetch
    }
}


// Utiliser tous les paniers non privés et non payés des clients
export const useCartsOrdered = () => {

    // Tous les paniers commandés
    const getCarts = async () => {
        let token = userStore()?.tokens?.accessToken
        let headers = {Authorization: `Bearer ${token}`}
        // console.log({token})
        const res = await api.get('/admin/carts/', {headers})
        return res?.data?.data || []
    }

    const {
        data: carts, isPending, isLoading, isError, error, refetch
    } = useQuery({
        queryKey: ['get-all-carts-ordered', '/admin/carts'], queryFn: getCarts, refetchInterval: 5000
    })

    // console.log({commandes})

    return {
        isPending, isLoading, isError, carts, error, refetch
    }
}

